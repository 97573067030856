import * as React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styles from "./styles.module.css";
import { mdiConsoleLine, mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from "../components/navbar_logged_in";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Background from "../components/background";
import LottieView from "lottie-react";
import { TransitionImage } from "../components/transitionImage";
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import "@fontsource/roboto/700.css";
import Footer from "../components/footer";
import ParticleEngine from "../components/particles";
import { backgroundImage } from "../components/background";
import { useNavigate } from 'react-router-dom';
import { BACKEND_URL } from "./helper";
import jwt_decode from "jwt-decode";
import { errorAlert } from "./helper";
import { headingStyles } from "../components/others";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa"; // Example icons from Font Awesome
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import queryString from "query-string";
import { IconButton } from "@mui/material";
import { BsTrash } from 'react-icons/bs';
import AddIcon from "@mui/icons-material/Add";
import { Line } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { isAfter, isToday } from 'date-fns';
import dayjs from 'dayjs';



import {
  InputLabel,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Alert,
  FormControl,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { CurrencyBitcoin } from "@mui/icons-material";
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const IndexPage = () => {
  Chart.register(CategoryScale);
  const navigate = useNavigate();
  const [progressMonitoring, setProgressMonitoring] = React.useState([]);
  const [updateChart, setUpdateChart] = React.useState(false);
  const [target, setTarget] = React.useState(20);
  const [baseline, setBaseline] = React.useState(80);
  const currentDate = new Date();
  const [chartData, setChartData] = React.useState({});
  const [student, setStudent] = React.useState({});
  const [validChart, setValidChart] = React.useState(false);

  const { sid, courseID, dateFrom, dateTo } = useParams();
  function calculateUpperTarget(points, target) {
    const n = points.length;
    const out_points = [];
    let x = 0
    for (let i = 0; i < n; i++) {
      let y = parseInt(points[i]);
      if (y > target) {
        x = i
        out_points.push({ x, y });
      }
      else {
        x = 0;
        y = 0;
        out_points.push({});
      }
    }
    return out_points;
  }
  function calculateLinearRegression(points) {
    let sumX = 0;
    let sumY = 0;
    let sumXY = 0;
    let sumXX = 0;

    const n = points.length;

    for (let i = 0; i < n; i++) {
      sumX = i + sumX;
      sumY = parseInt(points[i]) + sumY;
      sumXY = (i * points[i]) + sumXY;
      sumXX = (i * i) + sumXX;
    }

    const m = ((n * sumXY) - (sumX * sumY)) / ((n * sumXX) - (sumX * sumX));
    const b = (sumY - (m * sumX)) / n;
    const startX = 0;
    const endX = points.length;
    const step = 1;
    const out_points = [];
    for (let x = startX; x < endX; x += step) {
      const y = m * x + b;
      out_points.push({ x, y });
    }

    return out_points;
  }

  function linearRegression(X, y) {
    const nSamples = X.length;
    const nFeatures = X[0].length;

    // Compute the mean values of X and y
    const meanX = X.reduce((acc, curr) => acc.map((x, i) => x + curr[i]), Array(nFeatures).fill(0)).map((sum) => sum / nSamples);
    const meanY = y.reduce((acc, curr) => acc + curr, 0) / nSamples;

    // Compute the covariance and variance of X
    let cov = 0;
    let varX = 0;
    for (let i = 0; i < nSamples; i++) {
      cov += (X[i].reduce((acc, curr, j) => acc + (curr - meanX[j]) * (y[i] - meanY), 0)) / nSamples;
      varX += (X[i].reduce((acc, curr, j) => acc + (curr - meanX[j]) ** 2, 0)) / nSamples;
    }

    // Compute the slope (weights) and intercept (bias) of the linear regression line
    const slope = cov / varX;
    const intercept = meanY - slope * meanX.reduce((acc, curr) => acc + curr, 0) / nFeatures;

    return { slope, intercept };
  }

  function forecastNextGrades(filteredGrades, filteredDates) {

    if (Array.isArray(filteredGrades) && Array.isArray(filteredDates) && filteredGrades.length > 0 && filteredDates.length > 0) {
      const parsedGrades = filteredGrades.map((grade) => parseInt(grade));
      const X = parsedGrades.map((_, index) => [index]);
      const y = parsedGrades;
      const model = linearRegression(X, y);
      const numZeros = filteredDates.length;
      const forecastedGrades = Array(numZeros).fill(-10);
      const nextGradeIndex = parsedGrades.length;


      const nextGrade = model.slope * nextGradeIndex + model.intercept;
      forecastedGrades.push(nextGrade);
      forecastedGrades.push(-10);



      return forecastedGrades;
    }
    else {
      return null;
    }
  }

  React.useEffect(() => {
    /*Set target and base*/
    fetch(`${BACKEND_URL}/api/get_target_baseline/?student_id=${sid}&course_id=${courseID}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        if (json.length > 0) {
          setTarget(json[0]['target']);
          setBaseline(json[0]['baseline']);
          setUpdateChart(!updateChart)
        }
      });
    /*Get Monitoring Details*/
    fetch(`${BACKEND_URL}/api/get_progress_monitoring/?student_id=${sid}&course_id=${courseID}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        console.log("🚀 ~ file: progressmonitoring.js:329 ~ .then ~ json:", json)
        setProgressMonitoring(json);

      }).catch((error) => {
        console.error("Error fetching progress monitoring:", error);
      });

    /*Get Student Details*/
    fetch(BACKEND_URL + "/api/get_student/" + sid, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        setStudent(json);
      });

  }, []);

  React.useEffect(() => {
    filterGraph();

  }, [progressMonitoring])


  function filterGraph() {
    const dateFromChart = dayjs(dateFrom, "DD-MM-YYYY")
    const dateToChart = dayjs(dateTo, "DD-MM-YYYY")
    const dates = progressMonitoring.map(entry => entry.date);

    let firstDate = null;
    let lastDate = null;
    if (dateFromChart == '' && dateToChart == '' && Array.isArray(dates) && dates.length > 0) {
      let startIndex = 0;
      let endIndex = Math.max(dates.length - 1, 9);
      if (dates.length <= 10) {
        startIndex = 0;
      } else {
        startIndex = endIndex - 9;
      }
      firstDate = dates[startIndex]
      lastDate = dates[endIndex]
      firstDate = dayjs(firstDate);
      lastDate = dayjs(lastDate);
    }
    else {
      firstDate = dateFromChart;
      lastDate = dateToChart
    }

    const grades = progressMonitoring.map(entry => entry.grade);
    const gradesIndexes = [];


    const filteredDates = dates.filter((date, index) => {
      const currentDate = dayjs(date);
      if (currentDate.isBetween(firstDate, lastDate) || currentDate.isSame(firstDate, 'day') || currentDate.isSame(lastDate, 'day')) {
        gradesIndexes.push(index);
        return true;
      }

      return false;
    })
    console.log("🚀 ~ file: progressmonitoring.js:738 ~ filteredDates ~ filteredDates:", filteredDates)

    const correctFilteredDates = filteredDates.map(date => dayjs(date).format("DD/MM/YYYY"))

    const filteredGrades = gradesIndexes.map((index) => grades[index]);
    const forecastedGrades = forecastNextGrades(filteredGrades, correctFilteredDates);
    const comments = progressMonitoring.map(entry => entry.comment);
    const baseline_table = new Array(grades.length).fill(baseline);
    const target_table = new Array(grades.length).fill(target);
    const star = new Image(100, 100); const forecast = new Image(15, 15);
    forecast.src = '/circle2.png'; star.src = '/star.png';
    const out_points = calculateLinearRegression(filteredGrades);
    const upper_target_points = calculateUpperTarget(filteredGrades, target);
    correctFilteredDates.push('Next Lesson')
    setChartData({
      labels: correctFilteredDates,
      datasets: [
        {
          label: 'Forecast',
          data: forecastedGrades,
          backgroundColor: 'skyblue',
          borderColor: 'skyblue',
          pointStyle: forecast,
          fill: false,
          showLine: false,
        },
        {
          label: 'Upper Target Points',
          data: upper_target_points,
          backgroundColor: 'orange',
          borderColor: 'orange',
          pointStyle: star,
          borderWidth: 1.5,
          fill: false,
          showLine: false,
        },
        {
          label: 'Score',
          data: filteredGrades,
          borderColor: 'grey',
          backgroundColor: 'grey',
          pointStyle: 'circle',
          pointRadius: 6,
          borderWidth: 1.5,
          showLine: true,
        },
        {
          label: 'Baseline',
          data: baseline_table,
          borderColor: 'rgb(252, 3, 3)',
          backgroundColor: 'rgb(252, 3, 3)',
          pointRadius: 0,
          borderWidth: 1.5,
          showLine: true,
        },
        {
          label: 'Target',
          borderWidth: 1.5,

          data: target_table,
          backgroundColor: 'rgb(0, 176, 9)',
          borderColor: 'rgb(0, 176, 9)',
          fill: false,
          pointRadius: 0,

          showLine: true,
        },

        {
          label: 'Best Straight Line',
          data: out_points,
          borderColor: '#F27216',
          backgroundColor: '#F27216',
          pointRadius: 0,
          borderDash: [5, 5],
          showLine: true,
        },
      ],
      options: {
        layout: {
        },
        scales: {
          y: {
            min: 0,
            max: 100,
            ticks: {
              padding: 0,  // Add padding
            },
            beginAtZero: true,

          },
          x: {
            ticks: {
              padding: 1,  // Add padding
              font: {
                size: 8
              }
            }
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                var datasetLabel = context.dataset.label || '';
                var dataLabel = context.parsed.y || '';

                if (datasetLabel) {
                  datasetLabel += ': ';
                }

                var index = context.dataIndex;
                var comment = comments[index] || '';

                return datasetLabel + dataLabel + ' - ' + comment;
              },
            },
          },
          onClick: function (evt, activeElements) {
            // activeElements is an array containing all the active elements (datapoints)
            if (activeElements.length > 0) {
              var chartElement = activeElements[0];  // get first active element

              var datasetIndex = chartElement._datasetIndex;
              var dataIndex = chartElement._index;

              var label = this.data.labels[dataIndex];
              var value = this.data.datasets[datasetIndex].data[dataIndex];

              console.log(`Label: ${label}, Value: ${value}`);
            }
          },
        },
      },
    });
  }



  // function filterGraph() {
  //   const dateFromChart = dayjs(dateFrom, "DD-MM-YYYY")
  //   const dateToChart = dayjs(dateTo, "DD-MM-YYYY")
  //   const dates = progressMonitoring.map(entry => entry.date);

  //   let firstDate = null;
  //   let lastDate = null;
  //   if (dateFromChart == '' && dateToChart == '' && Array.isArray(dates) && dates.length > 0) {
  //     let startIndex = 0;
  //     let endIndex = Math.max(dates.length - 1, 9);
  //     if (dates.length <= 10) {
  //       startIndex = 0;
  //     } else {
  //       startIndex = endIndex - 9;
  //     }
  //     firstDate = dates[startIndex]
  //     lastDate = dates[endIndex]
  //     firstDate = dayjs(firstDate)
  //     lastDate = dayjs(lastDate)  
  //   }
  //   else {
  //     firstDate = dateFromChart
  //     lastDate = dateToChart
  //   }
  //   const grades = progressMonitoring.map(entry => entry.grade);
  //   const gradesIndexes = [];


  //   const filteredDates = dates.filter((date, index) => {
  //     const currentDate = dayjs(date);
  //     if (firstDate.isBefore(currentDate) && lastDate.isAfter(currentDate)) {
  //       gradesIndexes.push(index);
  //       return true;
  //   }

  //     return false;
  //   })
  //   const correctFilteredDates = filteredDates.map(date => dayjs(date).format("DD/MM/YYYY"))
  //   const filteredGrades = gradesIndexes.map((index) => grades[index]);
  //   const forecastedGrades = forecastNextGrades(filteredGrades, correctFilteredDates);
  //   const comments = progressMonitoring.map(entry => entry.comment);
  //   const baseline_table = new Array(grades.length).fill(baseline);
  //   const tareget_table = new Array(grades.length).fill(target);
  //   const star = new Image(35, 35);
  //   const forecast = new Image(40, 37);
  //   forecast.src = '/circle2.png';
  //   star.src = '/star.png';
  //   const out_points = calculateLinearRegression(filteredGrades);
  //   const upper_target_points = calculateUpperTarget(filteredGrades, target);
  //   correctFilteredDates.push('Next Lesson', ' ')
  //   setChartData({
  //     labels: correctFilteredDates,
  //     datasets: [
  //       {
  //         label: 'Forecast',
  //         data: forecastedGrades,
  //         backgroundColor: 'blue',
  //         borderColor: 'blue',
  //         pointStyle: forecast,
  //         fill: false,
  //         showLine: false,
  //       },
  //       {
  //         label: 'Upper Target Points',
  //         data: upper_target_points,
  //         backgroundColor: 'orange',
  //         borderColor: 'orange',
  //         pointStyle: star,

  //         fill: false,
  //         showLine: false,
  //       },
  //       {
  //         label: 'Score',
  //         data: filteredGrades,
  //         borderColor: 'grey',
  //         backgroundColor: 'grey',
  //         pointStyle: 'circle',
  //         pointRadius: 3,
  //         showLine: true,
  //       },
  //       {
  //         label: 'Baseline',
  //         data: baseline_table,
  //         borderColor: 'rgb(252, 3, 3)',
  //         backgroundColor: 'rgb(252, 3, 3)',
  //         showLine: true,
  //       },
  //       {
  //         label: 'Target',
  //         data: tareget_table,
  //         backgroundColor: 'rgb(0, 176, 9)',
  //         borderColor: 'rgb(0, 176, 9)',
  //         fill: false,
  //         showLine: true,
  //       },

  //       {
  //         label: 'Best Straight Line',
  //         data: out_points,
  //         borderColor: '#F27216',
  //         backgroundColor: '#F27216',
  //         pointRadius: 0,
  //         borderDash: [5, 5],
  //         showLine: true,
  //       },
  //     ],
  //     options: {
  //       layout: {
  //       },
  //       scales: {
  //         y: {
  //           min: 0,
  //           max: 100,
  //           ticks: {
  //             padding: 1,  // Add padding
  //           },
  //           beginAtZero: true,

  //         },
  //         x: {
  //           ticks: {
  //             padding: 1,  // Add padding
  //           }
  //         }
  //       },
  //       plugins: {
  //         legend: {
  //           position: 'bottom',
  //         },
  //         tooltip: {
  //           callbacks: {
  //             label: function (context) {
  //               var datasetLabel = context.dataset.label || '';
  //               var dataLabel = context.parsed.y || '';

  //               if (datasetLabel) {
  //                 datasetLabel += ': ';
  //               }

  //               var index = context.dataIndex;
  //               var comment = comments[index] || '';

  //               return datasetLabel + dataLabel + ' - ' + comment;
  //             },
  //           },
  //         },
  //       },
  //     },
  //   });
  // }

  return (
    <>
      <div style={backgroundImage} />
      {chartData.labels && chartData.datasets ?
        <Box style={{ display: 'flex', backgroundColor: "#FFFFFF" }}>
          <Line id="progress-chart" options={chartData.options} style={{ marginLeft: 50, height: 'auto' }} data={chartData} />
        </Box>
        :
        <p>Loading chart...</p>
      }
    </>

  );
};
export default IndexPage;
export const Head = () => <title>Tedu - Online Learning Platform</title>;
