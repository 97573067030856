import * as React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as styles from "./styles.module.css";
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from "../components/navbar_logged_in";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Background from "../components/background";
import LottieView from "lottie-react";
import { TransitionImage } from "../components/transitionImage";
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import "@fontsource/roboto/700.css";
import Footer from "../components/footer";
import ParticleEngine from "../components/particles";
import { backgroundImage } from "../components/background";
import { BACKEND_URL } from "./helper";
import jwt_decode from "jwt-decode";
import { errorAlert } from "./helper";
import { headingStyles } from "../components/others";
import { useNavigate, useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const IndexPage = () => {
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [activeStudents, setActiveStudents] = React.useState("");
  const [inactiveStudents, setInactiveStudents] = React.useState("");

  const [courses, setCourses] = React.useState("");
  const [groups, setGroups] = React.useState("");
  const navigate = useNavigate();


  React.useEffect(() => {
    function handleScroll() {
      // handle scroll event here
      console.log("scrolling...");
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  React.useEffect(() => {
    fetch(BACKEND_URL + "/api/get_teacher/", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        setName(json[0]["first_name"]);
        setSurname(json[0]["last_name"]);
        setEmail(json[0]["email"]);
        setActiveStudents(json[0]["active_students"]);
        setInactiveStudents(json[0]["inactive_students"]);

        setCourses(json[0]["courses"]);
        setGroups(json[0]["groups"]);
      });
  }, []);

  return (
    <>
      <div style={backgroundImage} />
      <Navbar />
      <Container maxWidth="lg">
        <Box
          sx={{
            backgroundColor: "#f8f8f8",
            padding: 4,
            borderRadius: 4,
            marginTop: 6,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h1"
            style={headingStyles}
            sx={{
              marginBottom: 2,
              paddingBottom: 5,
              fontWeight: "bold",
              fontSize: "2rem",
            }}
          >
            {`${name} ${surname}`}
          </Typography>
          

<TableContainer component={Paper} sx={{ width: '100%', margin: 'auto' }}>
  <Table sx={{ minWidth: 700 }}>
    <TableHead>
      <TableRow>
       
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell component="th" scope="row">
          <Typography variant="subtitle1" style={{ fontSize: '1.4rem' }}><strong>Email</strong></Typography>
        </TableCell>
        <TableCell align="left"><Typography variant="subtitle1" style={{ fontSize: '1.4rem' }}>{email}</Typography></TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row">
          <Typography variant="subtitle1" style={{ fontSize: '1.4rem' }}><strong>Courses</strong></Typography>
        </TableCell>
        <TableCell align="left"><Typography variant="subtitle1" style={{ fontSize: '1.4rem' }}>{courses}</Typography></TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row">
          <Typography variant="subtitle1" style={{ fontSize: '1.4rem' }}><strong>Total Students</strong></Typography>
        </TableCell>
        <TableCell align="left"><Typography variant="subtitle1" style={{ fontSize: '1.4rem' }}>{parseInt(inactiveStudents) + parseInt(activeStudents)}</Typography></TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row">
          <Typography variant="subtitle1" style={{ fontSize: '1.4rem' }}><strong>Active students</strong></Typography>
        </TableCell>
        <TableCell align="left"><Typography variant="subtitle1" style={{ fontSize: '1.4rem' }}>{activeStudents}</Typography></TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row">
          <Typography variant="subtitle1" style={{ fontSize: '1.4rem' }}><strong>In-active students</strong></Typography>
        </TableCell>
        <TableCell align="left"><Typography variant="subtitle1" style={{ fontSize: '1.4rem' }}>{inactiveStudents}</Typography></TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row">
          <Typography variant="subtitle1" style={{ fontSize: '1.4rem' }}><strong>Groups</strong></Typography>
        </TableCell>
        <TableCell align="left"><Typography variant="subtitle1" style={{ fontSize: '1.4rem' }}>{groups}</Typography></TableCell>
      </TableRow>
    </TableBody>
  </Table>
</TableContainer>

        
        </Box>
      </Container>
    </>
  );
};
export default IndexPage;
export const Head = () => <title>Tedu - Online Learning Platform</title>;
