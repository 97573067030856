import { Card, CardContent, CardHeader, Typography, Grid, Button } from '@mui/material';
import React, { Component } from 'react';
import styles from './components.module.css';
import ReactCardFlip from 'react-card-flip';
import Icon from '@mdi/react';
import { mdiFaceAgent } from '@mdi/js';
import { mdiProgressCheck } from '@mdi/js';
import { mdiAccountCashOutline } from '@mdi/js';
import { mdiSchoolOutline } from '@mdi/js';
import { mdiChairSchool } from '@mdi/js';
import { mdiHeadCogOutline } from '@mdi/js';
import { headingStyles, headingStyles2, headingStyles3, headingStyles4, headingAccentStyles, headingAccentStyles2 } from '../components/others';
import { useNavigate } from "react-router-dom";

import { version } from 'react';


function CardList({cardData}) {

    const [cards, setCards] = React.useState(cardData);
    const [hoveredCard, setHoveredCard] = React.useState(null);
    const [isFlipped, setIsFlipped] = React.useState(false);
    const navigate = useNavigate();

    function handleClick(e, turn) {
        e.preventDefault();
        turn = !turn
    }
    return (
        <>
          

            <Grid container spacing={5}>
                {cardData.map((card, index) => {
                    return (
                        <Grid style={{marginTop:-20}}item xs={12} sm={6} md={4} key={index}>
                            <div
                                onMouseEnter={() => {
                                    const updatedCards = [...cards];
                                    updatedCards[index].turn = true;
                                    setCards(updatedCards);
                                }}
                                onMouseLeave={() => {
                                    const updatedCards = [...cards];
                                    updatedCards[index].turn = false;
                                    setCards(updatedCards);
                                }}
                            >
                                <ReactCardFlip isFlipped={card.turn} flipDirection="horizontal">
                                    <Card
                                        className={styles.boxholder}
                                        sx={{
                                            color: "#474b4f",
                                            border: '1px solid  #CCCCCC',
                                            borderRadius: '10px',
                                            height: 220,
                                            width: 280,
                                        }}
                                        onMouseEnter={() => setHoveredCard(index)}
                                        onMouseLeave={() => setHoveredCard(null)}
                                    >
                                        <CardContent
                                           style={{
                                                background: 'rgb(255,255,255)',
                                                alignItems: 'center',
                                              
                                                justifyContent: 'center',
                                                borderRadius: '999px'}}
                                            >
                                          
                                                <img src={card.logo} alt='Description of Image' style={{ alignSelf:"center", transform: 'scale(2.0)', marginTop: 20, height: 100 }} />

                                            <Typography style={{ fontSize: 24, fontWeight: 'bold', marginTop: 10 }} >{card.title}</Typography>

                                        </CardContent>
                                    </Card>
                                    <Card
                                        className={styles.boxholder}
                                        sx={{
                                            color: "#474b4f",
                                            border: '1px solid  #CCCCCC',
                                            borderRadius: '10px',
                                            height: 220,
                                            width: 280,
                                        }}
                                        onMouseEnter={() => setHoveredCard(index)}
                                        onMouseLeave={() => setHoveredCard(null)}
                                    >
                                        <Typography style={{ fontSize: 24, fontWeight: 'bold', marginTop: 30 }} >{card.title}</Typography>
                                        <CardContent style={{
                                                background: 'rgb(255,255,255)',
                                                marginBottom: 5,
                                                marginTop: 5,
                                                marginLeft: 1,
                                                marginRight: 1,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: '999px'
                                            }}>
                                                <Button variant='contained'     onClick={() =>navigate(card.link)} 
 sx={{ marginTop: 3, padding: 1, backgroundColor: 'orange' }} >{card.buttontext}</Button>
                                        </CardContent>
                                    </Card>
                                </ReactCardFlip>
                            </div>
                        </Grid>
                    )
                }
                )}
            </Grid>

        </>
    );
}

export default CardList;