import * as React from "react";
 
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as styles from "./styles.module.css";
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from "../components/navbar";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Background from "../components/background";
import LottieView from "lottie-react";
import { TransitionImage } from "../components/transitionImage";
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import "@fontsource/roboto/700.css";
import Footer from "../components/footer";
import ParticleEngine from "../components/particles";
import {backgroundImage} from "../components/background";
import { useNavigate } from 'react-router-dom';
import axios from "./axios";
import { BACKEND_URL } from "./helper";
import jwt_decode from "jwt-decode";
import { errorAlert } from "./helper";
import {headingStyles} from '../components/others';
import IconButton from '@mui/material/IconButton';
import InputAdornment from  '@mui/material/InputAdornment';
import Visibility from  '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
const handleSubmit = (event) => {
  event.preventDefault();
  // Add your form submission logic here
};

const IndexPage = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [pwd, setPwd] = React.useState("");
  const [error, setError] = React.useState(null);
  const [errMsg, setErrMsg] = React.useState("");
  const emailRef = React.useRef();
  const navigate = useNavigate();
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
};
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "/api/auth",
        JSON.stringify({ email, pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      const verified = response?.data?.verified;
      let decoded = jwt_decode(response?.data?.accessToken);
      let accessToken = response?.data?.accessToken;

      localStorage.setItem("id", decoded.UserInfo.id);
      localStorage.setItem("accessToken", accessToken);

      navigate("/homeadmin", { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("Network Failure");
      } else if (err.response?.status === 400) {
        setErrMsg("Please insert email address and password");
      } else if (err.response?.status === 401) {
        setErrMsg("Wrong email address or password");
      } else if (err.response?.status === 406) {
        setErrMsg(
          "Your account has been temporarily locked for security reasons. Please try again later."
        );
      } else {
        setErrMsg("Network Failure");
      }
    }
  };

  React.useEffect(() => {
    fetch(BACKEND_URL + "/api/check_auth/", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }).then((response) => {
      // console.log(response.status)
      if (response.status === 200) {
        let decoded = jwt_decode(localStorage.getItem("accessToken"));
        if (decoded.UserInfo.roles === "user") {
          setErrMsg("Δεν μπορεί να γίνει σύνδεση");
          // return Promise.reject();
        } else {
          navigate("/students", { replace: false });
        }
      } else {
        localStorage.clear();
      }
    });
    emailRef.current.focus();
  }, []);

  React.useEffect(() => {
    setErrMsg("");
  }, [email, pwd]);

  return (
    <>
     <div style={backgroundImage} />
      <Navbar />
      <Container
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        component="main"
        maxWidth="xs"
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center", // Added to vertically center the content
            backgroundColor: "white", // Set the background color to white
            paddingTop: "20px", // Add 20px padding on all sides
            paddingBottom: "20px", // Add 20px padding on all sides
            paddingLeft: "50px", // Add 20px padding on all sides
            paddingRight: "50px", // Add 20px padding on all sides
            height:400,
            width:500,
            borderRadius: 5,
          }}
        >
          {/* <img src={logo} style={{ height: 28, width: 200 }} alt="Logo" /> */}
          <Typography variant="h4" component="h1" style={headingStyles} align="center">
            Log in 
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              ref={emailRef}
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              value={pwd}
              onChange={(e) => setPwd(e.target.value)}
              label="Password"
              type={showPassword ? 'text' : 'password'}

              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {errMsg !== "" ? errorAlert(errMsg) : null}
            <Button
              type="submit"
              fullWidth
              id="login-button"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>

            <Grid container style={{ display: "flex" }} spacing={1}>
              <Grid
                item
                style={{ justifyContent: "flex-start", display: "flex" }}
                xs={6}
              >
                <Link
                  href="../privacy-policy"
                  style={{
                    marginTop: "20px",
                    fontSize: "14px",
                    color: "#155263",
                  }}
                  variant="body2"
                >
                  {"Privacy Policy"}
                </Link>
              </Grid>
              <Grid
                item
                style={{ justifyContent: "flex-end", display: "flex" }}
                xs={6}
              >
                <Link
                  href="../forgot-password"
                  style={{
                    marginTop: "20px",
                    fontSize: "14px",
                    color: "#155263",
                  }}
                  variant="body2"
                >
                  {"Forgot Password?"}
                </Link>
              </Grid>
            </Grid>

            {error && <p>{error.message}</p>}
          </Box>
        </Box>
      </Container>
    </>
  );
};
export default IndexPage;
export const Head = () => <title>Tedu - Online Learning Platform</title>;
