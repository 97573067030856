import * as React from "react";
 
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import  styles from "./styles.module.css";
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from "../components/navbar";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Background from "../components/background";
import LottieView from "lottie-react";
import { TransitionImage } from "../components/transitionImage";
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import "@fontsource/roboto/700.css";
import Footer from "../components/footer";
import ParticleEngine from "../components/particles";
import {backgroundImage} from "../components/background";
import {
  InputLabel,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Alert,
  FormControl,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { errorAlert } from "./helper";
import { BACKEND_URL } from "./helper";
import Swal from "sweetalert2";

const handleSubmit = (event) => {
  event.preventDefault();
  // Add your form submission logic here
};

const IndexPage = () => {
  const [open, setOpen] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [code, setCode] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordVer, setPasswordVer] = React.useState("");
  const [status, setStatus] = React.useState("1");
  const [errorCode, setErrorCode] = React.useState("");
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const SubmitButton = (
    status,
    email,
    code,
    password,
    passwordVer,
    setStatus,
    setErrorCode
  ) => {
    return (
      <>
        <Button
          style={{
            color: "white",
            backgroundColor: "primary",
            fontSize: 16,
            padding: "12px 20px",
            borderRadius: 4,
            width: "200px",
            marginLeft: "50px",
          }}
          variant="contained"
          onClick={async () => {
            if (status == "1") {
              if (email != "") {
                await fetch(BACKEND_URL + "/api/forgot_password/" + email, {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                })
                  .then((response) => {
                    if (response.status === 403) {
                      localStorage.clear();
                      navigate("/login", { replace: true });
                      return Promise.reject();
                    } else {
                      return response;
                    }
                  })
                  .then((response) => response.json())
                  .then((json) => {
                    // console.log('json.message',json.message)
                    // Handle the response here
                    if (json.message == "Update was successful") {
                      setStatus("2");
                      setErrorCode("");
                    } else {
                      // There was an error updating the password
                      setErrorCode(
                        "We have not found any account associated with this email address. Please try again."
                      );
                    }
                  });
              } else {
                setErrorCode(
                  "Email address cannot be empty. Please try again."
                );
              }
            } else if (status == "2") {
              await fetch(BACKEND_URL + "/api/get_password_reset/", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email: email,
                  reset_passcode: code,
                }),
              })
                .then((response) => {
                  if (response.status === 403) {
                    localStorage.clear();
                    navigate("/login", { replace: true });
                    return Promise.reject();
                  } else {
                    return response;
                  }
                })
                .then((response) => response.json())
                .then((json) => {
                  // console.log('json.message',json.message)
                  // Handle the response here
                  if (json.message == "Valid") {
                    setStatus("3");
                    setErrorCode("");
                  } else if (json.message == "Blocked") {
                    setErrorCode(
                      "Your account has been temporarily locked for security reasons. Please try again later."
                    );
                  } else {
                    // There was an error updating the password
                    setErrorCode(
                      "The recovery code you entered is invalid. Please try again."
                    );
                  }
                });
            } else {
              let password_valid = true;
              let password__verification_valid = true;
              if (
                password.length <= 9 ||
                !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/.test(password)
              ) {
                password_valid = false;
              }
              if (password != passwordVer) {
                password__verification_valid = false;
              }
              if (password_valid) {
                if (password__verification_valid) {
                  await fetch(
                    BACKEND_URL + "/api/implement_password_reset/" + email,
                    {
                      method: "PUT",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        password: password,
                        reset_passcode: code,
                      }),
                    }
                  )
                    .then((response) => {
                      if (response.status === 403) {
                        localStorage.clear();
                        navigate("/login", { replace: true });
                        return Promise.reject();
                      } else {
                        return response;
                      }
                    })
                    .then((response) => response.json())
                    .then((json) => {
                      // console.log('json.message',json.message)
                      // Handle the response here
                      if (json.message == "Update was successful") {
                        Swal.fire({
                          title: "Password updated",
                          text: "Your password was updated successfully.",
                          icon: "success",
                          confirmButtonColor: "#155263",
                          confirmButtonText: "OK",
                        }).then((result) => {
                          navigate("/login");
                        });
                      } else {
                        // There was an error updating the password
                        setErrorCode(
                          "An error occurred while resetting your password. Please try again."
                        );
                      }
                    });
                } else {
                  setErrorCode(
                    "Your password verification is invalid. Please try again."
                  );
                }
              } else {
                setErrorCode(
                  "The password you provided does not meet the required specifications. Please try again."
                );
              }
            }
          }}
        >
          Submit
        </Button>
      </>
    );
  };

  const CancelButton = () => {
    return (
      <>
        <Button
          style={{
            color: "white",
            backgroundColor: "primary",
            fontSize: 16,
            padding: "12px 20px",
            borderRadius: 4,
            width: "200px",
          }}
          variant="contained"
          onClick={() => window.location.replace("../login")}
        >
          Cancel
        </Button>
      </>
    );
  };

  return (
    <>
      <div style={backgroundImage} />
      <Navbar />
      <ParticleEngine />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              sx={{
                paddingBottom: 5,
                paddingTop: 5,
                paddingLeft: 5,
                paddingRight: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <React.Fragment>
                <h1>Password Recovery</h1>
              </React.Fragment>
              <Box sx={{ flexGrow: 1, backgroundColor: "White" }}>
                <FormLabel>
                  Please submit the required information to create a new
                  password.
                </FormLabel>
                <br></br>
                <br></br>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  {status !== "3" && (
                    <Grid item md={12} xs={12}>
                      <InputLabel className={styles.bigblue1}>
                        Email Address
                      </InputLabel>
                      <InputLabel
                        className={styles.biggrey}
                        sx={{ whiteSpace: "normal" }}
                        style={{ color: "black", fontSize: 12 }}
                      >
                        Enter the email address you created your account with
                      </InputLabel>
                      <TextField
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                        required
                        className={styles.bigblue1}
                        fullWidth
                        style={{
                          backgroundColor:
                            email === ""
                              ? "white"
                              : /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                  email
                                )
                              ? "white"
                              : "pink",
                        }}
                        disabled={status === "2"}
                      ></TextField>
                    </Grid>
                  )}

                  {status !== "1" && status !== "3" && (
                    <Grid item md={12} xs={12}>
                      <InputLabel className={styles.bigblue1}>
                        Recovery Code
                      </InputLabel>
                      <InputLabel
                        className={styles.biggrey}
                        sx={{ whiteSpace: "normal" }}
                        style={{ color: "black", fontSize: 12 }}
                      >
                        Enter the recovery code received in the email address
                      </InputLabel>
                      <TextField
                        onChange={(e) => {
                          setCode(e.target.value);
                        }}
                        value={code}
                        required
                        className={styles.bigblue1}
                        fullWidth
                      ></TextField>
                    </Grid>
                  )}

                  {status == "3" && (
                    <Grid item md={6} xs={6}>
                      <InputLabel fullWidth className={styles.bigblue1}>
                        Password
                      </InputLabel>
                      <InputLabel
                        className={styles.biggrey}
                        sx={{ whiteSpace: "normal" }}
                        style={{ color: "black", fontSize: 12 }}
                      >
                        Please enter your new password
                      </InputLabel>
                      <TextField
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        value={password}
                        required
                        className={styles.bigblue1}
                        fullWidth
                        type="password"
                        style={{
                          backgroundColor:
                            password === ""
                              ? "white"
                              : password.length <= 9 ||
                                !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/.test(
                                  password
                                )
                              ? "pink"
                              : "white",
                        }}
                      ></TextField>
                    </Grid>
                  )}

                  {status == "3" && (
                    <Grid item md={6} xs={6}>
                      <InputLabel fullWidth className={styles.bigblue1}>
                        Password Verification
                      </InputLabel>
                      <InputLabel
                        className={styles.biggrey}
                        sx={{ whiteSpace: "normal" }}
                        style={{ color: "black", fontSize: 12 }}
                      >
                        Please verify your new password
                      </InputLabel>
                      <TextField
                        onChange={(e) => {
                          setPasswordVer(e.target.value);
                        }}
                        value={passwordVer}
                        required
                        className={styles.bigblue1}
                        fullWidth
                        type="password"
                        style={{
                          backgroundColor:
                            passwordVer.length === 0
                              ? "white"
                              : passwordVer === password
                              ? "white"
                              : "pink",
                        }}
                      ></TextField>
                    </Grid>
                  )}

                  {status == "3" && (
                    <p style={{ fontStyle: "italic", fontSize: "11pt" }}>
                      Password must be at least 10 characters long and contain
                      at least one letter, number and special character
                    </p>
                  )}

                  <Grid item md={12} xs={12}>
                    {errorCode !== "" ? <>{errorAlert(errorCode)}</> : null}
                    <br></br>
                  </Grid>

                  <Grid>
                    <br></br>
                    {CancelButton()}
                    {SubmitButton(
                      status,
                      email,
                      code,
                      password,
                      passwordVer,
                      setStatus,
                      setErrorCode
                    )}
                    <br></br>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default IndexPage;
export const Head = () => <title>Tedu - Online Learning Platform</title>;
