


import * as React from "react";
 import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import  styles from "./styles.module.css";
import Navbar from "../components/navbar_logged_in";
import Divider from "@mui/material/Divider";
import "@fontsource/roboto/700.css";
import {backgroundImage} from "../components/background";
import { BACKEND_URL } from "./helper";
import { useNavigate } from 'react-router-dom';import Swal from "sweetalert2";
import { MdEdit } from 'react-icons/md';
import { BsTrash } from 'react-icons/bs';
import {IoAddCircleOutline} from 'react-icons/io5'
import {HiOutlineDocumentReport} from 'react-icons/hi'
import StudentBox from "../components/studentBox";

const IndexPage = () => {
  const [students, setStudents] = React.useState("");
  const navigate = useNavigate();
  const [courses, setCourses] = React.useState("");
  const [groups, setGroups] = React.useState("");

  React.useEffect(() => {
    fetch(BACKEND_URL + "/api/get_groups/", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        setGroups(json);
      });
  }, []);

  React.useEffect(() => {
    fetch(BACKEND_URL + "/api/get_courses/", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        setCourses(json);
      });
  }, []);

  React.useEffect(() => {
    fetch(BACKEND_URL + "/api/get_students/", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        setStudents(json);
      });
  }, []);

  return (
    <>
      <div style={backgroundImage} />
      <Navbar />
      <Container maxWidth='xl'>
        <Box
          sx={{
            backgroundColor: "#f8f8f8",
            padding: 4,
            borderRadius: 4,
            marginTop: 6,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h1"
            style={{
              borderTopRightRadius: '33px',
              fontFamily: 'Roboto',
              fontSize: '2rem',
              fontWeight: 500,
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'grey',
              marginBottom: 5,
              paddingBottom: 15,
              paddingTop: 10,
              paddingLeft: 10,
              fontWeight: 'bold',
            }}
          >
            <span style={{ marginRight: '5px' }}>My Groups</span>
            <button
              style={{
                background: "none",
                border: "none",
                padding: "0",
                cursor: "pointer",
                fontSize: "16px",
              }}
              title="Add"
              onClick={() => navigate("/add_groups")}
            >
              <IoAddCircleOutline style={{ color: "white", marginLeft: -100, fontSize: 30, marginTop: 5 }} /> &nbsp;
            </button>
          </Typography>


          <div style={{ marginTop: 30 }}>
            <Grid container spacing={1}>
              {Array.isArray(groups)
                ? groups.map((group) => (
                  <Grid item md={3} sm={6} xs={12} key={group.group_id}>
                    <div
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        padding: "10px",
                        borderTopRightRadius: '33px',
                        marginBottom: "3px",
                        zIndex: -100,
                      }}
                      onClick={() =>{
                        console.log(group)
                        navigate(
                          "/studentsgroup/sid/" + group.group_id + "/groupName/" + group.group_name
                        )
                      }
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                          <div style={{ display: 'flex' }}>
                            <h2 style={{ marginRight: '10px', marginTop: -5 }}>
                              {group.group_name}
                            </h2>
                            <div style={{ marginLeft: 'auto', marginTop: -10 }}>

                            </div>



                            {/* <button
                              style={{
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                zIndex: 2,
                                marginTop: -15
                              }}
                              title="Edit"
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                  "/progressmonitoring/sid/" +
                                  group.group_id +
                                  "/group/" +
                                  group.group_name
                                );
                              }}
                            >
                              <GiProgression style={{ color: "grey", fontSize: 25 }} />
                            </button> */}

                            <button
                              style={{
                                background: "none",
                                border: "none",
                                padding: "0",
                                marginRight: "5px",
                                cursor: "pointer",
                                marginTop: -15,
                                zIndex: 2,
                              }}
                              title="Edit"
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate("/editgroups/sid/" + group.group_id);
                              }}
                            >
                              <MdEdit style={{ color: 'grey', fontSize: 25 }} />
                            </button>
                            <button
                              style={{
                                background: "none",
                                border: "none",
                                padding: "0",
                                marginRight: "5px",
                                cursor: "pointer",
                                fontSize: "24px",
                                marginTop: -15,
                                zIndex: 2,
                              }}
                              title="Delete"
                              onClick={async (e) => {
                                e.stopPropagation(); // Stop event propagation

                                let do_the_request = true;
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: `Please verify that you want to delete the specific group.`,
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#d33",
                                  cancelButtonColor: "#3085d6",
                                  confirmButtonText: "Delete",
                                  cancelButtonText: "Cancel",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    do_the_request = true;
                                  } else {
                                    do_the_request = false;
                                  }
                                  if (do_the_request) {
                                    fetch(
                                      BACKEND_URL +
                                      "/api/delete_group/" +
                                      group.group_id,
                                      {
                                        method: "DELETE",
                                        headers: {
                                          Accept: "application/json",
                                          "Content-Type": "application/json",
                                          Authorization: `Bearer ${localStorage.getItem(
                                            "accessToken"
                                          )}`,
                                        },
                                      }
                                    )
                                      .then((response) => {
                                        if (response.status === 403) {
                                          localStorage.clear();
                                          navigate("/login", { replace: true });
                                          return Promise.reject();
                                        } else {
                                          return response;
                                        }
                                      })
                                      .then((response) => response.json())
                                      .then((json) => {
                                        // Handle the response here
                                        if (
                                          json.message ==
                                          "Deletion was successful"
                                        ) {
                                          // Success
                                          Swal.fire({
                                            title: " Deleted",
                                            text: "The group was deleted successfully",
                                            icon: "success",
                                            confirmButtonColor: "#155263",
                                            confirmButtonText: "OK",
                                          }).then((result) => {
                                            fetch(
                                              BACKEND_URL + "/api/get_groups/",
                                              {
                                                headers: {
                                                  Authorization: `Bearer ${localStorage.getItem(
                                                    "accessToken"
                                                  )}`,
                                                },
                                              }
                                            )
                                              .then((response) => {
                                                if (response.status === 403) {
                                                  localStorage.clear();
                                                  navigate("/login", { replace: true });
                                                  return Promise.reject();
                                                } else {
                                                  return response;
                                                }
                                              })
                                              .then((response) =>
                                                response.json()
                                              )
                                              .then((json) => {
                                                setGroups(json);
                                              });
                                          }, []);
                                        } else {
                                          // Success
                                          Swal.fire({
                                            title: "Error",
                                            text: "An unexpected error occurred. Please try again.",
                                            icon: "error",
                                            confirmButtonColor: "#155263",
                                            confirmButtonText: "OK",
                                          }).then((result) => { });
                                        }
                                      });
                                  }
                                });
                              }}
                            >
                              <BsTrash style={{ color: 'grey' }} />
                            </button>
                          </div>
                          <Divider />
                          <Grid container>
                            <Grid item xs={6} >
                              <Button onClick={(e) =>{
                              e.stopPropagation()
                                navigate(
                                  "/groupparticipations/sid/" + group.group_id+"/groupName/"+ group.group_name
                                )}
                              } fullWidth style={{ borderRadius: 10, zIndex:900, marginTop: 10, color: "white", fontSize: 11, backgroundColor: "#f87414", textTransform: 'none' }}>Add/Remove Students</Button>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>

                  </Grid>
                ))
                : null}
            </Grid>
          </div>
        </Box>
      </Container>

      <Container maxWidth='xl'>
        <Box
          sx={{
            backgroundColor: "#f8f8f8",
            padding: 4,
            borderRadius: 4,
            marginTop: 6,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h1"
            style={{
              borderTopRightRadius: '33px',
              fontFamily: 'Roboto',
              fontSize: '2rem',
              fontWeight: 500,
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'grey',
              marginBottom: 5,
              paddingBottom: 15,
              paddingTop: 10,
              paddingLeft: 10,
              fontWeight: 'bold',
            }}
          >
            <span style={{ marginRight: '5px' }}>My Courses</span>
            <button
              style={{
                background: "none",
                border: "none",
                padding: "0",
                cursor: "pointer",
                fontSize: "16px",
              }}
              title="Add"
              onClick={() => navigate("/add_courses")}
            >
              <IoAddCircleOutline style={{ color: "white", marginLeft: -100, fontSize: 30, marginTop: 5 }} /> &nbsp;
            </button>
          </Typography>


          <div style={{ marginTop: 30 }}>
            <Grid container spacing={1}>
              {Array.isArray(courses)
                ? courses.map((course) => (
                  <Grid item md={3} sm={6} xs={12} key={course.course_id}>
                    <div
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        padding: "10px",
                        borderTopRightRadius: '33px',
                        marginBottom: "3px",
                        zIndex: -100,
                      }}
                      onClick={() =>{
                        console.log(course)
                        navigate(
                          "/studentscourse/sid/" + course.course_id + "/courseName/" + course.course_name
                        )
                      }
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                          <div style={{ display: 'flex' }}>
                            <h2 style={{ marginRight: '10px', marginTop: -5 }}>
                              {course.course_name}
                            </h2>
                            <div style={{ marginLeft: 'auto', marginTop: -10 }}>

                            </div>



                            {/* <button
                              style={{
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                zIndex: 2,
                                marginTop: -15
                              }}
                              title="Edit"
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                  "/progressmonitoring/sid/" +
                                  course.course_id +
                                  "/group/" +
                                  course.course_name
                                );
                              }}
                            >
                              <GiProgression style={{ color: "grey", fontSize: 25 }} />
                            </button> */}

                            <button
                              style={{
                                background: "none",
                                border: "none",
                                padding: "0",
                                marginRight: "5px",
                                cursor: "pointer",
                                marginTop: -15,
                                zIndex: 2,
                              }}
                              title="Edit"
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate("/editcourses/sid/" + course.course_id);
                              }}
                            >
                              <MdEdit style={{ color: 'grey', fontSize: 25 }} />
                            </button>
                            <button
                              style={{
                                background: "none",
                                border: "none",
                                padding: "0",
                                marginRight: "5px",
                                cursor: "pointer",
                                fontSize: "24px",
                                marginTop: -15,
                                zIndex: 2,
                              }}
                              title="Delete"
                              onClick={async (e) => {
                                e.stopPropagation(); // Stop event propagation

                                let do_the_request = true;
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: `Please verify that you want to delete the specific course.`,
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#d33",
                                  cancelButtonColor: "#3085d6",
                                  confirmButtonText: "Delete",
                                  cancelButtonText: "Cancel",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    do_the_request = true;
                                  } else {
                                    do_the_request = false;
                                  }
                                  if (do_the_request) {
                                    fetch(
                                      BACKEND_URL +
                                      "/api/delete_course/" +
                                      course.course_id,
                                      {
                                        method: "DELETE",
                                        headers: {
                                          Accept: "application/json",
                                          "Content-Type": "application/json",
                                          Authorization: `Bearer ${localStorage.getItem(
                                            "accessToken"
                                          )}`,
                                        },
                                      }
                                    )
                                      .then((response) => {
                                        if (response.status === 403) {
                                          localStorage.clear();
                                          navigate("/login", { replace: true });
                                          return Promise.reject();
                                        } else {
                                          return response;
                                        }
                                      })
                                      .then((response) => response.json())
                                      .then((json) => {
                                        // Handle the response here
                                        if (
                                          json.message ==
                                          "Deletion was successful"
                                        ) {
                                          // Success
                                          Swal.fire({
                                            title: " Deleted",
                                            text: "The course was deleted successfully",
                                            icon: "success",
                                            confirmButtonColor: "#155263",
                                            confirmButtonText: "OK",
                                          }).then((result) => {
                                            fetch(
                                              BACKEND_URL + "/api/get_courses/",
                                              {
                                                headers: {
                                                  Authorization: `Bearer ${localStorage.getItem(
                                                    "accessToken"
                                                  )}`,
                                                },
                                              }
                                            )
                                              .then((response) => {
                                                if (response.status === 403) {
                                                  localStorage.clear();
                                                  navigate("/login", { replace: true });
                                                  return Promise.reject();
                                                } else {
                                                  return response;
                                                }
                                              })
                                              .then((response) =>
                                                response.json()
                                              )
                                              .then((json) => {
                                                setCourses(json);
                                              });
                                          }, []);
                                        } else {
                                          // Success
                                          Swal.fire({
                                            title: "Error",
                                            text: "An unexpected error occurred. Please try again.",
                                            icon: "error",
                                            confirmButtonColor: "#155263",
                                            confirmButtonText: "OK",
                                          }).then((result) => { });
                                        }
                                      });
                                  }
                                });
                              }}
                            >
                              <BsTrash style={{ color: 'grey' }} />
                            </button>
                          </div>
                          <Divider />
                          <Grid container>
                            <Grid item xs={6} >
                              <Button onClick={(e) =>{
                              e.stopPropagation()
                                navigate(
                                  "/courseparticipations/sid/" + course.course_id+"/courseName/"+ course.course_name
                                )}
                              } fullWidth style={{ borderRadius: 10, zIndex:900, marginTop: 10, color: "white", fontSize: 11, backgroundColor: "#f87414", textTransform: 'none' }}>Add/Remove Students</Button>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>

                  </Grid>
                ))
                : null}
            </Grid>
          </div>
        </Box>
      </Container>
      <Container maxWidth='xl'>
        <Box
          sx={{
            backgroundColor: "#f8f8f8",
            padding: 4,
            borderRadius: 4,
            marginTop: 6,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h1"
            style={{
              borderTopRightRadius: '33px',
              fontFamily: 'Roboto',
              fontSize: '2rem',
              fontWeight: 500,
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'grey',
              marginBottom: 5,
              paddingBottom:15,
              paddingTop:10,
              paddingLeft:10,
              fontWeight: 'bold',
            }}
          >
            <span style={{ marginRight: '5px' }}>My Students</span>
            <button
              style={{
                background: 'none',
                border: 'none',
                color:'white',
                fontFamily: 'Roboto',
                fontSize: '2rem',
                fontWeight: 500,
                padding: '0',
                cursor: 'pointer',
                fontSize: '16px',
              }}
              title="Add Student"
              onClick={() => navigate('/add_students')}
            >
            <IoAddCircleOutline style={{color:"white", marginLeft:-100, fontSize:30, marginTop:5}} /> &nbsp;
            </button>
          </Typography>

          <div style={{marginTop:30}}>
            <Grid container spacing={1}>
              {Array.isArray(students)
                ? students.map((student) => (
                  <StudentBox student={student} setStudents={setStudents}/>
                ))
                : null}
            </Grid>
          </div>
        </Box>
      </Container>
    </>
  );
};
export default IndexPage;
export const Head = () => <title>Tedu - Online Learning Platform</title>;
