import * as React from "react";
 
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styles from "./styles.module.css";
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from "../components/navbar_logged_in";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Background from "../components/background";
import LottieView from "lottie-react";
import { TransitionImage } from "../components/transitionImage";
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import "@fontsource/roboto/700.css";
import Footer from "../components/footer";
import ParticleEngine from "../components/particles";
import {backgroundImage} from "../components/background";
import { useNavigate } from 'react-router-dom';import axios from "./axios";
import { BACKEND_URL } from "./helper";
import jwt_decode from "jwt-decode";
import { errorAlert } from "./helper";
import { headingStyles } from "../components/others";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa"; // Example icons from Font Awesome
import { useQueryParam, NumberParam, StringParam } from "use-query-params";

import {
  InputLabel,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Alert,
  FormControl,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const IndexPage = () => {

  const [open, setOpen] = React.useState(true);
  const [firstName, setFirstName] = React.useState("");
  const [middleName, setMiddleName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [birthDay, setBirthDay] = React.useState("");
  const [birthMonth, setBirthMonth] = React.useState("");
  const [birthYear, setBirthYear] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [personResponsible, setPersonResponsible] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [secondPhoneNumber, setSecondPhoneNumber] = React.useState("");
  const [course, setCourse] = React.useState("");
  const [specificField, setSpecificField] = React.useState("");
  const [additionalComments, setAdditionalComments] = React.useState("");
  const [active, setActive] = React.useState("");
  const navigate = useNavigate();
  const activeOptions = ["Yes", "No"];

  const { sid } = useParams();

  React.useEffect(() => {
    fetch(BACKEND_URL + "/api/get_student/" + sid, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        setFirstName(json[0]["first_name"]);
        setMiddleName(json[0]["middle_name"]);
        setLastName(json[0]["last_name"]);
        setBirthDay(json[0]["birth_day"]);
        setBirthMonth(json[0]["birth_month"]);
        setBirthYear(json[0]["birth_year"]);
        setGender(json[0]["gender"]);
        setEmail(json[0]["email"]);
        setPersonResponsible(json[0]["person_responsible"]);
        setPhoneNumber(json[0]["phone_number"]);
        setSecondPhoneNumber(json[0]["second_phone_number"]);
        setCourse(json[0]["course"]);
        setSpecificField(json[0]["specific_field"]);
        setAdditionalComments(json[0]["additional_comments"]);
        setActive(active_decode(json[0]["active"]));
      });
  }, []);

  // Function to decode a boolean value
  function active_decode(parameter) {
    if (parameter) {
      return "Yes";
    } else {
      return "No";
    }
  }

  // Function to encode 'Yes' or 'No' as boolean values
  function active_encode(value) {
    if (value === "Yes") {
      return true;
    } else if (value === "No") {
      return false;
    } else {
      // Handle invalid input
      return null;
    }
  }

  const currentYear = new Date().getFullYear();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const years = Array.from(
    { length: currentYear - 1950 + 1 },
    (_, i) => currentYear - i
  );
  const genderOptions = ["Female", "Male", "Other"];

  const CancelButton = () => {
    return (
      <>
        <Button
          style={{
            color: "white",
            backgroundColor: "primary",
            fontSize: 16,
            padding: "12px 20px",
            borderRadius: 4,
            width: "200px",
          }}
          variant="contained"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </>
    );
  };

  React.useEffect(() => {
    function handleScroll() {
      // handle scroll event here
      console.log("scrolling...");
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div style={backgroundImage} />
      <Navbar />
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              sx={{
                paddingBottom: 5,
                paddingTop: 5,
                paddingLeft: 5,
                paddingRight: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <React.Fragment>
                <h1>Student Profile</h1>
              </React.Fragment>
              <Box sx={{ flexGrow: 1, backgroundColor: "White" }}>
                <FormLabel>
                  Fill out the form carefully for registration
                </FormLabel>
                <br></br>
                <br></br>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item md={12} xs={12}>
                    <InputLabel className={styles.bigblue1}>
                      <Typography variant="h6">
                        <strong>Student Name</strong>
                      </Typography>
                    </InputLabel>
                  </Grid>
                  <Grid item md={4} xs={4}>
                    <TextField
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      value={firstName}
                      required
                      className={styles.bigblue1}
                      fullWidth
                      disabled
                    ></TextField>
                    <InputLabel className={styles.bigblue1}>
                      First Name
                    </InputLabel>
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <TextField
                      onChange={(e) => {
                        setMiddleName(e.target.value);
                      }}
                      value={middleName}
                      required
                      className={styles.bigblue1}
                      fullWidth
                      disabled
                    ></TextField>
                    <InputLabel className={styles.bigblue1}>
                      Middle Name
                    </InputLabel>
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <TextField
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      value={lastName}
                      required
                      className={styles.bigblue1}
                      fullWidth
                      disabled
                    ></TextField>
                    <InputLabel className={styles.bigblue1}>
                      Last Name
                    </InputLabel>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <InputLabel className={styles.bigblue1}>
                      <Typography variant="h6">
                        <strong>Birth Date</strong>
                      </Typography>
                    </InputLabel>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <InputLabel className={styles.bigblue1}>
                      <Typography variant="h6">
                        <strong>Gender</strong>
                      </Typography>
                    </InputLabel>
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <TextField
                      onChange={(e) => {
                        setBirthDay(e.target.value);
                      }}
                      value={birthDay}
                      required
                      className={styles.bigblue1}
                      fullWidth
                      disabled
                    ></TextField>
                    <InputLabel fullWidth className={styles.bigblue1}>
                      Day
                    </InputLabel>
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <TextField
                      onChange={(e) => {
                        setBirthMonth(e.target.value);
                      }}
                      value={birthMonth}
                      required
                      className={styles.bigblue1}
                      fullWidth
                      disabled
                    ></TextField>
                    <InputLabel fullWidth className={styles.bigblue1}>
                      Month
                    </InputLabel>
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <TextField
                      onChange={(e) => {
                        setBirthYear(e.target.value);
                      }}
                      value={birthYear}
                      required
                      className={styles.bigblue1}
                      fullWidth
                      disabled
                    ></TextField>
                    <InputLabel fullWidth className={styles.bigblue1}>
                      Year
                    </InputLabel>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <TextField
                      onChange={(e) => {
                        setGender(e.target.value);
                      }}
                      value={gender}
                      required
                      className={styles.bigblue1}
                      fullWidth
                      disabled
                    ></TextField>
                    <InputLabel fullWidth className={styles.bigblue1}>
                      &nbsp;
                    </InputLabel>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <InputLabel className={styles.bigblue1}>
                      <Typography variant="h6">
                        <strong>Email</strong>
                      </Typography>
                      <InputLabel fullWidth className={styles.bigblue1}>
                        &nbsp;
                      </InputLabel>
                    </InputLabel>
                    <TextField
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                      required
                      className={styles.bigblue1}
                      fullWidth
                      disabled
                    ></TextField>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <InputLabel className={styles.bigblue1}>
                      <Typography variant="h6">
                        <strong>Person Responsible</strong>
                      </Typography>
                      <InputLabel fullWidth className={styles.bigblue1}>
                        &nbsp;
                      </InputLabel>
                    </InputLabel>
                    <TextField
                      onChange={(e) => {
                        setPersonResponsible(e.target.value);
                      }}
                      value={personResponsible}
                      required
                      className={styles.bigblue1}
                      fullWidth
                      disabled
                    ></TextField>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <InputLabel className={styles.bigblue1}>
                      <Typography variant="h6">
                        <strong>Phone Number</strong>
                      </Typography>
                      <InputLabel fullWidth className={styles.bigblue1}>
                        &nbsp;
                      </InputLabel>
                    </InputLabel>
                    <TextField
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      value={phoneNumber}
                      required
                      className={styles.bigblue1}
                      fullWidth
                      disabled
                    ></TextField>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <InputLabel className={styles.bigblue1}>
                      <Typography variant="h6">
                        <strong>Second Phone Number</strong>
                      </Typography>
                      <InputLabel fullWidth className={styles.bigblue1}>
                        &nbsp;
                      </InputLabel>
                    </InputLabel>
                    <TextField
                      onChange={(e) => {
                        setSecondPhoneNumber(e.target.value);
                      }}
                      value={secondPhoneNumber}
                      required
                      className={styles.bigblue1}
                      fullWidth
                      disabled
                    ></TextField>
                  </Grid>
               <Grid item md={12} xs={12}>
                    <InputLabel className={styles.bigblue1}>
                      <Typography variant="h6">
                        <strong>Active</strong>
                      </Typography>
                    </InputLabel>
                    <TextField
                      onChange={(e) => {
                        setActive(e.target.value);
                      }}
                      value={active}
                      required
                      className={styles.bigblue1}
                      fullWidth
                      disabled
                    ></TextField>
                    <InputLabel fullWidth className={styles.bigblue1}>
                      &nbsp;
                    </InputLabel>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <InputLabel className={styles.bigblue1}>
                      <Typography variant="h6">
                        <strong>Additional Comments</strong>
                      </Typography>
                      <InputLabel fullWidth className={styles.bigblue1}>
                        &nbsp;
                      </InputLabel>
                    </InputLabel>
                    <TextField
                      onChange={(e) => {
                        setAdditionalComments(e.target.value);
                      }}
                      value={additionalComments}
                      required
                      fullWidth
                      multiline
                      rows={4}
                      disabled
                      inputProps={{
                        style: {
                          overflow: "hidden",
                          resize: "none",
                        },
                      }}
                    />
                  </Grid>

                  <Grid>
                    <br></br>
                    <br></br>
                    {CancelButton()}
                    <br></br>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default IndexPage;
export const Head = () => <title>Tedu - Online Learning Platform</title>;
