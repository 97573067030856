import React from 'react';
import Grid from '@mui/material/Grid';
import { Facebook, Twitter, LinkedIn } from '@mui/icons-material';
import logo from '../images/logo_report3.png'; // adjust the path to your actual logo file

import { AiFillPhone } from "react-icons/ai";
import { MdEmail } from "react-icons/md";

import { IoLocationSharp } from "react-icons/io5";

import { Typography, Link, IconButton } from '@mui/material';
const styles = {
  footer: {
    marginTop: '10vh',
    textAlign: 'center'
  },
  address: {
    marginBottom: '24px',
  },
  socialMedia: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '24px',
  },
};
const backgroundImage = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: 2000,
  overflow: 'auto',
  backgroundImage: 'url(/bg1.png)',
  objectPosition: 'center',
  backgroundSize: '100% 100%',
  objectFit: 'cover',
  zIndex: '-1',
}


function Footer() {
  return (
    <footer style={styles.footer}>
      <div style={backgroundImage} />
      <Grid container spacing={1}>
        <Grid item xs={4} >
          <Typography style={{ marginBottom: '24px', fontWeight: 800, fontSize: 24 }} variant="body1" color="textSecondary">
            Solutions
          </Typography>
          <Typography style={styles.address} variant="body1" color="textSecondary">
            Evaluation<br />
            Progress Monitoring<br />
            Powerful Reports<br />
          </Typography>
        </Grid>
        <Grid item xs={4} >
          <Typography style={{ marginBottom: '24px', fontWeight: 800, fontSize: 24 }} variant="body1" color="textSecondary">
            Coming Up
          </Typography>
          <Typography style={styles.address} variant="body1" color="textSecondary">
            General Education Activities<br />
            Special Education Activities<br />
            Psychotherapy Activities<br />
          </Typography>

          {/* <div style={styles.socialMedia}>
            <IconButton>
              <Facebook />
            </IconButton>
            <IconButton>
              <Twitter />
            </IconButton>
            <IconButton>
              <LinkedIn />
            </IconButton>
          </div> */}
        </Grid>


        <Grid item xs={4}>
          <Typography style={{ marginBottom: '24px', fontWeight: 800, fontSize: 24 }} variant="body1" color="textSecondary">
            Company
          </Typography>
          <Typography style={styles.address} variant="body1" color="textSecondary">
            Why Tedu<br />
            About Us<br />
            Contact Us<br />
            Careers<br />
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <img src={logo} alt="Logo" style={{ marginTop: -10, width: 'auto', height: 50 }} />
        </Grid>
        <Grid item xs={3}>
          <Typography style={{   fontSize: 16 }} >
            <AiFillPhone style={{marginRight:3,color:"#f87414", marginBottom:-3, fontSize:20}} /> +357 99099231
          </Typography>
        </Grid><Grid item xs={3}>
          <Typography style={{  fontSize: 16 }} >
            <MdEmail style={{marginRight:3,color:"#f87414", marginBottom:-3, fontSize:20}}/> info@tedu.us
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography style={{  fontSize: 16 }} >
            <IoLocationSharp style={{marginRight:3,color:"#f87414", marginBottom:-3, fontSize:20}}/>  Stylianou Lena 3, Nicosia, Cyprus
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright '}
        <Link color="inherit" href="https://tedu.us/">
          Tedu
        </Link>{' '}
        {'2020'}
        {'.'}
        <br></br>

      </Typography>
      <br></br>

    </footer>
  );
}

export default Footer;