import * as React from "react"
 
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import * as styles from './styles.module.css';
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from '../components/navbar'
import Divider from '@mui/material/Divider'
import LottieView from 'lottie-react';
import { TransitionImage } from '../components/transitionImage'
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import '@fontsource/roboto/700.css';
import Footer from '../components/footer';
import {backgroundImage} from '../components/background';
import {headingStyles} from '../components/others';
import { BACKEND_URL } from "./helper";
import Swal from "sweetalert2";

function isValidEmail(email) {
  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the email matches the regular expression
  return emailRegex.test(email);
}

const IndexPage = () => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  return (
    <>

    <div style={backgroundImage} />
      <Navbar />
      <Container maxWidth="lg">

        <Box
          sx={{
            backgroundColor: '#f8f8f8',
            padding: 4,
            borderRadius: 4,
            justifyContent: 'center',
            flexDirection: 'column',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', marginTop: 6, flexGrow: 1, display: 'flex'
          }}
        >
          <Typography variant="h4" component="h1" style={headingStyles} align="center">
            Contact Us
          </Typography>
          <Box sx={{marginTop:5}}>

            <Typography variant="h5" component="h3" sx={{ mb: 2 }}>
              Please fill out the following form to contact us:
            </Typography>

            <Box component="form" sx={{ mb: 4 }}>
              <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                <TextField
                  label="Name"
                  name="name"
                  required
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  required
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                <TextField
                  label="Message"
                  name="message"
                  multiline
                  rows={4}
                  required
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                />
              </Box>

              {errorMessage !== "" && (
              <p
                id="errorMessage"
                className="text-gray-600 mb-8"
                style={{
                  backgroundColor: "#f8d7da",
                  border: "1px solid #721c24",
                  borderRadius: "4px",
                  color: "#721c24",
                  fontWeight: "bold",
                  fontSize: "18px",
                  marginBottom: "16px",
                  padding: "12px",
                  display: errorMessage !== "" ? "block" : "none",
                }}
                dangerouslySetInnerHTML={{ __html: errorMessage }}
              ></p>
            )}

              <Button
                type="submit"
                variant="contained"
                sx={{ alignSelf: "start" }}
                onClick={() => {
                  event.preventDefault(); // Prevent the default form submission
                  setErrorMessage('')
                  if ((name==null) || (name=='')|| (email==null)|| (email=='')|| (message==null)|| (message=='')){
                    setErrorMessage('All fields are required to send your message')
                    return;
                  }
                  if(!isValidEmail(email)){
                    setErrorMessage('Email address is not valid')
                    return;
                  }
                  
                  fetch(BACKEND_URL + "/api/contact_us", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      name: name,
                      email: email,
                      message: message,
                    }),
                  })
                    .then((response) => {
                      // Handle the response
                      Swal.fire({
                        title: "Message sent successfully",
                        text: "We will get in touch with you shortly",
                        icon: "success",
                        confirmButtonColor: "#155263",
                        confirmButtonText: "OK",
                      }).then((result) => {
                        setName('')
                        setMessage('')
                        setEmail('')
                      });
                    })
                    .catch(error => {
                      // Handle the error
                      Swal.fire({
                        title: "Error",
                        text: "An unexpected error occurred. Please try again.",
                        icon: "error",
                        confirmButtonColor: "#155263",
                        confirmButtonText: "OK",
                      }).then((result) => {});
                    });
                }}
              >
                Submit
              </Button>
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Typography variant="body2">
              We value your privacy and will never share your information with any
              third party.
            </Typography>

          </Box>
          
        </Box>
        <Container sx={{ marginTop: 20, }} maxWidth="md">
        <Footer />
      </Container>
      </Container>

    </>
  )
}

export default IndexPage

export const Head = () => <title>Tedu - Online Learning Platform</title>