import * as React from "react"

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import styles from './styles.module.css';
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from '../components/navbar'
import LottieView from 'lottie-react';
import { TransitionImage } from '../components/transitionImage'
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import { Link as RouterLink } from 'react-router-dom';

import Footer from '../components/footer';
import ParticleEngine from '../components/particles';
import { backgroundImageCity } from '../components/background';
import { headingStyles, headingStyles2, headingStyles3, headingStyles4, headingAccentStyles, headingAccentStyles2 } from '../components/others';
import { Parallax } from 'react-parallax';
import { Button, Typography } from "@mui/material";
import { Link } from 'react-scroll';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useNavigate } from "react-router-dom";

const cardData = [
  { buttontext: "Learn More", link:"/contact", title: 'Support Services', logo: "1.png", front: 'Additional resources and assistance provided to students to help them succeed academically and personally', back: 'Learn More', turn: false },
  { buttontext: "Learn More", link:"/modules",title: 'Progress Monitoring', logo: "2.png", front: 'The process of tracking student performance over time to evaluate progress and make informed decisions about instruction and intervention', back: 'Discover', turn: false },
  { buttontext: "Learn More", link:"/contact",title: 'Account and Billing', logo: "3.png", front: 'The management of financial accounts and transactions related to education services and programs', back: 'Discover', turn: false },
  { buttontext: "Coming Soon", link:"", title: 'General Education', logo: "4.png", front: 'Education that is provided to students without special needs or disabilities, typically in a general education classroom', back: 'Discover', turn: false },
  { buttontext: "Coming Soon", link:"", title: 'Special Education', logo: "6.png", front: 'Education and support services provided to students with disabilities to help them achieve their academic and personal goals', back: 'Discover', turn: false },
  { buttontext: "Coming Soon",link:"",  title: 'Psychotherapy', logo: "5.png", front: 'The treatment of mental health disorders through talk therapy and other forms of psychological intervention', back: 'Discover', turn: false },
];

const IndexPage = () => {

  React.useEffect(() => {
    function handleScroll() {
      console.log('scrolling...');
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  const backgroundImage = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: 2000,
    overflow: 'auto',
    backgroundImage: 'url(/bg2.png)',
    objectPosition: 'center',
    backgroundSize: '100% 100%',
    objectFit: 'cover',
    zIndex: '-1',
  }

  return (
    <>
      <Navbar />

      <Parallax
        renderLayer={percentage => (
          <div
            style={{
              position: 'absolute',
              left: 0,
              height:'auto',
              top: 200,
              width: percentage * 500,
              height: percentage * 500,
            }}
          />
        )}
        bgImage="/bg2.png" blur={{ min: -15, max: 5 }}
        strength={500}
      >

        <div style={{ marginTop: 200, height: 'auto' }}>
          <Container maxWidth="md">
            <Box sx={{ marginTop: 10, flexGrow: 1, display: 'flex' }}>
              <div className={styles.content}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexGrow: 1 }}>
                      <h1 style={headingStyles}>
                        We're Empowering Educators For Seamless Student Progress Management
                        <p style={headingStyles2}><br></br>We are hyper-focused on one thing - Simplifying Education. <br />Our streamlined solution helps educators achieve better outcomes for their students, so they can focus on what they do best - helping students thrive.</p>
                      </h1>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <img style={{ height: 500, marginTop: -80, marginLeft: -100, width: "auto" }} src='/education2.png' />
                  </Grid>
                  <Grid item xs={3}>
                    <Link style={{ width: 'inherit' }} to='video' smooth={true}>
                      <Button id="video" variant='contained' color='primary' endIcon={<PlayCircleOutlineIcon />} sx={{ marginLeft:-3, marginTop: -20, color: 'white' }}>
                        Take a free tour
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <section >
                      <div style={{ marginBottom: 10 }}>  <br />                  </div>
                      <TransitionImage />
                    </section>
                  </Grid>
                </Grid>
              </div>
            </Box>

          </Container>

        </div>
      </Parallax>

      <Parallax bgImage="/bg1.png" strength={400}
        renderLayer={percentage => (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              width: percentage * 500,
              height: percentage * 500,
            }}
          />
        )}
        blur={{ min: -15, max: 5 }}
      >
        <div style={{ height: 'auto' }}>
          <Container maxWidth="lg">
            <Box sx={{ marginTop: 10, flexGrow: 1, display: 'flex' }}>
              <div className={styles.content}>
                <Grid container spacing={10}>
                  <Grid s item xs={6}>
                    <h1 style={headingStyles3}>Evaluation</h1>
                    <Box
                      sx={{
                      }}>
                      <p style={headingStyles4}>Get to know your students better with our powerful evaluation tool! Assess their level and status in any field, and take notes to personalize your teaching approach. Our tool helps you understand their learning style and preferences, allowing you to provide the best possible learning experience. Get started today and unlock your students' full potential!</p>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={styles.imageContainerNoMargin} style={{ backgroundImage: "url('pic3.png')" }}></div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={styles.imageContainerNoMargin} style={{ backgroundImage: "url('pic1.png')" }}></div>
                  </Grid>
                  <Grid item xs={6}>
                    <h1 style={headingStyles3}>Monitor Student Success </h1>
                    <Box
                      sx={{
                        borderRadius: 4,
                      }}>
                      <p style={headingStyles4}>Track your students' progress effortlessly with Tedu's progress monitor! Our tool provides real-time graphing and predicted mastery dates, allowing you to make proactive adjustments and ensure their success. Try it now and take control of your students' goals!</p>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <h1 style={headingStyles3}>Powerful Reports</h1>
                    <Box
                      sx={{
                        borderRadius: 4,
                      }}>
                      <p style={headingStyles4}>Tedu's reports feature, generating the perfect report has never been easier! Choose from our extensive list of pre-built reports or customize your own to meet your unique needs. Whether it's attendance records, test scores, or progress reports, we've got you covered. Try our reports feature today and streamline your reporting process like never before!</p>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={styles.imageContainerNoMargin} style={{ backgroundImage: "url('pic2.png')" }}></div>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Container>

          <Container sx={{ marginTop: 10, marginBottom: 10 }} maxWidth="md">
            <div className={styles.content}>
              <h1 style={{
                marginTop: 0,
                textAlign: 'center',
                color: '#474b4f',
                fontFamily: 'Roboto',
                fontSize: '2rem',
                fontWeight: 800,
              }}>
                Powerful Together And Separately
              </h1>
              <p style={{
                fontFamily: 'Roboto',
                marginTop: -20,
                marginBottom: 50,
                fontSize: '1.2rem',
                fontWeight: 400,
                textAlign: 'center',
                color: '#7a7a7a'
              }}><br></br>Choose from our suite of products to build the perfect special education solution for your district.
              </p>
              <CardList cardData={cardData} />
            </div>
          </Container>
        </div>
      </Parallax>





      <Parallax bgImage="/bg2.png" strength={400}
        renderLayer={percentage => (
          <div
            style={{
              marginTop: 10,
              position: 'absolute',
              left: '50%',
              top: '50%',
              width: percentage * 500,
              height: percentage * 500,
            }}
          />
        )}
        blur={{ min: -15, max: 5 }}
      >
        <div style={{ height: 'auto' }}>

          <Container sx={{ marginTop: 10 }} maxWidth="lg">
            <Box style={{ backgroundColor: "white", boxShadow: "0 0 50px 0 rgba(205.5,105.32189682497196,27.963077445652207,.5)", borderRadius: "50px" }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <img style={{ height: 350, width: "auto", transform: 'scale(1.3)', marginLeft: 100 }} src="/last.png" />
                </Grid>
                <Grid item xs={6}>
                  <h1 style={{
                    marginTop: 100,
                    textAlign: 'left',
                    color: '#474b4f',
                    fontFamily: 'Roboto',
                    fontSize: '2rem',
                    fontWeight: 800,
                  }}>Ready To Simplify Your Education?</h1>
                  <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "left", marginTop: 0 }}>
                    <Button sx={{ color: "white" }} onClick={()=> navigate("/contact")} variant="contained">Request a Demo</Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
          <Container sx={{ justifyContent: "center", alignItems: 'center', marginTop: 10 }} style={backgroundImageCity} maxWidth="false">
            <h1 style={{
              marginTop: 200,
              textShadow: `0 0 10px rgba(0,0,0,.3)`,
              textAlign: 'center',
              color: 'white',
              fontFamily: 'Roboto',
              fontSize: '3rem',
              fontWeight: 800,
            }}>
              Empowering Your Educatinal Journey, One step At a Time!
            </h1>
            <RouterLink style={{textDecoration:'none'}}to='https://tedu.us/contact'>

            <Button
              style={{
                display: 'block',
                margin: '0 auto',
                textAlign: 'center',
                alignSelf: 'center',
                padding: '25px 50px',
                fontSize: '17px',
                borderRadius: '6px',
                fontWeight: 700,
                backgroundColor: '#f4821e',
                boxShadow: '0 0 10px 0 rgba(0,0,0,.5)',
                color: 'white',
                transition: 'background-color 0.3s, box-shadow 0.3s, color 0.3s',
              }}
            >
              Learn how we can help!
            </Button>
            </RouterLink>
          </Container>
        </div>
      </Parallax >

      <Container sx={{ marginTop: 10 }} maxWidth="md">
        <Footer />
      </Container>
    </>
  )
}
export default IndexPage
export const Head = () => <title>Tedu - Online Learning Platform</title>