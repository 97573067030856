import styles from './components.module.css'; // Import CSS file for styling
import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useDetectScroll } from "@smakss/react-scroll-direction";
import { Link } from "react-router-dom";
import "@fontsource/roboto/700.css";
import { useNavigate } from "react-router-dom";
import { TbReport } from "react-icons/tb";
import { AiOutlineHome } from "react-icons/ai"
import { IoLibraryOutline } from "react-icons/io5";
import { HiOutlineUserGroup } from "react-icons/hi";
import {SiBookstack} from "react-icons/si";
import { headingStyles, headingStyles2, headingStyles3, headingStyles4, headingAccentStyles, headingAccentStyles2 } from '../components/others';
import { BACKEND_URL } from "../pages/helper";
import logo from '../images/logo.png'; // adjust the path to your actual logo file

const drawerWidth = 240;
const navLinks = {
  Dashboard: "/homeadmin",
  Students: "/students",
  About: "/about-us",
  Courses: "/courses",
  "Progress Monitoring": "/progress-monitoring",
  Groups:'/groups'
};

const navItems = [
  { title: "Home", icon: <AiOutlineHome />, link: navLinks.Dashboard },
  { title: "Students", icon: <TbReport />, link: navLinks.Students },
  { title: "Courses", icon: <SiBookstack />, link: navLinks.Courses },
  { title: "Groups", icon: <HiOutlineUserGroup />, link: navLinks.Groups },

];

function Navbar(props) {
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [students, setStudents] = React.useState("");
  const [courses, setCourses] = React.useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    fetch(BACKEND_URL + "/api/get_teacher/", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setName(json[0]["first_name"]);
        setSurname(json[0]["last_name"]);
        setEmail(json[0]["email"]);
        setStudents(json[0]["students"]);
        setCourses(json[0]["courses"]);
      });
  }, []);
  
  const { window } = props;
  const [hasScrolled, setHasScrolled] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [scrollDir] = useDetectScroll({});

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
      <img src={logo} alt="Logo" style={{ marginTop: '10px',marginRight: '10px', width:'auto', height:40 }} /> 
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{ zIndex: 999 }}
        className={styles.navbarAdmin}
      >
        <Toolbar className={styles.navbarAdmin}
          sx={{ justifyContent: "space-between" }}>
          <Button
            onClick={() => {
              navigate("/dashboard");
            }}
            sx={{ color: "white", display: "flex", textAlign: "left" }}
          >
            <Typography variant="h6" component="div" sx={{ textAlign: "right", marginRight:10 }}>
            <img src={logo} alt="Logo" style={{ marginTop: '10px',marginRight: '10px', width:'auto', height:40 }} /> 
            </Typography>
          </Button>
          <Box style={{ display: "flex", flex: 1 }}>

            <div style={{ display: "flex", flex: 1, justifyContent: "left" }}>
              {navItems.map((item, index) => (
                <Button
                  key={index}
                  sx={{ color: "white", fontSize: 16, textTransform: 'none', fontStyle: "bold" }}
                  onClick={() => {
                    navigate(item.link);
                  }}
                >
                  <div style={{ fontSize: "16px" }}>{item.icon}</div>
                  &nbsp;{item.title}
                </Button>
              ))}
            </div>
            <div style={{ display: "flex", flex: 1, justifyContent: "right" }}>
              <p>
                {`${name} ${surname}`}
                <br></br>
                <strong>{email} </strong>
                {/* <strong>Courses: </strong>
            {courses}
            <strong>Students: </strong>
            {students} */}
              </p>

            </div>

            <Button
              variant="contained"
              color="primary"

              onClick={() => {
                localStorage.clear();
                navigate("/login");
              }}
              sx={{ height:40, marginLeft: 5, marginTop:2.5, color: "white" }}
            >
              Logout
            </Button>

            
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}

export default Navbar;
