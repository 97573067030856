import * as React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styles from "../pages/styles.module.css";
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from "../components/navbar_logged_in";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Background from "../components/background";
import LottieView from "lottie-react";
import { TransitionImage } from "../components/transitionImage";
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import "@fontsource/roboto/700.css";
import Footer from "../components/footer";
import { backgroundImage } from "../components/background";
import { BACKEND_URL } from "../pages/helper";
import jwt_decode from "jwt-decode";
import { headingStyles } from "../components/others";
import { FaPenSquare, FaTrash, FaPlus } from "react-icons/fa"; // Example icons from Font Awesome
import { useNavigate } from 'react-router-dom'; import Swal from "sweetalert2";
import { MdEdit } from 'react-icons/md';
import { BsTrash } from 'react-icons/bs';
import { IoAddCircleOutline } from 'react-icons/io5'
import { useParams } from "react-router-dom";
import {HiOutlineDocumentReport} from 'react-icons/hi'

function StudentBox({ student, setStudents }) {

    const navigate = useNavigate();


    return (
        <Grid item md={3} sm={6} xs={12} key={student.student_id}>
                    <div
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        padding: "10px",
                        borderTopRightRadius: '33px',
                        marginBottom: "3px",
                        zIndex: -1,
                      }}
                      onClick={() => {
                        navigate(
                          "/studentsprofile/sid/" + student.student_id
                        );
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                          <div style={{ display: 'flex' }}>
                            <h2 style={{ marginRight: '10px', marginTop: -5 }}>
                              {student.first_name}
                            </h2>
                            <div style={{ marginLeft: 'auto', marginTop: -12 }}>
                              <p>
                                {student.active === null //Remove o
                                  ? "⚫"
                                  : student.active
                                    ? <div className={styles.greencircle}></div>
                                    : <div className={styles.redcircle}></div>}
                              </p>

                            </div>
                            <button
                              style={{
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                fontSize: "24px",
                                zIndex: 2,
                                marginTop: -15
                              }}
                              title="Edit"
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                  "/editstudents/sid/" + student.student_id
                                );
                              }}
                            >
                              <MdEdit style={{ color: 'grey' }} />
                            </button>


                            <button
                              style={{
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                fontSize: "24px",
                                zIndex: 2,
                                marginTop: -15,
                                marginLeft:-10
                              }}
                              title="Report"
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                  "/progressreport/sid/" + student.student_id
                                );
                              }}
                            >
                            <HiOutlineDocumentReport style={{ color: 'grey' }} />
                            </button>

                            <button
                              style={{
                                background: "none",
                                border: "none",
                                padding: "0",
                                marginRight: "5px",
                                cursor: "pointer",
                                fontSize: "24px",
                                marginTop: -15,
                                zIndex: 2,
                              }}
                              title="Delete"
                              onClick={async (e) => {
                                let do_the_request = true;
                                e.stopPropagation();

                                Swal.fire({
                                  title: "Are you sure?",
                                  text: `Please verify that you want to delete the specific student.`,
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#d33",
                                  cancelButtonColor: "#3085d6",
                                  confirmButtonText: "Delete",
                                  cancelButtonText: "Cancel",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    do_the_request = true;
                                  } else {
                                    do_the_request = false;
                                  }
                                  if (do_the_request) {
                                    fetch(
                                      BACKEND_URL +
                                      "/api/delete_student/" +
                                      student.student_id,
                                      {
                                        method: "DELETE",
                                        headers: {
                                          Accept: "application/json",
                                          "Content-Type": "application/json",
                                          Authorization: `Bearer ${localStorage.getItem(
                                            "accessToken"
                                          )}`,
                                        },
                                      }
                                    )
                                      .then((response) => {
                                        if (response.status === 403) {
                                          localStorage.clear();
                                          navigate("/login", { replace: true });
                                          return Promise.reject();
                                        } else {
                                          return response;
                                        }
                                      })
                                      .then((response) => response.json())
                                      .then((json) => {
                                        // Handle the response here
                                        if (
                                          json.message ==
                                          "Deletion was successful"
                                        ) {
                                          // Success
                                          Swal.fire({
                                            title: "Student Deleted",
                                            text: "The student was deleted successfully",
                                            icon: "success",
                                            confirmButtonColor: "#155263",
                                            confirmButtonText: "OK",
                                          }).then((result) => {
                                            fetch(
                                              BACKEND_URL +
                                              "/api/get_students/",
                                              {
                                                headers: {
                                                  Authorization: `Bearer ${localStorage.getItem(
                                                    "accessToken"
                                                  )}`,
                                                },
                                              }
                                            )
                                              .then((response) => {
                                                if (response.status === 403) {
                                                  localStorage.clear();
                                                  navigate("/login", { replace: true });
                                                  return Promise.reject();
                                                } else {
                                                  return response;
                                                }
                                              })
                                              .then((response) =>
                                                response.json()
                                              )
                                              .then((json) => {
                                                setStudents(json);
                                              });
                                          }, []);
                                        } else {
                                          // Success
                                          Swal.fire({
                                            title: "Error",
                                            text: "An unexpected error occurred. Please try again.",
                                            icon: "error",
                                            confirmButtonColor: "#155263",
                                            confirmButtonText: "OK",
                                          }).then((result) => { });
                                        }
                                      });
                                  }
                                });
                              }}
                            >
                              <BsTrash style={{ color: 'grey' }} />
                            </button>

                          </div>

                          <h4 style={{ marginTop: -25 }}>
                            {student.middle_name}{" "}
                            {student.last_name}
                          </h4>

                          <p style={{ marginTop: -20, fontSize: "12px" }}>
                            
                         
                          <Divider sx={{marginTop:2}} />
                          </p>
                          <Grid container spacing={1} >
                            <Grid  item  xs={6}>
                          <Button fullWidth  
                               onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                  "/progressmonitoring/sid/" +
                                  student.student_id
                                );
                              }} style={{borderRadius:10,  color:"white",fontSize:11, backgroundColor:"#f87414", textTransform:'none' }}>+ New Lesson</Button>
                          </Grid>
                          <Grid  item  xs={6}>
                          <Button fullWidth style={{borderRadius:10,     cursor: 'default', color:"white", fontSize:11, backgroundColor:"darkgrey", textTransform:'none'}}>{student.lessons} Lessons</Button> 
                          </Grid>

                          </Grid>
                        </div>

                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                        </div>
                      </div>
                    </div>
                  </Grid>
    )
}
export default StudentBox;
