import * as React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styles from "./styles.module.css";
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from "../components/navbar";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Background from "../components/background";
import LottieView from "lottie-react";
import { TransitionImage } from "../components/transitionImage";
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import "@fontsource/roboto/700.css";
import Footer from "../components/footer";
import { backgroundImage } from "../components/background";
import IconButton from '@mui/material/IconButton';
import InputAdornment from  '@mui/material/InputAdornment';
import Visibility from  '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  InputLabel,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Alert,
  FormControl,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { errorAlert } from "./helper";
import { BACKEND_URL } from "./helper";
import Swal from "sweetalert2";

const handleSubmit = (event) => {
  event.preventDefault();
  // Add your form submission logic here
};

const IndexPage = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordVer, setPasswordVer] = React.useState("");
  const [errorCode, setErrorCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const CancelButton = () => {
    return (
      <>
        <Button
          style={{
            color: "white",
            backgroundColor: "primary",
            fontSize: 16,
            padding: "12px 20px",
            borderRadius: 4,
            width: "200px",
          }}
          variant="contained"
          onClick={() => navigate("/login")}
        >
          Cancel
        </Button>
      </>
    );
  };

  const SubmitButton = (
    name,
    surname,
    email,
    password,
    passwordVer,
    checked,
    errorCode,
    setErrorCode
  ) => {
    return (
      <>
        <Button
          style={{
            color: "white",
            backgroundColor: "primary",
            fontSize: 16,
            padding: "12px 20px",
            borderRadius: 4,
            width: "200px",
            marginLeft: "50px",
          }}
          variant="contained"
          onClick={async () => {
            let do_the_request = true;
            if (
              name == "" ||
              surname == "" ||
              email == "" ||
              password == "" ||
              passwordVer == ""
            ) {
              setErrorCode(
                "Please complete all fields to submit your registration to the system."
              );
              do_the_request = false;
            }
            if (do_the_request && !checked) {
              setErrorCode(
                "Acceptance of the privacy policy is mandatory to complete your registration in the system."
              );
              do_the_request = false;
            }
            if (
              do_the_request &&
              !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
            ) {
              setErrorCode("Please enter a valid email address.");
              do_the_request = false;
            }
            if (
              do_the_request &&
              (password.length <= 9 ||
                !/^(?=.*[a-zA-Z])(?=.*[0-9])/.test(password))
            ) {
              setErrorCode(
                "The password you entered does not meet all the required attributes. Please try again."
              );
              do_the_request = false;
            }
            if (do_the_request && password != passwordVer) {
              setErrorCode(
                "Your password confirmation is invalid. Please try again."
              );
              do_the_request = false;
            }
            if (do_the_request) {
              await fetch(BACKEND_URL + "/api/sign_up/", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  first_name: name,
                  last_name: surname,
                  email: email,
                  password: password,
                }),
              })
                .then((response) => {
                  if (response.status === 403) {
                    localStorage.clear();
                    navigate("/login", { replace: true });
                    return Promise.reject();
                  } else {
                    return response;
                  }
                })
                .then((response) => response.json())
                .then((json) => {
                  // Handle the response here
                  if (json.message == "Insertion was successful") {
                    // Success
                    setErrorCode("");
                    Swal.fire({
                      title: "Account Created",
                      text: "Your account was successfully created. Please login to proceed with the verification of your email address.",
                      icon: "success",
                      confirmButtonColor: "#155263",
                      confirmButtonText: "OK",
                    }).then((result) => {
                      navigate("/login");
                    });
                    setOpenDialog(true);
                  } else if (json.message == "User already exists") {
                    // There was an error
                    setErrorCode(
                      "This email address is already registered in the system. Please enter a different email address."
                    );
                  } else {
                    setErrorCode(
                      "An unexpected error occurred while creating your account. Please try again."
                    );
                  }
                });
            }
          }}
        >
          Submit
        </Button>
      </>
    );
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
};
  const emailRef = React.useRef();
  React.useEffect(() => {
    function handleScroll() {
      // handle scroll event here
      console.log("scrolling...");
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div style={backgroundImage} />
      <Navbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              sx={{
                paddingBottom: 5,
                paddingTop: 5,
                paddingLeft: 5,
                paddingRight: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <React.Fragment>
                <h1>Create a new account</h1>
              </React.Fragment>
              <Box sx={{ flexGrow: 1, backgroundColor: "White" }}>
                <FormLabel>
                  Please submit the information below to create your account.
                  All fields are mandatory
                </FormLabel>
                <br></br>
                <br></br>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item md={6} xs={6}>
                    <InputLabel className={styles.bigblue1}>Name</InputLabel>
                    <TextField
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                      required
                      className={styles.bigblue1}
                      fullWidth
                    ></TextField>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <InputLabel fullWidth className={styles.bigblue1}>
                      Surname
                    </InputLabel>
                    <TextField
                      onChange={(e) => {
                        setSurname(e.target.value);
                      }}
                      value={surname}
                      required
                      className={styles.bigblue1}
                      fullWidth
                    ></TextField>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <InputLabel fullWidth className={styles.bigblue1}>
                      Email Address
                    </InputLabel>
                    <TextField
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                      required
                      className={styles.bigblue1}
                      fullWidth
                      style={{
                        backgroundColor:
                          email === ""
                            ? "white"
                            : /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                              email
                            )
                              ? "white"
                              : "pink",
                      }}
                    ></TextField>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <InputLabel fullWidth className={styles.bigblue1}>
                      Password
                    </InputLabel>
                    <TextField
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                      required
                      className={styles.bigblue1}
                      fullWidth
                      type={showPassword ? 'text' : 'password'}
                      style={{
                        backgroundColor:
                          password === ''
                            ? 'white'
                            : password.length <= 9 || !/^(?=.*[a-zA-Z])(?=.*[0-9])/.test(password)
                              ? 'pink'
                              : 'white',
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClickShowPassword}>
                              {showPassword ? <Visibility /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    ></TextField>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <InputLabel fullWidth className={styles.bigblue1}>
                      Password Verification
                    </InputLabel>
                    <TextField
                      onChange={(e) => {
                        setPasswordVer(e.target.value);
                      }}
                      value={passwordVer}
                      required
                      className={styles.bigblue1}
                      fullWidth
                      type="password"
                      style={{
                        backgroundColor:
                          passwordVer.length === 0
                            ? "white"
                            : passwordVer === password
                              ? "white"
                              : "pink",
                      }}
                    ></TextField>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <p style={{ fontStyle: "italic", fontSize: "11pt" }}>
                      Password must be at least 10 characters long and contain
                      at least one letter, number and special character.
                    </p>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                          }}
                          checked={checked}
                          onChange={handleChange}
                        />
                      }
                      label={
                        <>
                          <span>I accept the </span>
                          <Link href="privacypolicyout" target="_blank">
                            <span>privacy policy</span>
                          </Link>
                        </>
                      }
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    {errorCode !== "" ? <>{errorAlert(errorCode)}</> : null}
                    <br></br>
                    <br></br>
                  </Grid>

                  <Grid>
                    {CancelButton()}
                    {SubmitButton(
                      name,
                      surname,
                      email,
                      password,
                      passwordVer,
                      checked,
                      errorCode,
                      setErrorCode
                    )}
                    <br></br>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default IndexPage;
export const Head = () => <title>Tedu - Online Learning Platform</title>;
