import * as React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import CircularProgress from '@mui/material/CircularProgress';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TableStyles from './TableStyles.module.css';

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styles from "./styles.module.css";
import { mdiConsoleLine, mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from "../components/navbar_logged_in";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Background from "../components/background";
import LottieView from "lottie-react";
import { TransitionImage } from "../components/transitionImage";
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import "@fontsource/roboto/700.css";
import Footer from "../components/footer";
import ParticleEngine from "../components/particles";
import { backgroundImage } from "../components/background";
import { useNavigate, useHistory } from 'react-router-dom';
import { BACKEND_URL, THRESHOLD_LESSONS, CLIENT_URL } from "./helper";
import jwt_decode from "jwt-decode";
import { errorAlert } from "./helper";
import { headingStyles } from "../components/others";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa"; // Example icons from Font Awesome
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import queryString from "query-string";
import { IconButton } from "@mui/material";
import { BsTrash, BsDownload, BsZoomIn } from 'react-icons/bs';
import AddIcon from "@mui/icons-material/Add";
import { Line } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { IoSync } from "react-icons/io5";

import { isAfter, isToday, setDate } from 'date-fns';
import dayjs from 'dayjs';
import {
  InputLabel,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Alert,
  FormControl,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

import { format } from "date-fns";
import { AiOutlineConsoleSql } from "react-icons/ai";

const IndexPage = () => {
  Chart.register(CategoryScale);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [studentCourses, setStudentCourses] = React.useState([{}]);
  const [student, setStudent] = React.useState([{}]);
  const [course, setCourse] = React.useState({});
  const [courseID, setCourseID] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [overallScore, setOverallScore] = React.useState(0);
  const [additionalComments, setAdditionalComments] = React.useState("");
  const [progressMonitoring, setProgressMonitoring] = React.useState([]);
  const [updateChart, setUpdateChart] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [target, setTarget] = React.useState(20);
  const [baseline, setBaseline] = React.useState(80);
  const [loadReport, setLoadReport] = React.useState(false)
  const currentDate = new Date();
  const lastYearDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate()).toISOString().slice(0, 10);
  const [dateFromReport, setDateFromReport] = React.useState(lastYearDate);
  const [dateToReport, setDateToReport] = React.useState(currentDate.toISOString().slice(0, 10));
  const [dateFromChart, setDateFromChart] = React.useState(dayjs(null));
  const [dateToChart, setDateToChart] = React.useState(dayjs());
  const [chartData, setChartData] = React.useState({});
  const [gradeID, setGradeID] = React.useState('');
  const { sid } = useParams();
  const [openReportModal, setOpenReportModal] = React.useState(false);
  const [filteredDates, setfilteredDates] = React.useState({});
  const isInitialRender = React.useRef(true);
  const chartLabel = React.useRef(null);
  const [dateGrade, setDateGrade] = React.useState(dayjs());
  const [messageChart, setMessageChart] = React.useState('');
  const [validChart, setValidChart] = React.useState(false);
  const textFieldRef = React.useRef(null);
  const [target_table, setTargetTable] = React.useState([]);
  const [force, setForce] = React.useState(0);

  const [gradeContents, setGradeContents] = React.useState([]);
  function handleZoom() {
    const dateFromFormatted = dateFromChart.format('DD-MM-YYYY');
    const dateToFormatted = dateToChart.format('DD-MM-YYYY');
    const url = `/progressmonitoringzoom/sid/${sid}/courseID/${courseID}/dateFrom/${dateFromFormatted}/dateTo/${dateToFormatted}`
    window.open(CLIENT_URL + url, '_blank');
  }




  function handleGenerationReport() {
    setLoadReport(true);
    setOpenReportModal(false);
    const url = '/api/create_report/';
    const requestBody = {
      student_id: sid,
      course_id: courseID,
      date_from: dateFromChart,
      date_to: dateToChart
    };
    fetch(BACKEND_URL + url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => {
        if (response.status === 200) {
          // Success case: Status 200
          Swal.fire('Report Generated', '', 'success');
          setLoadReport(false);
          return response.blob(); // convert to blob only if status is 200
        } else {
          throw new Error('Failed to generate report'); // throw an error if status is not 200
        }
      })
      .then(blob => {
        // create a new object URL from the blob
        const url = window.URL.createObjectURL(new Blob([blob]));
        // create a link element
        const link = document.createElement('a');
        link.href = url;
        // set the download attribute (optional)
        link.setAttribute('download', 'Progress Report for '+ student[0].first_name+' '+student[0].last_name+'.pdf');
        // append the link to the body
        document.body.appendChild(link);
        // click the link
        link.click();
        // remove the link after download
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        Swal.fire('Encounter an Error', '', 'error');
        console.error(error);
      });
  }


  function calculateUpperTarget(points, target) {
    const n = points.length;
    const out_points = [];
    let x = 0
    for (let i = 0; i < n; i++) {
      let y = parseInt(points[i]);
      if (y > target) {
        x = i
        out_points.push({ x, y });
      }
      else {
        x = 0;
        y = 0;
        out_points.push({});
      }
    }
    return out_points;
  }
  function calculateLinearRegression(points) {
    let sumX = 0;
    let sumY = 0;
    let sumXY = 0;
    let sumXX = 0;

    const n = points.length;

    for (let i = 0; i < n; i++) {
      sumX = i + sumX;
      sumY = parseInt(points[i]) + sumY;
      sumXY = (i * points[i]) + sumXY;
      sumXX = (i * i) + sumXX;
    }

    const m = ((n * sumXY) - (sumX * sumY)) / ((n * sumXX) - (sumX * sumX));
    const b = (sumY - (m * sumX)) / n;
    const startX = 0;
    const endX = points.length;
    const step = 1;
    const out_points = [];
    for (let x = startX; x < endX; x += step) {
      const y = m * x + b;
      out_points.push({ x, y });
    }

    return out_points;
  }

  function linearRegression(X, y) {
    const nSamples = X.length;
    const nFeatures = X[0].length;

    // Compute the mean values of X and y
    const meanX = X.reduce((acc, curr) => acc.map((x, i) => x + curr[i]), Array(nFeatures).fill(0)).map((sum) => sum / nSamples);
    const meanY = y.reduce((acc, curr) => acc + curr, 0) / nSamples;

    // Compute the covariance and variance of X
    let cov = 0;
    let varX = 0;
    for (let i = 0; i < nSamples; i++) {
      cov += (X[i].reduce((acc, curr, j) => acc + (curr - meanX[j]) * (y[i] - meanY), 0)) / nSamples;
      varX += (X[i].reduce((acc, curr, j) => acc + (curr - meanX[j]) ** 2, 0)) / nSamples;
    }

    // Compute the slope (weights) and intercept (bias) of the linear regression line
    const slope = cov / varX;
    const intercept = meanY - slope * meanX.reduce((acc, curr) => acc + curr, 0) / nFeatures;

    return { slope, intercept };
  }

  function forecastNextGrades(filteredGrades, filteredDates) {

    if (Array.isArray(filteredGrades) && Array.isArray(filteredDates) && filteredGrades.length > 0 && filteredDates.length > 0) {
      const parsedGrades = filteredGrades.map((grade) => parseInt(grade));
      const X = parsedGrades.map((_, index) => [index]);
      const y = parsedGrades;
      const model = linearRegression(X, y);
      const numZeros = filteredDates.length;
      const forecastedGrades = Array(numZeros).fill(-10);
      const nextGradeIndex = parsedGrades.length;


      const nextGrade = model.slope * nextGradeIndex + model.intercept;
      forecastedGrades.push(nextGrade);
      forecastedGrades.push(-10);



      return forecastedGrades;
    }
    else {
      return null;
    }
  }

  React.useEffect(() => {
    fetch(BACKEND_URL + "/api/get_student/" + sid, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        setStudent(json);
      });

  }, []);

  React.useEffect(() => {
    filterGraph();

  }, [updateChart])

  React.useEffect(() => {
    fetch(BACKEND_URL + "/api/get_student_courses/" + sid, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        console.log("🚀 ~ file: progressmonitoring.js:280 ~ .then ~ studentCourses:", json)
        setStudentCourses(json);
      });

  }, []);

  React.useEffect(() => {
    fetch(`${BACKEND_URL}/api/get_target_baseline/?student_id=${sid}&course_id=${courseID}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        if (json.length > 0) {
          setTarget(json[0]['target']);
          setBaseline(json[0]['baseline']);
          setUpdateChart(!updateChart)
        }
      });
  }, [courseID]);

  React.useEffect(() => {

    fetch(`${BACKEND_URL}/api/get_progress_monitoring/?student_id=${sid}&course_id=${courseID}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        setProgressMonitoring(json);

      }).catch((error) => {
        console.error("Error fetching progress monitoring:", error);
      });
  }, [courseID]);

  const DeleteButton = () => {
    return (
      <>
        <Button
          style={{
            marginTop: 20,
            color: "white",
            backgroundColor: "primary",
            fontSize: 16,
            marginRight:20, 
            padding: "12px 20px",
            borderRadius: 4,
            width: "200px",
          }}
          variant="contained"
          onClick={async (e) => {
            e.stopPropagation(); // Stop event propagation

            let do_the_request = true;
            Swal.fire({
              title: "Are you sure?",
              text: `Please verify that you want to delete the lesson.`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#d33",
              cancelButtonColor: "#3085d6",
              confirmButtonText: "Delete",
              cancelButtonText: "Cancel",
            }).then((result) => {
              if (result.isConfirmed) {
                do_the_request = true;
              } else {
                do_the_request = false;
              }
              if (do_the_request) {
                fetch(
                  BACKEND_URL +
                  "/api/delete_lesson/" +
                  gradeID,
                  {
                    method: "DELETE",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                      )}`,
                    },
                  }
                )
                  .then((response) => {
                    if (response.status === 403) {
                      localStorage.clear();
                      navigate("/login", { replace: true });
                      return Promise.reject();
                    } else {
                      return response;
                    }
                  })
                  .then((response) => response.json())
                  .then((json) => {
                    // Handle the response here
                    if (
                      json.message ==
                      "Deletion was successful"
                    ) {
                      // Success
                      Swal.fire({
                        title: " Deleted",
                        text: "The lesson was deleted successfully",
                        icon: "success",
                        confirmButtonColor: "#155263",
                        confirmButtonText: "OK",
                      }).then((result) => {
                        fetch(
                          BACKEND_URL + "/api/delete_lesson/",
                          {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem(
                                "accessToken"
                              )}`,
                            },
                          }
                        )
                          .then((response) => {
                            if (response.status === 403) {
                              localStorage.clear();
                              navigate("/login", { replace: true });
                              return Promise.reject();
                            } else {
                              return response;
                            }
                          })

                          .then((result) => {
                            setUpdateChart(false)
                            setForce(force => force + 1);
                            setUpdate(false)
                            //setDateGrade('')
                            setRows([])
                            setAdditionalComments('')
                            setOverallScore('')
                          });
      
                          setTimeout(() => {
                            fetch(`${BACKEND_URL}/api/get_progress_monitoring/?student_id=${sid}&course_id=${courseID}`, {
                              headers: {
                                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                              },
                            })
                              .then((response) => {
                                if (response.status === 403) {
                                  localStorage.clear();
                                  navigate("/login", { replace: true });
                                  return Promise.reject();
                                } else {
                                  return response;
                                }
                              })
                              .then((response) => response.json())
                              .then((json) => {
                                console.log(json)
                                setProgressMonitoring(json);
                              })
                              .catch((error) => {
                                console.error("Error fetching progress monitoring:", error);
                              });
                          }, 3000);




                          
                          
                      });
                    } else {
                      // Success
                      Swal.fire({
                        title: "Error",
                        text: "An unexpected error occurred. Please try again.",
                        icon: "error",
                        confirmButtonColor: "#155263",
                        confirmButtonText: "OK",
                      }).then((result) => { });
                    }
                  });
              }
            });
          }}
        >
          Delete
        </Button>
      </>
    );
  };

  const CancelButton = () => {
    return (
      <>
        <Button
          style={{
            marginTop: 20,
            color: "white",
            backgroundColor: "primary",
            fontSize: 16,
            padding: "12px 20px",
            borderRadius: 4,
            width: "200px",
          }}
          variant="contained"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </>
    );
  };


  const addRow = () => {
    const currentSum = rows.reduce(
      (sum, row) => sum + parseInt(row.percentage),
      0
    );
    const defaultPercentage = 100 - currentSum;

    if (rows.length === 0) {
      setRows([
        ...rows,
        { percentage: 100, field: "", score: 0, comment: "" },
      ]);
    }
    else {
      setRows([
        ...rows,
        { percentage: 0, field: "", score: 0, comment: "" },
      ]);
    }
    // updateScore(rows);

  };

  const deleteRow = (index) => {
    let column = "percentage";
    const updatedRows = [...rows];
    let numberofrows = updatedRows.length;
    let removedPercentage = updatedRows[index][column];
    updatedRows.splice(index, 1);

    if (numberofrows > 1) {
      for (let i = 0; i < updatedRows.length; i++) {
        updatedRows[i][column] = Math.round(
          (updatedRows[i][column] * 100) / (100 - removedPercentage)
        );
      }

      let verify_total = 0;
      if (updatedRows.length == 1) {
        updatedRows[0][column] = 100
      } else {
        for (let i = 0; i < updatedRows.length; i++) {
          if (isNaN(updatedRows[i][column])) {
            updatedRows[i][column] = 0
          } else {
            verify_total = verify_total + updatedRows[i][column];
          }
        }

        let ipoloipo = 100 - verify_total;
        while (ipoloipo != 0 && !isNaN(ipoloipo)) {
          let ran = Math.floor(Math.random() * updatedRows.length);
          if (ipoloipo > 0) {
            if (ran !== index) {
              updatedRows[ran][column] = updatedRows[ran][column] + 1;
              ipoloipo = ipoloipo - 1;
            }
          } else {
            if (ran !== index && updatedRows[ran][column] > 0) {
              updatedRows[ran][column] = updatedRows[ran][column] - 1;
              ipoloipo = ipoloipo + 1;
            }
          }
        }
      }


    }
    setRows(updatedRows);
    if (updatedRows.length > 0) {
      updateScore(updatedRows);
    } else {
      setOverallScore(0);
    }
  };

  const updateScore = (updatedRows) => {
    const percentage = "percentage";
    const score = "score";
    let overall_score = 0;
    for (let i = 0; i < updatedRows.length; i++) {
      overall_score =
        overall_score +
        (updatedRows[i][percentage] * updatedRows[i][score]) / 100;
    }
    let overall_score_formatted = parseFloat(overall_score.toFixed(2));
    setOverallScore(overall_score_formatted);
  };

  const handleInputChange = (index, column, value) => {
    const updatedRows = [...rows];
    updatedRows[index][column] = value;
    setRows(updatedRows);
    updateScore(updatedRows);
  };

  const parseGradeContents = (gradeContents) => {
    const rows = gradeContents.split('₺').filter(Boolean);
    return rows.map(row => {
      const [percentage, field, score, comment] = row.split('$');
      return {
        percentage: parseInt(percentage),
        field: field,
        score: parseInt(score),
        comment: comment
      };
    });
  };



  const getGradeContents = () => {
    const updatedRows = [...rows];
    const percentage = "percentage";
    const field = "field";
    const score = "score";
    const comment = "comment";
    let gradeContents = '';
    for (let i = 0; i < updatedRows.length; i++) {
      gradeContents =
        gradeContents + removeCurrencySymbols(updatedRows[i][percentage]) + '$' + removeCurrencySymbols(updatedRows[i][field]) + '$' + removeCurrencySymbols(updatedRows[i][score]) + '$' + removeCurrencySymbols(updatedRows[i][comment]) + '₺';
    }
    return gradeContents
  };

  function removeCurrencySymbols(inputString) {
    if (inputString === null) {
      return '';
    }

    if (typeof inputString !== 'string') {
      console.error('Input must be a string');
      return inputString;
    }

    return inputString.replace(/[$₺]/g, '');
  }


  const checkScore = () => {
    let totalPercentage = 0;
    for (let i = 0; i < rows.length; i++) {
      let p = parseInt(rows[i]["score"])
      if (p > 100 || p < 0)
        return false
    }
    return true;
  }
  const calculateTotalPercentage = () => {
    let totalPercentage = 0;
    for (let i = 0; i < rows.length; i++) {
      totalPercentage += parseInt(rows[i]["percentage"]);
    }
    return totalPercentage;
  }

  const validatePercentage = (index, column, value) => {

    const newPercentage = parseInt(value);
    if (value == '')
      return 0;
    if ((value != '') && (isNaN(newPercentage) || newPercentage < 0 || newPercentage > 100)) {
      return; // Invalid input, do not update the state
    }

    const updatedRows = [...rows];
    updatedRows[index][column] = newPercentage;

    const remainingSum = 100 - newPercentage;
    const numRemainingRows = rows.length - 1;
    const remainingPercentage = Math.floor(remainingSum / numRemainingRows);


    let old_index_percentage = 0;
    for (let i = 0; i < rows.length; i++) {
      if (i !== index) {
        old_index_percentage = old_index_percentage + updatedRows[i][column];
      }
    }
    old_index_percentage = 100 - old_index_percentage;


    for (let i = 0; i < rows.length; i++) {
      if (i !== index && updatedRows[i][column] > 0) {
        updatedRows[i][column] = Math.round(
          (updatedRows[i][column] * remainingSum) / (100 - old_index_percentage)
        );
      }
    }

    let verify_total = 0;
    for (let i = 0; i < rows.length; i++) {
      verify_total = verify_total + updatedRows[i][column];
    }

    let ipoloipo = 100 - verify_total;

    while (ipoloipo != 0) {
      let ran = Math.floor(Math.random() * rows.length);
      if (ipoloipo > 0) {
        if (ran !== index) {
          updatedRows[ran][column] = updatedRows[ran][column] + 1;
          ipoloipo = ipoloipo - 1;
        }
      } else {
        if (ran !== index && updatedRows[ran][column] > 0) {
          updatedRows[ran][column] = updatedRows[ran][column] - 1;
          ipoloipo = ipoloipo + 1;
        }
      }
    }

    setRows(updatedRows);
    updateScore(updatedRows);
  };
  React.useEffect(() => {
    filterGraph();
  }, [dateFromChart, dateToChart])
  const validateScore = (index, column, value) => {
    const newScore = parseInt(value);

    if (isNaN(newScore) || newScore < 0 || newScore > 100) {
      return; // Invalid input, do not update the state
    }

    const updatedRows = [...rows];
    updatedRows[index][column] = newScore;
    setRows(updatedRows);
    updateScore(updatedRows);
  };
  const SubmitButton = () => {
    return (
      <>
        <Button
          style={{
            color: "white",
            backgroundColor: "primary",
            fontSize: 16,
            padding: "12px 20px",
            borderRadius: 4,
            width: "200px",
            marginTop: 20,
            marginLeft: 20,
          }}
          variant="contained"
          onClick={async () => {
            let do_the_request = true;
            if (do_the_request) {
              await fetch(BACKEND_URL + "/api/add_grade/", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                },
                body: JSON.stringify({
                  student_id: sid,
                  course_id: courseID,

                  grade: overallScore,
                  dateGrade: dateGrade,
                  grade_contents: getGradeContents(),
                  comment: additionalComments,
                }),
              })
                .then((response) => {
                  if (response.status === 403) {
                    localStorage.clear();
                    navigate("/login", { replace: true });
                    return Promise.reject();
                  } else {
                    return response;
                  }
                })
                .then((response) => response.json())
                .then((json) => {
                  // Handle the response here
                  if (json.message == "Insertion was successful") {
                    // Success
                    Swal.fire({
                      title: "Grade Submitted",
                      text: "The grade was submitted successfully",
                      icon: "success",
                      confirmButtonColor: "#155263",
                      confirmButtonText: "OK",
                    }).then((result) => {
                      setUpdateChart(false)
                      setForce(force => force + 1);
                      setUpdate(true)
                    });

                    setTimeout(() => {
                      fetch(`${BACKEND_URL}/api/get_progress_monitoring/?student_id=${sid}&course_id=${courseID}`, {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                      })
                        .then((response) => {
                          if (response.status === 403) {
                            localStorage.clear();
                            navigate("/login", { replace: true });
                            return Promise.reject();
                          } else {
                            return response;
                          }
                        })
                        .then((response) => response.json())
                        .then((json) => {
                          console.log(json)
                          setProgressMonitoring(json);
                        })
                        .catch((error) => {
                          console.error("Error fetching progress monitoring:", error);
                        });
                    }, 3000);
                  } else {
                    // Success
                    Swal.fire({
                      title: "Error",
                      text: "An unexpected error occurred. Please try again.",
                      icon: "error",
                      confirmButtonColor: "#155263",
                      confirmButtonText: "OK",
                    }).then((result) => { });
                  }
                });
            }
          }}

        >
          Submit
        </Button>
      </>
    );
  };

  const UpdateButton = () => {
    return (
      <>
        <Button
          style={{
            color: "white",
            backgroundColor: "primary",
            fontSize: 16,
            padding: "12px 20px",
            borderRadius: 4,
            width: "200px",
            marginTop: 20,
            marginLeft: 20,
          }}
          variant="contained"
          onClick={async () => {
            let do_the_request = true;
            if (do_the_request) {
              console.log(JSON.stringify({
                student_id: sid,
                course_id: courseID,
                grade: overallScore,
                grade_id: gradeID,
                dateGrade: dateGrade,
                grade_contents: getGradeContents(),
                comment: additionalComments,
              }),)
              await fetch(BACKEND_URL + "/api/update_grade/", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                },
                body: JSON.stringify({
                  student_id: sid,
                  course_id: courseID,
                  grade: overallScore,
                  grade_id: gradeID,
                  dateGrade: dateGrade,
                  grade_contents: getGradeContents(),
                  comment: additionalComments,
                }),
              })
                .then((response) => {
                  if (response.status === 403) {
                    localStorage.clear();
                    navigate("/login", { replace: true });
                    return Promise.reject();
                  } else {
                    return response;
                  }
                })
                .then((response) => response.json())
                .then((json) => {
                  // Handle the response here
                  if (json.message == "Update was successful") {
                    // Success
                    Swal.fire({
                      title: "Grade Submitted",
                      text: "The grade was update successfully",
                      icon: "success",
                      confirmButtonColor: "#155263",
                      confirmButtonText: "OK",
                    }).then((result) => {
                      // setRows([]);
                      // setOverallScore(0);
                      // setAdditionalComments('')
                      // setUpdateChart(!updateChart)
                    });

                    setTimeout(() => {
                      fetch(`${BACKEND_URL}/api/get_progress_monitoring/?student_id=${sid}&course_id=${courseID}`, {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                      })
                        .then((response) => {
                          if (response.status === 403) {
                            localStorage.clear();
                            navigate("/login", { replace: true });
                            return Promise.reject();
                          } else {
                            return response;
                          }
                        })
                        .then((response) => response.json())
                        .then((json) => {
                          console.log(json)
                          setProgressMonitoring(json);


                        })
                        .catch((error) => {
                          console.error("Error fetching progress monitoring:", error);
                        });
                    }, 3000);
                  } else {
                    // Success
                    Swal.fire({
                      title: "Error",
                      text: "An unexpected error occurred. Please try again.",
                      icon: "error",
                      confirmButtonColor: "#155263",
                      confirmButtonText: "OK",
                    }).then((result) => { });
                  }
                });
            }
          }}

        >
          Update
        </Button>
      </>
    );
  };

  const setTargetBaseline = (type, value) => {
    let target_local = target
    let baseline_local = baseline
    if (type == 'target') {
      target_local = value
    } else {
      baseline_local = value
    }
    fetch(`${BACKEND_URL}/api/set_target_baseline/?student_id=${sid}&course_id=${courseID}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "accessToken"
        )}`,
      },
      body: JSON.stringify({
        target: target_local,
        baseline: baseline_local,
      }),
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        // Handle the response here
        if (json.message == "Update was successful") {
          // Success
        } else {
          // Failed
        }
      });
  };

  const printFunctions = (obj) => {
    for (let key in obj) {
      if (typeof obj[key] === 'function') {
        console.log(key, obj[key]);
      }
    }
  }
  function filterGraph() {
    const dates = progressMonitoring.map(entry => entry.date);

    let firstDate = null;
    let lastDate = null;
    if (dateFromChart == '' && dateToChart == '' && Array.isArray(dates) && dates.length > 0) {
      let startIndex = 0;
      let endIndex = Math.max(dates.length - 1, 9);
      if (dates.length <= 10) {
        startIndex = 0;
      } else {
        startIndex = endIndex - 9;
      }
      firstDate = dates[startIndex]
      lastDate = dates[endIndex]
      firstDate = dayjs(firstDate);
      lastDate = dayjs(lastDate);
    }
    else {
      firstDate = dateFromChart;
      lastDate = dateToChart
    }

    const grades = progressMonitoring.map(entry => entry.grade);
    const gradesIndexes = [];


    const filteredDates = dates.filter((date, index) => {
      const currentDate = dayjs(date);
      if (currentDate.isBetween(firstDate, lastDate) || currentDate.isSame(firstDate, 'day') || currentDate.isSame(lastDate, 'day')) {
        gradesIndexes.push(index);
        return true;
      }

      return false;
    })

    const correctFilteredDates = filteredDates.map(date => dayjs(date).format("DD/MM/YYYY"))
    if (correctFilteredDates.length === 0) {
      setMessageChart("No lessons were found during these dates.");
      setValidChart(false);
      return;
    }
    else if (correctFilteredDates.length >= THRESHOLD_LESSONS) {
      setMessageChart(`Lesson dates exceed the threshold (${THRESHOLD_LESSONS}). Please check the lessons scheduled between the given dates.`);
      setValidChart(false);
      return;
    }
    else {
      setMessageChart('');
      setValidChart(true);
    }
    const filteredGrades = gradesIndexes.map((index) => grades[index]);
    const forecastedGrades = forecastNextGrades(filteredGrades, correctFilteredDates);
    const comments = progressMonitoring.map(entry => entry.comment);
    const baseline_table = new Array(grades.length).fill(baseline);
    const target_table = new Array(grades.length).fill(target);
    setTargetTable(target_table)
    const star = new Image(100, 100); const forecast = new Image(15, 15);
    forecast.src = '/circle2.png'; star.src = '/star.png';
    const out_points = calculateLinearRegression(filteredGrades);
    const upper_target_points = calculateUpperTarget(filteredGrades, target);
    correctFilteredDates.push('Next Lesson')
    setChartData({
      labels: correctFilteredDates,
      datasets: [
        {
          label: 'Forecast',
          data: forecastedGrades,
          backgroundColor: 'skyblue',
          borderColor: 'skyblue',
          pointStyle: forecast,
          fill: false,
          showLine: false,
        },
        {
          label: 'Upper Target Points',
          data: upper_target_points,
          backgroundColor: 'orange',
          borderColor: 'orange',
          pointStyle: star,
          borderWidth: 1.5,
          fill: false,
          showLine: false,
        },
        {
          label: 'Score',
          data: filteredGrades,
          borderColor: 'grey',
          backgroundColor: 'grey',
          pointStyle: 'circle',
          pointRadius: 6,
          borderWidth: 1.5,
          showLine: true,
        },
        {
          label: 'Baseline',
          data: baseline_table,
          borderColor: 'rgb(252, 3, 3)',
          backgroundColor: 'rgb(252, 3, 3)',
          pointRadius: 0,
          borderWidth: 1.5,
          showLine: true,
        },
        {
          label: 'Target',
          borderWidth: 1.5,

          data: target_table,
          backgroundColor: 'rgb(0, 176, 9)',
          borderColor: 'rgb(0, 176, 9)',
          fill: false,
          pointRadius: 0,

          showLine: true,
        },

        {
          label: 'Best Straight Line',
          data: out_points,
          borderColor: '#F27216',
          backgroundColor: '#F27216',
          pointRadius: 0,
          borderDash: [5, 5],
          showLine: true,
        },
      ],
      options: {
        layout: {
        },
        scales: {
          y: {
            min: 0,
            max: 100,
            ticks: {
              padding: 0,  // Add padding
            },
            beginAtZero: true,

          },
          x: {
            ticks: {
              padding: 1,  // Add padding
              font: {
                size: 8
              }
            }
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                var datasetLabel = context.dataset.label || '';
                var dataLabel = context.parsed.y || '';

                if (datasetLabel) {
                  datasetLabel += ': ';
                }

                var index = context.dataIndex;
                var comment = comments[index] || '';

                return datasetLabel + dataLabel + ' - ' + comment;
              },
            },
          },
          onClick: function (evt, activeElements) {
            // activeElements is an array containing all the active elements (datapoints)
            if (activeElements.length > 0) {
              var chartElement = activeElements[0];  // get first active element

              var datasetIndex = chartElement._datasetIndex;
              var dataIndex = chartElement._index;

              var label = this.data.labels[dataIndex];
              var value = this.data.datasets[datasetIndex].data[dataIndex];

              console.log(`Label: ${label}, Value: ${value}`);
            }
          },
        },
      },
    });
  }



  React.useEffect(() => {
    filterGraph();
    const lastTenValues = progressMonitoring.map(entry => entry.date).slice(-10);

    if (lastTenValues.length > 0) {
      setDateFromChart(dayjs(lastTenValues[0]));
    } else {
      setDateFromChart(dayjs(null));
    }

  }, [progressMonitoring])





  React.useEffect(() => {
    async function fetchData() {
      const url = '/api/get_grade/';
      const requestBody = {
        gradeDate: dateGrade,
        course_id: parseInt(courseID),
        student_id: parseInt(sid)
      };
      try {
        let response = await fetch(BACKEND_URL + url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          },
          body: JSON.stringify(requestBody)
        });

        if (response.status === 404) {
          response = await fetch(BACKEND_URL + '/api/get_latest_grade/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: JSON.stringify(requestBody)
          });

          if (response.status === 404) {
            setRows([]);
            setUpdate(false);
            setOverallScore(0);
            setAdditionalComments('');
            setUpdateChart(false);
            throw new Error("Not found");
          }
          else {
            let json = await response.json();
            console.log(json);
            const gradeContents = json[0].grade_contents;
            setRows(parseGradeContents(gradeContents));
            setGradeID(json[0].grade_id);
            updateScore(parseGradeContents(gradeContents));
            setAdditionalComments(json[0].comment || '');
            setUpdate(false)
          }
        }
        else {
          let json = await response.json();
          console.log(json);
          const gradeContents = json[0].grade_contents;
          setRows(parseGradeContents(gradeContents));
          setGradeID(json[0].grade_id);
          updateScore(parseGradeContents(gradeContents));
          setAdditionalComments(json[0].comment || '');
          setUpdate(true)
        }

      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
    console.log("fetch data")
  }, [dateGrade, courseID, force]);

  React.useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [textFieldRef.current]);

  return (
    <>
      <div style={backgroundImage} />
      <Navbar />
      <Container style={{ margin: 10 }} maxWidth={false}>
        <Paper
          sx={{
            paddingBottom: 5,
            paddingTop: 5,
            paddingLeft: 5,
            paddingRight: 5,
            display: "flex",
            flexDirection: "column",
          }}
        >

          <Grid
            container
            spacing={2}
            style={{ marginTop: -10, display: 'flex', flexDirection: "row", }}
          >
            <Grid item md={8} xs={8}>
              <h2>Progress Monitoring</h2>
            </Grid>
            <Grid item md={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end', alignContent: 'flex-end' }} xs={4}>
              {validChart && <Button variant="contained" style={{ alignSelf: "flex-end", marginTop: 30, marginLeft: 10 }} onClick={() => handleGenerationReport()}>Report  &nbsp; <BsDownload style={{ fontSize: 20 }} /> </Button>}
              {loadReport && <div style={{ display: 'flex', alignItems: 'center' }}><h4  >Generating report </h4> <CircularProgress style={{ marginLeft: 10 }} /></div>}

            </Grid>

          </Grid>
          <h3 style={{ marginTop: -10 }}>{student[0].first_name} {student[0].middle_name} {student[0].last_name}</h3>
          <Grid
            container
            spacing={2}
            style={{ marginTop: -10, flexDirection: "row", display: 'flex' }}
          >
            {Array.isArray(studentCourses) &&
              studentCourses.length == 0 ?


              <Grid item md={3} xs={3}>
                <InputLabel className={styles.bigblue1}>
                  <Typography variant="h6">
                    <strong>
                      There are no courses enrolled to this student.
                    </strong>
                  </Typography>
                </InputLabel>
              </Grid>

              :
              <>
                <Grid item xs={2} sm={2} md={2} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: "flex-start", justifyContent: 'flex-start' }}>
                  <Typography>Choose Course:</Typography>
                  <Select
                    onChange={(e) => {
                      const foundCourse = studentCourses.find((course) => {
                        return course.course_name === e.target.value;
                      });
                      setCourse(e.target.value);
                      setCourseID(
                        foundCourse.course_id
                      );
                    }}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    value={course.course_name}
                    required
                    fullWidth
                    id="course-select"

                  >
                    {Array.isArray(studentCourses) &&
                      studentCourses.map((course) => {
                        return (
                          <MenuItem key={course.course_name} value={course.course_name}>
                            {course.course_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
                <Grid item xs={2} sm={2} md={2} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: "flex-start", justifyContent: 'flex-start' }}>
                  <Typography>Choose Date:</Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>

                    <DatePicker
                      format="DD/MM/YYYY"
                      disableFuture
                      value={dayjs(dateGrade)}
                      renderInput={(props) => <TextField  {...props} size='small' />}
                      onChange={(newValue) => {
                        setDateGrade(newValue);
                      }}
                    />
                  </LocalizationProvider>

                </Grid>
                {courseID &&
                  <Grid
                    container
                    spacing={2}
                    style={{ marginTop: 10, marginLeft: 1, display: 'flex', flexDirection: "row", }}
                  >
                    <br></br>
                    <Grid item xs={1.5} sm={1.5} md={1.5} style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignContent: "flex-start", justifyContent: 'flex-start' }}>
                      <Box>

                        <Typography component="h6"> Date From: </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            disableFuture
                            value={dayjs(dateFromChart)}
                            renderInput={(props) => <TextField  {...props} size='small' />}
                            onChange={(newValue) => {
                              setDateFromChart(newValue);
                            }}
                            fullWidth
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid item xs={1.5} sm={1.5} md={1.5} style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignContent: "flex-start", justifyContent: 'flex-start' }}>
                      <Box>
                        <Typography component="h6"> Date To: </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            disableFuture
                            value={dayjs(dateToChart)}
                            renderInput={(props) => <TextField  {...props} size='small' />}
                            onChange={(newValue) => {
                              setDateToChart(newValue);
                            }}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid item xs={1.5} sm={1.5} md={1.5} >

                      {validChart && <Button variant="contained" style={{ fontSize: 15, marginTop: 33 }} onClick={() => handleZoom(true)}>Zoom  &nbsp; <BsZoomIn /> </Button>}
                    </Grid>


                  </Grid>}
              </>
            }
          </Grid>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >

            {courseID != '' ? <>

              {chartData !== {} ? (
                <Grid item md={6} xs={6} >
                  <div>
                    {
                      (messageChart === '' && chartData.labels && chartData.datasets

                        ? <Box style={{ display: 'flex' }}><Line id="progress-chart" options={chartData.options} style={{ marginTop: 30, height: 'auto' }} data={chartData} /></Box>
                        : null)
                    }
                    {
                      (!(dateFromChart.isValid() && dateToChart.isValid())
                        ? <h3 style={{ color: 'red' }}>Please enter valid dates to show the graph...</h3>
                        : <h3 ref={chartLabel}>{messageChart}</h3>
                      )
                    }
                  </div>

                </Grid>
              ) : null}



              <Grid flexDirection='column' item md={6} xs={6}>

                <InputLabel style={{textAlign: 'center'}}>
                  <Typography variant="h6">
                    <strong style={{color:"black"}}>Score Table</strong>
                  </Typography>
                </InputLabel>

                <div>
                  <table
                  className={styles.tableProgress}
                    style={{
                      borderCollapse: "collapse",
                      width: "100%",
                      flexDirection: 'column',
                      justifyItems: 'flex-start',
                      justifyContent: 'flex-start',
                      boxShadow: '5px 10px 15px rgba(0, 0, 0, 0.1)',
                      alignItems: 'flex-start',
                    }}
                  >
                    <thead>
                      <tr style={{backgroundColor:"#38344c", color:"white"}}>
                        <th
                          style={{
                            height:"50px",
                            width: "100px",
                          }}
                        >
                          Weight (%)
                        </th>
                        <th
                          style={{
                            fontSize:'16px',
                            height:"50px",

                            width: "100px",
                          }}
                        >
                          Score (%)
                        </th>
                        <th
                          style={{
                            fontSize:'16px',
                            height:"50px",

                            width: "250px",
                          }}
                        >
                          Field
                        </th>

                        <th
                          style={{
                            fontSize:'16px',
                            height:"50px",

                            width: "250px",
                          }}
                        >
                          Comment
                        </th>
                        <th
                          style={{
                            width: "100px",
                            fontSize:'16px',
                            height:"50px",

                          }}
                        >
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      {rows.map((row, index) => {

                        let backgroundColors = index % 2 === 0 ? "white" : "#f5f5f5";
                        return(
                          <>
                            <tr
                              key={index} 
                              style={{
                                backgroundColor: index % 2 === 0 ? "white" : "#f5f5f5",  // "#f5f5f5" is a light gray color
                              }}
                            >
                              <td>
                                <input
                                  type="text"
                                  value={row.percentage}
                                  onChange={(e) => {
                                    if (e.target.value === '' || isNaN(e.target.value)) {
                                      const updatedRows = [...rows];
                                      updatedRows[index] = { percentage: 0, score: updatedRows[index].score, comment: updatedRows[index].comment, field: updatedRows[index].field };
                                      setRows(updatedRows);
                                      updateScore(updatedRows);

                                      return;
                                    }

                                    if (!isNaN(parseInt(e.target.value))) {
                                      const updatedRows = [...rows];
                                      updatedRows[index] = { percentage: parseInt(e.target.value), score: updatedRows[index].score, comment: updatedRows[index].comment, field: updatedRows[index].field };
                                      setRows(updatedRows);
                                      updateScore(updatedRows);

                                    }
                                  }}
                                  style={{
                                    width: "100%",
                                    resize: "vertical",
                                    textAlign: "center",
                                    display: "inline-block",
                                    fontSize:"14px",
                                    height:40,
                                    backgroundColor: "transparent",
                                    border: 'none',
                                    backgroundColor: calculateTotalPercentage() === 100 ? backgroundColors : '#F08080',
                                  }}
                                />
                              </td>
                              <td style={{}}>
                                <input
                                  value={row.score}

                                  onChange={(e) => {
                                    if (e.target.value === '' || isNaN(e.target.value)) {
                                      const updatedRows = [...rows];
                                      updatedRows[index] = { score: 0, percentage: updatedRows[index].percentage, comment: updatedRows[index].comment, field: updatedRows[index].field };
                                      setRows(updatedRows);
                                      updateScore(updatedRows);

                                      return;
                                    }
                                    if (!isNaN(parseInt(e.target.value))) {
                                      const updatedRows = [...rows];
                                      updatedRows[index] = { percentage: updatedRows[index].percentage, score: parseInt(e.target.value), comment: updatedRows[index].comment, field: updatedRows[index].field };
                                      setRows(updatedRows);
                                      updateScore(updatedRows);

                                    }
                                  }}
                                  style={{
                                    width: "100%",
                                    resize: "vertical",
                                    textAlign: "center",
                                    fontSize:"14px",
                                    height:40,

                                    display: "inline-block",
                                    backgroundColor: "transparent",
                                    border: 'none',
                                    backgroundColor: checkScore() ? backgroundColors : '#F08080',
                                  }}



                                />
                              </td>
                              <td style={{}}>
                                <input
                                  type="text"
                                  value={row.field}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "field",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    width: "100%",
                                    resize: "vertical",
                                    textAlign: "left",
                                    fontSize:"14px",
                                    height:40,

                                    display: "inline-block",
                                    backgroundColor: "transparent",
                                    border: 'none'
                                  }}
                                />
                              </td>

                              <td style={{
                                border: "",
                                backgroundColor: "transparent",

                                textAlign: "center",
                              }}>
                                <input
                                  value={row.comment}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "comment",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    width: "100%",
                                    resize: "vertical",
                                    fontSize:"14px",
                                    textAlign: "left",
                                    display: "inline-block",
                                    backgroundColor: "transparent",
                                    height:40,
                                    border: 'none'
                                  }}
                                />
                              </td>
                              <td
                                style={{
                                  border: "",
                                  backgroundColor: "transparent",

                                  textAlign: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "inline-block",
                                    backgroundColor: "transparent",
                                    paddingTop: 5,
                                  }}
                                  onClick={() => deleteRow(index)}
                                >
                                  <BsTrash style={{ color: 'grey' }} />
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                  <br></br>
                  <IconButton
                    style={{
                      backgroundColor: "transparent",
                      padding: 0,
                    }}
                    onClick={addRow}
                  >
                    Add row
                    <AddIcon />
                  </IconButton>
                  <br></br>
                  <br></br>
                  {rows.reduce((sum, row) => sum + (row.percentage || 0), 0) !== 100 && (rows.length !== 0) && (
                    <p style={{ color: "red" }}>The total percentage must be 100.</p>
                  )}
                  {/* {rows.map((row) => row < 0 &&  row < 100) && (rows.length !== 0) && (
                    <p style={{ color: "red" }}>The score must have a value between 0 and 100.</p>
                  )} */}
                  {rows.some((row) => row.score < 0 || row.score > 100) && (rows.length !== 0) && (
                    <p style={{ color: "red" }}>The score must have a value between 0 and 100.</p>
                  )}

                  <table
                    style={{
                      border: 0,
                      borderCollapse: "collapse",
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr >
                        <th></th>
                        <th
                          style={{
                            backgroundColor: " #38344c",
                            width: "185px",
                            boxShadow: '5px 10px 15px rgba(0, 0, 0, 0.1)',
                            color:"white"
                          }}
                        >
                          Overall Score
                        </th>

                        <th
                          style={{
                            width: "110px",
                            boxShadow: '5px 10px 15px rgba(0, 0, 0, 0.1)',
                            marginLeft: 10

                          }}
                        >
                          {overallScore} %
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <Grid container style={{ marginTop: 10 }} spacing={3}>


                  <Grid item xs={6}>
                    <InputLabel className={styles.bigblue1}>
                      <Typography variant="h6">
                        <strong>Baseline</strong>
                      </Typography>
                    </InputLabel>

                    <TextField
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        if (!isNaN(value) && value >= 0 && value <= 100) {
                          setBaseline(value);
                          setUpdateChart(!updateChart);
                          setTargetBaseline('baseline', value);
                        }
                        else {
                          setBaseline(0);
                          setUpdateChart(!updateChart);
                          setTargetBaseline('baseline', value);
                        }
                      }}
                      value={baseline}
                      required
                      className={styles.bigblue1}
                      fullWidth
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel className={styles.bigblue1}>
                      <Typography variant="h6">
                        <strong>Target Line</strong>
                      </Typography>
                    </InputLabel>
                    <TextField
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        if (!isNaN(value) && value >= 0 && value <= 100) {
                          setTarget(value);
                          setUpdateChart(!updateChart);
                          setTargetBaseline('target', value);
                        }
                        else {
                          setTarget(0);
                          setUpdateChart(!updateChart);
                          setTargetBaseline('target', 0);
                        }
                      }}
                      value={target}
                      required
                      className={styles.bigblue1}
                      fullWidth
                    ></TextField>

                  </Grid>
                </Grid>

              </Grid>
              <Grid item md={12} xs={12}>
                <InputLabel style={{ marginTop: 30 }} className={styles.bigblue1}>
                  <Typography variant="h6">
                    <strong>Additional Comments</strong>
                  </Typography>

                </InputLabel>

                <TextField
                  onChange={(e) => {
                    setAdditionalComments(e.target.value);
                    e.target.style.height = "auto"; // Reset the height to auto
                    e.target.style.height = `${e.target.scrollHeight}px`; // Set the height to fit the content

                  }}
                  value={additionalComments}
                  required
                  fullWidth
                  ref={textFieldRef}
                  autoFocus={true}
                  multiline={true}
                  rows={3}
                  InputProps={{ sx: { overflow: 'hidden' } }} // Use 'minHeight' instead of 'height'
                />
              </Grid>

            </> : null}

            <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} item md={12} xs={12}>
              {update && gradeID && DeleteButton() }
              {CancelButton()}
              {
                courseID !== ''
                  ? checkScore() && calculateTotalPercentage() === 100
                    ? update
                      ? UpdateButton()
                      : SubmitButton()
                    : null
                  : null
              }
            </Grid>
          </Grid>
        </Paper>
      </Container >
     
    </>
  );
};
export default IndexPage;
export const Head = () => <title>Tedu - Online Learning Platform</title>;
