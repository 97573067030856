import * as React from "react"

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import styles from './styles.module.css';
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from '../components/navbar'
import LottieView from 'lottie-react';
import { TransitionImage } from '../components/transitionImage'
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import '@fontsource/roboto/700.css';
import Footer from '../components/footer';
import ParticleEngine from '../components/particles';
import { backgroundImage } from '../components/background';
import { headingStyles, headingStyles2, headingStyles3, headingStyles4, headingAccentStyles, headingAccentStyles2 } from '../components/others';
import { Link } from 'react-scroll';
import Button from '@mui/material/Button';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const cardData = [
  { buttontext: "Coming Soon", title: 'General Education', logo: "4.png", front: 'Education that is provided to students without special needs or disabilities, typically in a general education classroom', back: 'Discover', turn: false },
  { buttontext: "Coming Soon", title: 'Special Education', logo: "6.png", front: 'Education and support services provided to students with disabilities to help them achieve their academic and personal goals', back: 'Discover', turn: false },
  { buttontext: "Coming Soon", title: 'Psychotherapy', logo: "5.png", front: 'The treatment of mental health disorders through talk therapy and other forms of psychological intervention', back: 'Discover', turn: false },
];

const IndexPage = () => {

  React.useEffect(() => {
    function handleScroll() {
      // handle scroll event here
      console.log('scrolling...');
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div style={backgroundImage} />
      <Navbar />

      <Container maxWidth="md">
        <Box sx={{ marginTop: 10, marginBottom: 10, flexGrow: 1, display: 'flex' }}>
          <div className={styles.content}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: 'center', flexGrow: 1 }}>
                  <h1 style={{ ...headingStyles, marginTop: -10, textAlign: "center" }}>Tedu Team is Building Something Outstanding For You!<br></br>The New Applications Will be Published Soon!
                  </h1>
                </Box>
              </Grid>


            </Grid>

          </div>
        </Box>

      </Container>
      <Container sx={{ marginTop: 10, marginBottom: 10 }} maxWidth="md">
        <div className={styles.content}>
          <CardList cardData={cardData} />
        </div>
      </Container>
      <Container sx={{ marginTop: 10 }} maxWidth="md">
        <Footer />
      </Container>
    </>
  )
}
export default IndexPage
export const Head = () => <title>Tedu - Online Learning Platform</title>