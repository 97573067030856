import * as React from "react";
 
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styles from './styles.module.css';
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from "../components/navbar_logged_in";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Background from "../components/background";
import LottieView from "lottie-react";
import { TransitionImage } from "../components/transitionImage";
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import "@fontsource/roboto/700.css";
import Footer from "../components/footer";
import ParticleEngine from "../components/particles";
import {backgroundImage} from "../components/background";
 import { useNavigate } from "react-router-dom";
import axios from "./axios";
import { BACKEND_URL } from "./helper";
import jwt_decode from "jwt-decode";
import { errorAlert } from "./helper";
import { headingStyles } from "../components/others";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa"; // Example icons from Font Awesome
import {
  InputLabel,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Alert,
  FormControl,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";

const IndexPage = () => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(true);
  const [groupName, setGroupName] = React.useState("");
  const [active, setActive] = React.useState("");
  const [sumbitOK, setSumbitOK] = React.useState(false);
  const activeOptions = ["Yes", "No"];

  // Function to decode a boolean value
  function active_decode(parameter) {
    if (parameter) {
      return "Yes";
    } else {
      return "No";
    }
  }

  // Function to encode 'Yes' or 'No' as boolean values
  function active_encode(value) {
    if (value === "Yes") {
      return true;
    } else if (value === "No") {
      return false;
    } else {
      // Handle invalid input
      return null;
    }
  }

  const CancelButton = () => {
    return (
      <>
        <Button
          style={{
            color: "white",
            backgroundColor: "primary",
            fontSize: 16,
            padding: "12px 20px",
            borderRadius: 4,
            width: "200px",
          }}
          variant="contained"
          onClick={() => navigate("/groups")}
        >
          Cancel
        </Button>
      </>
    );
  };

  const SubmitButton = () => {
    return (
      <>
        <Button
          style={{
            color: "white",
            backgroundColor: "primary",
            fontSize: 16,
            padding: "12px 20px",
            borderRadius: 4,
            width: "200px",
            marginLeft: "50px",
          }}
          disabled={sumbitOK}
          variant="contained"
          onClick={async () => {
            let do_the_request = true;
            if (do_the_request) {
              await fetch(BACKEND_URL + "/api/add_group/", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                },
                body: JSON.stringify({
                  group_name: groupName,
                  group_active: active_encode(active),
                }),
              })
                .then((response) => {
                  if (response.status === 403) {
                    localStorage.clear();
                    navigate("/login", { replace: true });
                    return Promise.reject();
                  } else {
                    return response;
                  }
                })
                .then((response) => response.json())
                .then((json) => {
                  // Handle the response here
                  if (json.message == "Insertion was successful") {
                    // Success
                    Swal.fire({
                      title: "Group Added",
                      text: "The group was created successfully",
                      icon: "success",
                      confirmButtonColor: "#155263",
                      confirmButtonText: "OK",
                    }).then((result) => {
                      navigate("/groups");
                    });
                  } else {
                    // Success
                    Swal.fire({
                      title: "Error",
                      text: "An unexpected error occurred. Please try again.",
                      icon: "error",
                      confirmButtonColor: "#155263",
                      confirmButtonText: "OK",
                    }).then((result) => {});
                  }
                });
            }
          }}
        >
          Create
        </Button>
      </>
    );
  };

  React.useEffect(() => {
    function handleScroll() {
      // handle scroll event here
      console.log("scrolling...");
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  React.useEffect(()=>{
    if (groupName!="")
    setSumbitOK(false);
    else
    setSumbitOK(true);

  },[groupName])

  return (
    <>
      <div style={backgroundImage} />
      <Navbar />
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              sx={{
                paddingBottom: 5,
                paddingTop: 5,
                paddingLeft: 5,
                paddingRight: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <React.Fragment>
                <h1>Create a New Group</h1>
              </React.Fragment>
              <Box sx={{ flexGrow: 1, backgroundColor: "White" }}>
                <br></br>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item md={12} xs={12}>
                    <InputLabel className={styles.bigblue1}>
                      <Typography variant="h6">
                        <strong>Group Name</strong>
                      </Typography>
                    </InputLabel>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      onChange={(e) => {
                        
                        setGroupName(e.target.value);
                      }}
                      value={groupName}
                      required
                      className={styles.bigblue1}
                      fullWidth
                    ></TextField>
                    <InputLabel fullWidth className={styles.bigblue1}>
                      &nbsp;
                    </InputLabel>
                  </Grid>

                 

                  <Grid>
                    <br></br>
                    <br></br>
                    {CancelButton()}
                    {SubmitButton()}
                    <br></br>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default IndexPage;
export const Head = () => <title>Tedu - Online Learning Platform</title>;
