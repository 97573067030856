import {
  InputLabel,
  TextField,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Alert,
} from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

//export const BACKEND_URL = "http://localhost:3002";
//export const CLIENT_URL = "http://localhost:3000";
export const BACKEND_URL = "https://tedu.us:8910";
export const CLIENT_URL = "https://tedu.us:443";

export const THRESHOLD_LESSONS = 100
export function printObj(obj) {
  for (let prop in obj) {
    console.log(prop + ": " + obj[prop].toString());
    if (typeof obj[prop] === "object") {
      printObj(obj[prop]);
    }
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function AlertDialogSlide({
  open,
  setOpen,
  dialogTitle,
  dialogText,
  acceptText,
  rejectText,
  funContinue,
}) {
  const handleClosePositive = () => {
    setOpen(false);
    funContinue();
  };

  const handleCloseNegative = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseNegative}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseNegative()}>{rejectText}</Button>
          <Button onClick={() => handleClosePositive()}>{acceptText}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function errorAlert(text) {
  return (
    <>
      <br></br>
      <Alert variant="outlined" severity="error">
        {text}
      </Alert>
    </>
  );
}

export function successAlert(text) {
  return (
    <>
      <br></br>
      <Alert variant="outlined" severity="success">
        {text}
      </Alert>
    </>
  );
}

export function warningAlert(text) {
  return (
    <Alert variant="outlined" severity="warning">
      {text.split("<br>").map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </Alert>
  );
}
