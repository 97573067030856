import * as React from "react"

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import styles from './styles.module.css';
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from '../components/navbar'
import LottieView from 'lottie-react';
import { TransitionImage } from '../components/transitionImage'
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import '@fontsource/roboto/700.css';
import Footer from '../components/footer';
import ParticleEngine from '../components/particles';
import { backgroundImage } from '../components/background';
import { headingStyles, headingStyles2, headingStyles3, headingStyles4, headingAccentStyles, headingAccentStyles2 } from '../components/others';
import { Link } from 'react-scroll';
import Button from '@mui/material/Button';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Icon from '@mdi/react';
import { mdiCheckCircleOutline } from '@mdi/js';
const IndexPage = () => {

  React.useEffect(() => {
    function handleScroll() {
      // handle scroll event here
      console.log('scrolling...');
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div style={backgroundImage} />
      <Navbar />
      <Container maxWidth="lg">
        <div className={styles.content}>
          <Grid container spacing={1}>

            <Grid item xs={12}>

              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: 'center', flexGrow: 1 }}>
                <h1 style={{ ...headingStyles2, fontSize: '1.5rem', marginTop: 200 }}>Simplifying Education
                </h1>
                <h1 style={{ ...headingStyles, marginTop: 1, textAlign: "center", fontSize: '3rem', }}>Empowering Education Teams: Streamline Compliance and Maximize Student Time!
                </h1>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <img style={{ height: 600 }} src='/whytedu.png' />
            </Grid>
            <Grid item xs={12}>
                    <section >
                      <div style={{ marginBottom: 10 ,marginTop:-70 }}>  <br />                  </div>
                      <TransitionImage />
                    </section>
                  </Grid>
            <Grid item xs={6} sx={{ display: 'flex', marginTop: 10, flexDirection: 'column', alignItems: "flex-start", justifyContent: 'flex-start', flexGrow: 1 }}>
             <img style={{ height: 350, width: "auto", transform: 'scale(1.7)', marginLeft: 100 }} src='/whytedu2.png' />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', marginTop: 10, flexDirection: 'column', alignItems: "flex-start", justifyContent: 'flex-start', flexGrow: 1 }}>
              <h1 style={headingStyles}>
                Powerful Education Tool
              </h1>
              <h1 style={{ ...headingStyles2, marginTop: 10 }}>Track Education, a revolutionary platform designed to help teachers create and manage student profiles with ease. TEDU provides a comprehensive solution for educators to monitor and track student progress, empowering them to make data-driven decisions in their teaching methodologies.
              </h1>
              <p style={{ ...headingStylesWhyTedu, marginTop: 10 }}><Icon style={{ marginBottom: -7, color: "#1565c0" }} path={mdiCheckCircleOutline} size={1} />  Easy to Use &nbsp;&nbsp;&nbsp;&nbsp;
                <Icon style={{ marginBottom: -7, color: "#1565c0" }} path={mdiCheckCircleOutline} size={1} />  Stress – Free Implementation &nbsp;&nbsp;&nbsp;&nbsp;
                <Icon style={{ marginBottom: -7, color: "#1565c0" }} path={mdiCheckCircleOutline} size={1} />{' '} Helpful Support </p>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Container sx={{ marginTop: 20, }} maxWidth="md">
        <Footer />
      </Container>


    </>
  )
}

export const headingStylesWhyTedu = {
  fontFamily: 'Roboto',
  fontSize: '1rem',
  fontWeight: 400,

  textAlign: 'center',
  color: '#7a7a7a',
}
export default IndexPage
export const Head = () => <title>Tedu - Online Learning Platform</title>