import styles from './components.module.css';
import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useDetectScroll } from "@smakss/react-scroll-direction";
import { Link } from 'react-router-dom';
import '@fontsource/roboto/700.css';
import logo from '../images/logo.png'; // adjust the path to your actual logo file
import { HiOutlineHome, HiOutlineCalendar, HiOutlineInformationCircle, HiOutlineStar, HiOutlineMail } from "react-icons/hi";


import { useNavigate } from "react-router-dom";

const drawerWidth = 240;
const navItems = ['Modules', 'Coming Up', 'Why Tedu', 'Contact Us'];
const navLinks = {
  'Modules': '/modules',
  'Coming Up': '/coming-up',
  'Why Tedu': '/whytedu',
  'Contact Us': '/contact'
};


const navIconMapping = {
  'Modules': <HiOutlineHome style={{ fontSize: '20px', marginRight:5 }} />,
  'Coming Up': <HiOutlineCalendar style={{ fontSize: '20px', marginRight:5 }}/>,
  'About Us': <HiOutlineInformationCircle style={{ fontSize: '20px', marginRight:5 }}/>,
  'Why Tedu': <HiOutlineStar style={{ fontSize: '20px', marginRight:5 }}/>,
  'Contact Us': <HiOutlineMail style={{ fontSize: '20px', marginRight:5 }}/>
};


function Navbar(props) {
  const { window } = props;
  const [hasScrolled, setHasScrolled] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [scrollDir] = useDetectScroll({});
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Typography variant="h6" component="div" sx={{ textAlign: "right", marginRight: 10 }}>
          <img src={logo} alt="Logo" style={{ marginTop: '10px', marginRight: '10px', width: 'auto', height: 40 }} />
        </Typography>      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>

              <ListItemText primary={item} />

            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      console.log('hello')
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <>
      <CssBaseline />
      <AppBar component="nav" sx={{ zIndex: 999, position: "sticky", backgroundColor: 'transparent' }} className={styles.navbar}>
        <Toolbar>
          <Button
            onClick={() => {
              navigate("/");
            }}
            sx={{
              color: "white",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
              <Typography variant="h6" component="div" sx={{ textAlign: "right", marginRight: 10 }}>
                <img src={logo} alt="Logo" style={{ marginTop: '10px', marginRight: '10px', width: 'auto', height: 50 }} />
              </Typography>
            </Typography>
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flex: 1,
            }}
          >
            {navItems.map((item) => (


              <Button
                key={item}
                sx={{ color: "white", fontSize: 16, textTransform: 'none', fontStyle: "bold", display: 'flex', alignItems: 'center' }}
                onClick={() => {
                  navigate(navLinks[item]);
                }}
              >
                {navIconMapping[item]}

                {item}
              </Button>

            ))}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("/login");
              }}
              sx={{ height:40, marginLeft: 5, marginTop:0, color: "white" }}
            >
              Login
            </Button>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}


export default Navbar;