import * as React from "react";
 
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import  styles from "./styles.module.css";
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from "../components/navbar";
import LottieView from "lottie-react";
import { TransitionImage } from "../components/transitionImage";
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import "@fontsource/roboto/700.css";
import Footer from "../components/footer";
import ParticleEngine from "../components/particles";
import {backgroundImage} from "../components/background";
import { headingStyles } from "../components/others";

const IndexPage = () => {
  React.useEffect(() => {
    function handleScroll() {
      // handle scroll event here
      console.log("scrolling...");
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div style={backgroundImage} />
      <Navbar />
      <Container maxWidth="lg">
        <Box
          sx={{
            backgroundColor: "#f8f8f8",
            padding: 4,
            borderRadius: 4,
            marginTop: 6,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h1"
            style={headingStyles}
            sx={{
              marginBottom: 2,
              paddingBottom: 5,
              fontWeight: "bold",
              fontSize: "2rem",
            }}
          >
            Privacy Policy
          </Typography>
          <Typography variant="body1">
            <p>Last updated: 11/9/2023</p>

            <p>
              This page informs you of our policies regarding
              the collection, use, and disclosure of personal data when you use
              our service and the choices you have associated with that data.
            </p>

            <h2>Information Collection and Use</h2>
            <p>
              We collect several different types of information for various
              purposes to provide and improve our service to you.
            </p>

            <h3>Types of Data Collected</h3>
            <p>
              While using our service, we may collect the following types of
              data:
            </p>
            <ul>
              <li>
                Personal Data
              </li>
              <li>
                Usage Data
              </li>
              <li>
                Cookies and Tracking Data
              </li>
            </ul>

            <h3>Use of Data</h3>
            <p>
              We may use the collected data for various purposes, including:
            </p>
            <ul>
              <li>To provide and maintain our service</li>
              <li>To notify you about changes to our service</li>
              <li>
                To allow you to participate in interactive features of our
                service
              </li>
              <li>To provide customer support</li>
              <li>
                To gather analysis or valuable information so that we can
                improve our service
              </li>
              <li>To monitor the usage of our service</li>
              <li>To detect, prevent and address technical issues</li>
            </ul>

            <h3>Data Disclosure</h3>
            <p>
              We may disclose your personal data in the following situations:
            </p>
            <ul>
              <li>If required by law or to respond to valid legal requests</li>
              <li>To protect and defend our rights or property</li>
              <li>
                To prevent or investigate possible wrongdoing in connection with
                the service
              </li>
              <li>
                To protect the personal safety of users of the service or the
                public
              </li>
              <li>
                With service providers, contractors, or trusted third parties
                who assist us in operating our service
              </li>
              <li>
                In connection with a merger, acquisition, or sale of assets
              </li>
            </ul>

            <h2>Data Security</h2>
            <p>
              The security of your data is important to us, but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your personal data, we cannot
              guarantee its absolute security.
            </p>

            <h2>Changes to This Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </p>

            <h2>Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us at 00357 99099231.
            </p>
          </Typography>
        </Box>
      </Container>
    </>
  );
};
export default IndexPage;
export const Head = () => <title>Tedu - Online Learning Platform</title>;
