import { useLocation, Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";

const RequireAuth = ({ allowedRole }) => {
    const location = useLocation();
    let decoded = null
    if (localStorage.getItem('accessToken') !== null)
        decoded = jwt_decode(localStorage.getItem('accessToken'));

    if (decoded !== null && decoded.UserInfo.roles === allowedRole)
        return <Outlet />
    else
        return <Navigate to="/login" state={{ from: location }} replace />

}


export default RequireAuth;