
export  const backgroundImage = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100vh',
    overflow: 'auto',
    backgroundImage: `url(/bg2.png)`,
    objectPosition: 'center',
    backgroundSize: '100% 100%',
    objectFit: 'cover',
    zIndex: '-1',
  }

  export const backgroundImageCity = {
    top: '0',
    left: '0',
    width: '100%',
    height: '100vh',
    overflow: 'auto',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `linear-gradient(180deg, #FCF1DE 0%, rgba(0,0,0,.31) 90%), url(/city.jpg)`,
    backgroundColor: '#FCF1DE', // Specify the desired background color here
    objectFit: 'cover',
    zIndex: '3',
  };
  
