import * as React from "react"
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import * as styles from './styles.module.css';
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from '../components/navbar'
import LottieView from 'lottie-react';
import { TransitionImage } from '../components/transitionImage'
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import '@fontsource/roboto/700.css';
import Footer from '../components/footer';
import ParticleEngine from '../components/particles';
import {backgroundImage} from '../components/background';
import {headingStyles} from '../components/others';

const IndexPage = () => {
  React.useEffect(() => {
    function handleScroll() {
      // handle scroll event here
      console.log('scrolling...');
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div style={backgroundImage} />
      <Navbar />
      <Container maxWidth="lg">


        <Box
          sx={{
            backgroundColor: '#f8f8f8',
            padding: 4,
            borderRadius: 4,
            marginTop: 6,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Typography variant="h1" style={headingStyles} sx={{
            marginBottom: 2,
            paddingBottom: 5,
            fontWeight: 'bold',
            fontSize: '2rem',
          }}>
            About Us
          </Typography>
          <Typography variant="body1">
            Tedu is an online learning platform that aims to make education accessible to everyone. We believe that everyone has the right to learn and develop their skills, no matter their background or circumstances.
            <br />
            <br />
            Our courses are designed by industry experts and cover a wide range of topics, from programming and web development to digital marketing and entrepreneurship. We use the latest technologies and teaching methods to create engaging and interactive learning experiences that will help you achieve your goals.
            <br />
            <br />
            Join our community of learners and start your journey to success today!
          </Typography>
        </Box>
      </Container>
    </>
  )
}
export default IndexPage
export const Head = () => <title>Tedu - Online Learning Platform</title>