import * as React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styles from "./styles.module.css";
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from "../components/navbar_logged_in";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Background from "../components/background";
import LottieView from "lottie-react";
import { TransitionImage } from "../components/transitionImage";
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import "@fontsource/roboto/700.css";
import Footer from "../components/footer";
import ParticleEngine from "../components/particles";
import { backgroundImage } from "../components/background";
import { useNavigate } from 'react-router-dom';
import { BACKEND_URL } from "./helper";
import jwt_decode from "jwt-decode";
import { errorAlert } from "./helper";
import { headingStyles } from "../components/others";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa"; // Example icons from Font Awesome
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import { IconButton } from "@mui/material";
import { BsTrash } from 'react-icons/bs';
import AddIcon from "@mui/icons-material/Add";

import {
  InputLabel,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Alert,
  FormControl,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const IndexPage = () => {

  const [open, setOpen] = React.useState(true);
  const [groupStudents, setGroupStudents] = React.useState("");
  const [groupUnregistered, setGroupUnregistered] = React.useState("");
  const navigate = useNavigate();
  const { sid, groupName } = useParams();


  React.useEffect(() => {
    fetch(BACKEND_URL + "/api/get_group_students/" + sid, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        console.log("setGroupStudents", json);
        setGroupStudents(json);
      });
  }, []);

  React.useEffect(() => {
    fetch(BACKEND_URL + "/api/get_group_unregistered/" + sid, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        console.log("setGroupUnregistered", json);
        setGroupUnregistered(json);
      });
  }, []);

  const CancelButton = () => {
    return (
      <>
        <Button
          style={{
            color: "white",
            backgroundColor: "primary",
            fontSize: 16,
            padding: "12px 20px",
            borderRadius: 4,
            width: "200px",
          }}
          variant="contained"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </>
    );
  };

  React.useEffect(() => {
    function handleScroll() {
      // handle scroll event here
      console.log("scrolling...");
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div style={backgroundImage} />
      <Navbar />
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              sx={{
                paddingBottom: 5,
                paddingTop: 5,
                paddingLeft: 5,
                paddingRight: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h1>Students in {groupName} </h1>
              <Box sx={{ flexGrow: 1, backgroundColor: "White" }}>
                <br></br>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item md={6} xs={6}>
                        <InputLabel className={styles.bigblue1}>
                          <Typography variant="h6">
                            <strong>Students Enrolled</strong>
                          </Typography>
                        </InputLabel>
{/* 
                    {Array.isArray(groupStudents) &&
                      groupStudents.length > 0 && (
                      )} */}

                    {/* <thead>
                        <tr>
                          <th>Student</th>
                          <th>Remove</th>
                        </tr>
                      </thead> */}
                    <table
                      style={{
                        borderCollapse: "collapse",
                        borderTop: "1px solid black",
                        borderRight: "1px solid black",
                        borderBottom: "1px solid black",
                        borderLeft: "none",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        {Array.isArray(groupStudents)
                          ? groupStudents.map((student, index) => (
                            <tr key={student.student_id} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#ffd4a4' }}>
                              <td
                                style={{
                                  borderTop: "1px solid black",
                                  borderRight: "none",
                                  borderBottom: "1px solid black",
                                  borderLeft:  "1px solid black",
                                  padding: "5px",
                                  textAlign: "center",

                                }}
                              >
                                {`${student.first_name} ${student.middle_name} ${student.last_name}`}
                              </td>
                              <td
                                style={{
                                  borderTop: "1px solid black",
                                  borderLeft: "none",
                                  borderBottom: "1px solid black",
                                  borderRight:  "1px solid black",
                                  padding: "5px",
                                  textAlign: "center",
                                  width: "100px",
                                }}
                              >
                                <button
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                    padding: "4px",
                                  }}
                                  onClick={() => {
                                    let do_the_request = true;
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: `Please verify that you want to remove this student from the specific group? All the progress of the student will be lost.`,
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#d33",
                                      cancelButtonColor: "#3085d6",
                                      confirmButtonText: "Delete",
                                      cancelButtonText: "Cancel",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        do_the_request = true;
                                      } else {
                                        do_the_request = false;
                                      }
                                      if (do_the_request) {
                                        fetch(
                                          BACKEND_URL +
                                          "/api/delete_group_participation/",
                                          {
                                            method: "DELETE",
                                            headers: {
                                              Accept: "application/json",
                                              "Content-Type":
                                                "application/json",
                                              Authorization: `Bearer ${localStorage.getItem(
                                                "accessToken"
                                              )}`,
                                            },
                                            body: JSON.stringify({
                                              student_id: student.student_id,
                                              group_id: sid, // group_id
                                            }),
                                          }
                                        )
                                          .then((response) => {
                                            if (response.status === 403) {
                                              localStorage.clear();
                                              navigate("/login", { replace: true });
                                              return Promise.reject();
                                            } else {
                                              return response;
                                            }
                                          })
                                          .then((response) => response.json())
                                          .then((json) => {
                                            // Handle the response here
                                            if (
                                              json.message ==
                                              "Deletion was successful"
                                            ) {
                                              // Success
                                              Swal.fire({
                                                title: "Student Removed",
                                                text: "The student was removed from the group successfully",
                                                icon: "success",
                                                confirmButtonColor: "#155263",
                                                confirmButtonText: "OK",
                                              }).then((result) => { });
                                            } else {
                                              Swal.fire({
                                                title: "Error",
                                                text: "An unexpected error occurred. Please try again.",
                                                icon: "error",
                                                confirmButtonColor: "#155263",
                                                confirmButtonText: "OK",
                                              }).then((result) => { });
                                            }
                                            fetch(
                                              BACKEND_URL +
                                              "/api/get_group_students/" +
                                              sid,

                                              {
                                                headers: {
                                                  Authorization: `Bearer ${localStorage.getItem(
                                                    "accessToken"
                                                  )}`,
                                                },
                                              }
                                            )
                                              .then((response) => {
                                                if (response.status === 403) {
                                                  localStorage.clear();
                                                  navigate("/login", { replace: true });
                                                  return Promise.reject();
                                                } else {
                                                  return response;
                                                }
                                              })
                                              .then((response) =>
                                                response.json()
                                              )
                                              .then((json) => {
                                                console.log(
                                                  "setGroupStudents",
                                                  json
                                                );
                                                setGroupStudents(json);
                                              });
                                            fetch(
                                              BACKEND_URL +
                                              "/api/get_group_unregistered/" +
                                              sid,
                                              {
                                                headers: {
                                                  Authorization: `Bearer ${localStorage.getItem(
                                                    "accessToken"
                                                  )}`,
                                                },
                                              }
                                            )
                                              .then((response) => {
                                                if (response.status === 403) {
                                                  localStorage.clear();
                                                  navigate("/login", { replace: true });
                                                  return Promise.reject();
                                                } else {
                                                  return response;
                                                }
                                              })
                                              .then((response) =>
                                                response.json()
                                              )
                                              .then((json) => {
                                                console.log(
                                                  "setGroupUnregistered",
                                                  json
                                                );
                                                setGroupUnregistered(json);
                                              });
                                          }, []);
                                      }
                                    });
                                  }}
                                >
                                  <IconButton
                                    style={{
                                      backgroundColor: "transparent",
                                      padding: 0,
                                    }}
                                  >
                              <BsTrash style={{ color: 'grey' }} />
                                  </IconButton>
                                </button>
                              </td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </table>
                  </Grid>
                  <Grid item md={6} xs={6}>

                    {Array.isArray(groupUnregistered) &&
                      groupUnregistered.length > 0 && (
                        <InputLabel className={styles.bigblue1}>
                          <Typography variant="h6">
                            <strong>Add Students</strong>
                          </Typography>
                        </InputLabel>
                      )}


                    <table
                      style={{
                        borderCollapse: "collapse",
                        borderTop: "1px solid black",
                        borderLeft: "none",
                        borderBottom: "1px solid black",
                        borderRight:  "1px solid black",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        {Array.isArray(groupUnregistered)
                          ? groupUnregistered.map((student, index) => (
                            <tr key={student.student_id} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#ffd4a4' }}>
                              <td
                                style={{
                                  borderTop: "1px solid black",
                                  borderRight: "none",
                                  borderBottom: "1px solid black",
                                  borderLeft:  "1px solid black",
                                  padding: "5px",
                                  textAlign: "center",
                                }}
                              >
                                {`${student.first_name} ${student.middle_name} ${student.last_name}`}
                              </td>
                              <td
                                style={{
                                  borderTop: "1px solid black",
                                  borderLeft: "none",
                                  borderBottom: "1px solid black",
                                  borderRight:  "1px solid black",                                  
                                  padding: "5px",
                                  textAlign: "center",
                                  width: "100px",
                                }}
                              >
                                <button
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                    padding: "4px",
                                  }}
                                  onClick={() => {
                                    fetch(
                                      BACKEND_URL + "/api/add_group_participation/",
                                      {
                                        method: "POST",
                                        headers: {
                                          Accept: "application/json",
                                          "Content-Type": "application/json",
                                          Authorization: `Bearer ${localStorage.getItem(
                                            "accessToken"
                                          )}`,
                                        },
                                        body: JSON.stringify({
                                          student_id: student.student_id,
                                          group_id: sid, // group_id
                                        }),
                                      }
                                    )
                                      .then((response) => {
                                        if (response.status === 403) {
                                          localStorage.clear();
                                          navigate("/login", { replace: true });
                                          return Promise.reject();
                                        } else {
                                          return response;
                                        }
                                      })
                                      .then((response) => response.json())
                                      .then((json) => {
                                        // Handle the response here
                                        if (
                                          json.message ==
                                          "Insertion was successful"
                                        ) {
                                          // Success
                                          Swal.fire({
                                            title: "Student Enrolled",
                                            text: "The student was enrolled to the group successfully",
                                            icon: "success",
                                            confirmButtonColor: "#155263",
                                            confirmButtonText: "OK",
                                          }).then((result) => { });
                                        } else {
                                          Swal.fire({
                                            title: "Error",
                                            text: "An unexpected error occurred. Please try again.",
                                            icon: "error",
                                            confirmButtonColor: "#155263",
                                            confirmButtonText: "OK",
                                          }).then((result) => { });
                                        }
                                        fetch(
                                          BACKEND_URL +
                                          "/api/get_group_students/" +
                                          sid,
                                          {
                                            headers: {
                                              Authorization: `Bearer ${localStorage.getItem(
                                                "accessToken"
                                              )}`,
                                            },
                                          }
                                        )
                                          .then((response) => {
                                            if (response.status === 403) {
                                              localStorage.clear();
                                              navigate("/login", { replace: true });
                                              return Promise.reject();
                                            } else {
                                              return response;
                                            }
                                          })
                                          .then((response) => response.json())
                                          .then((json) => {
                                            console.log(
                                              "setGroupStudents",
                                              json
                                            );
                                            setGroupStudents(json);
                                          });
                                        fetch(
                                          BACKEND_URL +
                                          "/api/get_group_unregistered/" +
                                          sid,
                                          {
                                            headers: {
                                              Authorization: `Bearer ${localStorage.getItem(
                                                "accessToken"
                                              )}`,
                                            },
                                          }
                                        )
                                          .then((response) => {
                                            if (response.status === 403) {
                                              localStorage.clear();
                                              navigate("/login", { replace: true });
                                              return Promise.reject();
                                            } else {
                                              return response;
                                            }
                                          })
                                          .then((response) => response.json())
                                          .then((json) => {
                                            console.log(
                                              "setGroupUnregistered",
                                              json
                                            );
                                            setGroupUnregistered(json);
                                          });
                                      }, []);
                                  }}
                                >
                                  <IconButton
                                    style={{
                                      backgroundColor: "transparent",
                                      padding: 0,
                                    }}
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </button>
                              </td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </table>
                  </Grid>

                  <Grid>
                    <br></br>
                    <br></br>
                    {CancelButton()}
                    <br></br>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default IndexPage;
export const Head = () => <title>Tedu - Online Learning Platform</title>;
