import * as React from "react"
import styles from './styles.module.css';
import { Link } from 'react-scroll';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from '../components/navbar'
import LottieView from 'lottie-react';
import { TransitionImage } from '../components/transitionImage'
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import '@fontsource/roboto/700.css';
import Footer from '../components/footer';
import ParticleEngine from '../components/particles';
import { backgroundImage } from '../components/background';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Link as RouterLink } from 'react-router-dom';

import { headingStyles, headingStyles2, headingStyles3, headingStyles4, headingAccentStyles, headingAccentStyles2 } from '../components/others';

const IndexPage = () => {

  React.useEffect(() => {
    function handleScroll() {
      // handle scroll event here
      console.log('scrolling...');
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div style={backgroundImage} />

      <Container maxWidth="md">
        <Box sx={{ marginTop: 30, marginBottom: 70, flexGrow: 1, display: 'flex' }}>
          <div className={styles.content}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: 'center', flexGrow: 1 }}>
                  <h1 style={{ ...headingStyles2, textAlign:'center',fontSize: '1.5rem'}}>
                    Education Suite
                  </h1>
                  <h1 style={{ ...headingStyles, marginTop: -10,textAlign:'center', fontSize: '3rem'}}>Empowering Educators to Inspire, Innovate, and Educate
                  </h1>
                </Box>
              </Grid>

              <Grid item xs={12}>
              <RouterLink to='https://tedu.us/contact'>
            <Button variant='contained' color='primary' endIcon={<PlayCircleOutlineIcon  />} sx={{ marginTop: -1, color: 'white' }}>
                Contact us
            </Button>
        </RouterLink>
                {/* <Link style={{ width: 'inherit' }} to='section-name' smooth={true}>
                  <Button variant='contained' color='primary' endIcon={<PlayCircleOutlineIcon />} sx={{ marginTop: 1, color: 'white' }}>
                    Take a free tour
                  </Button>
                </Link> */}
              </Grid>
            </Grid>

          </div>
        </Box>

      </Container>


      <Container maxWidth="lg">
            <Box sx={{ marginTop: 10, flexGrow: 1, display: 'flex' }}>
              <div className={styles.content}>
                <Grid container spacing={10}>
                  <Grid s item xs={6}>
                    <h1 style={headingStyles3}>Evaluation</h1>
                    <Box
                      sx={{
                      }}>
                      <p style={headingStyles4}>Get to know your students better with our powerful evaluation tool! Assess their level and status in any field, and take notes to personalize your teaching approach. Our tool helps you understand their learning style and preferences, allowing you to provide the best possible learning experience. Get started today and unlock your students' full potential!</p>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={styles.imageContainerNoMargin} style={{ backgroundImage: "url('pic3.png')" }}></div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={styles.imageContainerNoMargin} style={{ backgroundImage: "url('pic1.png')" }}></div>
                  </Grid>
                  <Grid item xs={6}>
                    <h1 style={headingStyles3}>Monitor Student Success </h1>
                    <Box
                      sx={{
                        borderRadius: 4,
                      }}>
                      <p style={headingStyles4}>Track your students' progress effortlessly with Tedu's progress monitor! Our tool provides real-time graphing and predicted mastery dates, allowing you to make proactive adjustments and ensure their success. Try it now and take control of your students' goals!</p>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <h1 style={headingStyles3}>Powerful Reports</h1>
                    <Box
                      sx={{
                        borderRadius: 4,
                      }}>
                      <p style={headingStyles4}>Tedu's reports feature, generating the perfect report has never been easier! Choose from our extensive list of pre-built reports or customize your own to meet your unique needs. Whether it's attendance records, test scores, or progress reports, we've got you covered. Try our reports feature today and streamline your reporting process like never before!</p>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={styles.imageContainerNoMargin} style={{ backgroundImage: "url('pic2.png')" }}></div>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Container>
      <Container sx={{ marginTop: 10 }} maxWidth="md">
    <Footer />
  </Container>


     
    </>
  )
}
export default IndexPage
export const Head = () => <title>Tedu - Online Learning Platform</title>