import * as React from "react"
import { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import LottieView from 'lottie-react';
import * as styles from './components.module.css';
import { mdiTrumpet } from "@mdi/js";
import './styles.css'
import { requirePropFactory } from "@mui/material";

export function TransitionImage() {
    const [showComponent, setShowComponent] = useState(false);
    const componentRef = React.useRef(null);
    const divRef = React.useRef(null);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        console.log('in')
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function handleScroll() {
        const scrollPosition = window.pageYOffset + window.innerHeight;
        const currentcomponentRef = divRef.current;
        if (currentcomponentRef && scrollPosition > currentcomponentRef.offsetTop) {
            setShowComponent(true);
        }
    }

    return (
        <div ref={divRef} >

            <CardMedia
                autoPlay
                allow="autoPlay"
                sx={{ 
                    marginBottom:10,
                     borderRadius: 10,
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                }}
                component='video'
                loop
                image={"/video1.mp4"}
                controls
            />
        </div>
    );
}






// <CSSTransition
// in={showComponent}
// unmountOnExit
// classNames="alert"
// nodeRef={componentRef}
// timeout={10000}>
// <div ref={componentRef}  className={styles.videowrapper}>
//     <CardMedia
//         component='gif'
//         autoPlay
//         muted
//         loop
//         image={"video.gif"}
//     />
// </div>
// </CSSTransition>