import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import "./App.css";
import AboutUs from "./pages/about-us";
import ComingUp from "./pages/coming-up";
import Contact from "./pages/contact";
import Home from "./pages/home";
import Login from "./pages/login";
import SignUp from "./pages/signup";
import EditStudents from "./pages/editstudents";
import Modules from "./pages/modules";
import AddGroups from "./pages/add_groups";
import AddStudents from "./pages/add_students";
import EditGroups from "./pages/editgroups";
import PrivacyPolicy from "./pages/privacy-policy";
import Dashboard from "./pages/dashboard";
import Forgot from "./pages/forgot-password";
import Groupparticipations from "./pages/groupparticipations";
import Groups from "./pages/groups";
import ProgressMonitoring from "./pages/progressmonitoring";
import ProgressMonitoringZoom from "./pages/progressmonitoringzoom";
import ProgressReport from "./pages/progressreport"
import Students from "./pages/students";
import StudentsProfile from "./pages/studentsprofile";
import Why from "./pages/whytedu";
import HomeAdmin from "./pages/homeadmin";
import StudentsGroup from './pages/studentsgroup'
import Courses from "./pages/courses";
import AddCourses from "./pages/add_courses";
import EditCourses from "./pages/editcourses";
import StudentsCourse from './pages/studentscourse'
import Courseparticipations from "./pages/courseparticipations";
import ScrollToTop from "./components/scroll";



function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/coming-up" element={<ComingUp />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/modules" element={<Modules />} />
        <Route exact path="/forgot-password" element={<Forgot />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/whytedu" element={<Why />} />

        <Route element={<RequireAuth allowedRole={"admin"} />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/add_groups" element={<AddGroups />} />
          <Route exact path="/add_students" element={<AddStudents />} />
          <Route exact path="/editgroups/sid/:sid" element={<EditGroups />} />
          <Route exact path="/editstudents/sid/:sid" element={<EditStudents />} />
          <Route exact path="/homeadmin" element={<HomeAdmin />} />
          <Route exact path="/courses" element={<Courses />} />
          <Route exact path="/courseparticipations/sid/:sid/courseName/:courseName" element={<Courseparticipations />} />
          <Route exact path="/studentscourse/sid/:sid/courseName/:courseName" element={<StudentsCourse />} />
          <Route exact path="/add_courses" element={<AddCourses />} />
          <Route exact path="/editcourses/sid/:sid" element={<EditCourses />} />
          <Route exact path="/progressmonitoring/sid/:sid" element={<ProgressMonitoring />} />
          <Route exact path="/progressreport/sid/:sid" element={<ProgressReport />} />

          <Route exact path="/students" element={<Students />} />
          <Route exact path="/studentsProfile/sid/:sid" element={<StudentsProfile />} />
          <Route exact path="/groupparticipations/sid/:sid/groupName/:groupName" element={<Groupparticipations />} />
          <Route exact path="/studentsgroup/sid/:sid/groupName/:groupName" element={<StudentsGroup />} />
          <Route exact path="/groups" element={<Groups />} />
          <Route exact path="/progressmonitoringzoom/sid/:sid/courseID/:courseID/dateFrom/:dateFrom/dateTo/:dateTo" element={<ProgressMonitoringZoom />} />
        </Route>
      </Routes>
    </Router>
  );
}
export default App;
     {
        /*
        
          TO DO AUTHENTICATION
        <Route element={<RequireAuth allowedRole={"admin"} />}>
           <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/changepassword" element={<ChangePassword />} />
          <Route exact path="/deleteaccount" element={<DeleteAccount />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route
            exact
            path="/administrators"
            element={<AdminAdministrators />}
          />
          <Route
            exact
            path="/editadministrator"
            element={<EditAdministrator />}
          />
          <Route
            exact
            path="/addadministrator"
            element={<AddAdministrator />}
          />
          <Route exact path="/newrestaurant" element={<NewRestaurant />} />
          <Route
            exact
            path="/restaurantdetails"
            element={<RestaurantDetails />}
          /> 
        </Route> */}
     
