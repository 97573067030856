import * as React from "react";
 
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import  styles from "./styles.module.css";
import { mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from "../components/navbar_logged_in";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Background from "../components/background";
import LottieView from "lottie-react";
import { TransitionImage } from "../components/transitionImage";
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import "@fontsource/roboto/700.css";
import Footer from "../components/footer";
import {backgroundImage} from "../components/background";
import axios from "./axios";
import { BACKEND_URL } from "./helper";
import jwt_decode from "jwt-decode";
import { errorAlert } from "./helper";
import { headingStyles } from "../components/others";
import { FaPenSquare, FaTrash, FaPlus } from "react-icons/fa"; // Example icons from Font Awesome
import { useNavigate } from 'react-router-dom';import Swal from "sweetalert2";
import { MdEdit } from 'react-icons/md';
import { BsTrash } from 'react-icons/bs';
import {IoAddCircleOutline} from 'react-icons/io5'
import { useParams } from "react-router-dom";
import StudentBox from "../components/studentBox";
// Helper function to calculate the age based on birthdate
const calculateAge = (birthMonth, birthDay, birthYear) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;
  const currentDate = today.getDate();

  let age = currentYear - birthYear;

  if (
    currentMonth < birthMonth ||
    (currentMonth === birthMonth && currentDate < birthDay)
  ) {
    age--;
  }

  if (age > 150) {
    return "-";
  }

  return age;
};

const IndexPage = () => {
  const [students, setStudents] = React.useState("");
  const [groupStudents, setGroupStudents] = React.useState("");

  const navigate= useNavigate();
  const { sid,groupName } = useParams();

  React.useEffect(() => {
    function handleScroll() {
      // handle scroll event here
      console.log("scrolling...");
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

 

  React.useEffect(() => {
    fetch(BACKEND_URL + "/api/get_group_students/" + sid, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        console.log("setGroupStudents", json);
        setGroupStudents(json);
      });
  }, []);


  return (
    <>
      <div style={backgroundImage} />
      <Navbar />

      <Container maxWidth='xl'>
        <Box
          sx={{
            backgroundColor: "#f8f8f8",
            padding: 4,
            borderRadius: 4,
            marginTop: 6,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h1"
            style={{
              borderTopRightRadius: '33px',
              fontFamily: 'Roboto',
              fontSize: '2rem',
              fontWeight: 500,
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'grey',
              marginBottom: 5,
              paddingBottom:15,
              paddingTop:10,
              paddingLeft:10,
              fontWeight: 'bold',
            }}
          >
            <span style={{ marginRight: '5px' }}>Students in {groupName}</span>
          
          </Typography>

          <div style={{marginTop:30}}>
            <Grid container spacing={1}>
            {Array.isArray(groupStudents)
                ?  groupStudents.map((student) => (
                  <StudentBox student={student} setStudents={setStudents}/>
                ))
                : null}
            </Grid>
          </div>
        </Box>
      </Container>

      
     

         
    </>
  );
};
export default IndexPage;
export const Head = () => <title>Tedu - Online Learning Platform</title>;
