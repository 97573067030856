import * as React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import CircularProgress from '@mui/material/CircularProgress';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styles from "./styles.module.css";
import { mdiConsoleLine, mdiRobotMowerOutline } from "@mdi/js";
import CardList from "../components/landing-cards";
import Navbar from "../components/navbar_logged_in";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Background from "../components/background";
import LottieView from "lottie-react";
import { TransitionImage } from "../components/transitionImage";
import { mdiCheckBold } from "@mdi/js";
import { borderLeft } from "@mui/system";
import "@fontsource/roboto/700.css";
import Footer from "../components/footer";
import ParticleEngine from "../components/particles";
import { backgroundImage } from "../components/background";
import { useNavigate } from 'react-router-dom';
import { BACKEND_URL, THRESHOLD_LESSONS, CLIENT_URL } from "./helper";
import jwt_decode from "jwt-decode";
import { errorAlert } from "./helper";
import { headingStyles } from "../components/others";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa"; // Example icons from Font Awesome
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import queryString from "query-string";
import { IconButton } from "@mui/material";
import { BsTrash, BsDownload, BsZoomIn } from 'react-icons/bs';
import AddIcon from "@mui/icons-material/Add";
import { Line } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { IoSync } from "react-icons/io5";

import { isAfter, isToday, setDate } from 'date-fns';
import dayjs from 'dayjs';
import {
  InputLabel,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Alert,
  FormControl,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

import { format } from "date-fns";

const IndexPage = () => {
  Chart.register(CategoryScale);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [studentCourses, setStudentCourses] = React.useState([{}]);
  const [student, setStudent] = React.useState([{}]);
  const [course, setCourse] = React.useState({});
  const [courseID, setCourseID] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [overallScore, setOverallScore] = React.useState(0);
  const [additionalComments, setAdditionalComments] = React.useState("");
  const [progressMonitoring, setProgressMonitoring] = React.useState([]);
  const [updateChart, setUpdateChart] = React.useState(false);
  const [target, setTarget] = React.useState(20);
  const [baseline, setBaseline] = React.useState(80);
  const currentDate = new Date();
  const lastYearDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate()).toISOString().slice(0, 10);
  const [dateFromReport, setDateFromReport] = React.useState(lastYearDate);
  const [dateToReport, setDateToReport] = React.useState(currentDate.toISOString().slice(0, 10));
  const [dateFromChart, setDateFromChart] = React.useState(dayjs(null));
  const [dateToChart, setDateToChart] = React.useState(dayjs());
  const [chartData, setChartData] = React.useState({});
  const { sid } = useParams();
  const [openReportModal, setOpenReportModal] = React.useState(false);
  const [filteredDates, setfilteredDates] = React.useState({});
  const isInitialRender = React.useRef(true);
  const chartLabel = React.useRef(null);
  const [dateGrade, setDateGrade] = React.useState(dayjs());
  const [messageChart, setMessageChart] = React.useState('');
  const [validChart, setValidChart] = React.useState(false);
  const [loadReport, setLoadReport] = React.useState(false)
  const textFieldRef = React.useRef(null);
  const [target_table, setTargetTable] = React.useState([]);
  function handleZoom() {
    const dateFromFormatted = dateFromChart.format('DD-MM-YYYY');
    const dateToFormatted = dateToChart.format('DD-MM-YYYY');
    const url = `/progressmonitoringzoom/sid/${sid}/courseID/${courseID}/dateFrom/${dateFromFormatted}/dateTo/${dateToFormatted}`
    window.open(CLIENT_URL + url, '_blank');
  }

  function handleGenerationReport() {
    setLoadReport(true);
    setOpenReportModal(false);
    const url = '/api/create_report/';
    const requestBody = {
      student_id: sid,
      course_id: courseID,
      date_from: dateFromChart,
      date_to: dateToChart
    };
    fetch(BACKEND_URL + url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      body: JSON.stringify(requestBody)
    })
    .then(response => {
      if (response.status === 200) {
        // Success case: Status 200
        Swal.fire('Report Generated', '', 'success');
        setLoadReport(false);
        return response.blob(); // convert to blob only if status is 200
      } else {
        throw new Error('Failed to generate report'); // throw an error if status is not 200
      }
    })
    .then(blob => {
      // create a new object URL from the blob
      const url = window.URL.createObjectURL(new Blob([blob]));
      // create a link element
      const link = document.createElement('a');
      link.href = url;
      // set the download attribute (optional)
      link.setAttribute('download', 'Progress Report for '+ student[0].first_name+' '+student[0].last_name+'.pdf');
      // append the link to the body
      document.body.appendChild(link);
      // click the link
      link.click();
      // remove the link after download
      link.parentNode.removeChild(link);
    })
    .catch(error => {
      Swal.fire('Encounter an Error', '', 'error');
      console.error(error);
    });
  }
  

  function calculateUpperTarget(points, target) {
    const n = points.length;
    const out_points = [];
    let x = 0
    for (let i = 0; i < n; i++) {
      let y = parseInt(points[i]);
      if (y > target) {
        x = i
        out_points.push({ x, y });
      }
      else {
        x = 0;
        y = 0;
        out_points.push({});
      }
    }
    return out_points;
  }
  function calculateLinearRegression(points) {
    let sumX = 0;
    let sumY = 0;
    let sumXY = 0;
    let sumXX = 0;

    const n = points.length;

    for (let i = 0; i < n; i++) {
      sumX = i + sumX;
      sumY = parseInt(points[i]) + sumY;
      sumXY = (i * points[i]) + sumXY;
      sumXX = (i * i) + sumXX;
    }

    const m = ((n * sumXY) - (sumX * sumY)) / ((n * sumXX) - (sumX * sumX));
    const b = (sumY - (m * sumX)) / n;
    const startX = 0;
    const endX = points.length;
    const step = 1;
    const out_points = [];
    for (let x = startX; x < endX; x += step) {
      const y = m * x + b;
      out_points.push({ x, y });
    }

    return out_points;
  }

  function linearRegression(X, y) {
    const nSamples = X.length;
    const nFeatures = X[0].length;

    // Compute the mean values of X and y
    const meanX = X.reduce((acc, curr) => acc.map((x, i) => x + curr[i]), Array(nFeatures).fill(0)).map((sum) => sum / nSamples);
    const meanY = y.reduce((acc, curr) => acc + curr, 0) / nSamples;

    // Compute the covariance and variance of X
    let cov = 0;
    let varX = 0;
    for (let i = 0; i < nSamples; i++) {
      cov += (X[i].reduce((acc, curr, j) => acc + (curr - meanX[j]) * (y[i] - meanY), 0)) / nSamples;
      varX += (X[i].reduce((acc, curr, j) => acc + (curr - meanX[j]) ** 2, 0)) / nSamples;
    }

    // Compute the slope (weights) and intercept (bias) of the linear regression line
    const slope = cov / varX;
    const intercept = meanY - slope * meanX.reduce((acc, curr) => acc + curr, 0) / nFeatures;

    return { slope, intercept };
  }

  function forecastNextGrades(filteredGrades, filteredDates) {

    if (Array.isArray(filteredGrades) && Array.isArray(filteredDates) && filteredGrades.length > 0 && filteredDates.length > 0) {
      const parsedGrades = filteredGrades.map((grade) => parseInt(grade));
      const X = parsedGrades.map((_, index) => [index]);
      const y = parsedGrades;
      const model = linearRegression(X, y);
      const numZeros = filteredDates.length;
      const forecastedGrades = Array(numZeros).fill(-10);
      const nextGradeIndex = parsedGrades.length;


      const nextGrade = model.slope * nextGradeIndex + model.intercept;
      forecastedGrades.push(nextGrade);
      forecastedGrades.push(-10);



      return forecastedGrades;
    }
    else {
      return null;
    }
  }

  React.useEffect(() => {
    fetch(BACKEND_URL + "/api/get_student/" + sid, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        setStudent(json);
      });

  }, []);

  React.useEffect(() => {
    filterGraph();

  }, [updateChart])

  React.useEffect(() => {
    fetch(BACKEND_URL + "/api/get_student_courses/" + sid, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        console.log("🚀 ~ file: progressmonitoring.js:280 ~ .then ~ studentCourses:", json)
        setStudentCourses(json);
      });

  }, []);

  React.useEffect(() => {
    fetch(`${BACKEND_URL}/api/get_target_baseline/?student_id=${sid}&course_id=${courseID}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        if (json.length > 0) {
          setTarget(json[0]['target']);
          setBaseline(json[0]['baseline']);
          setUpdateChart(!updateChart)
        }
      });
  }, [courseID]);

  React.useEffect(() => {

    fetch(`${BACKEND_URL}/api/get_progress_monitoring/?student_id=${sid}&course_id=${courseID}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        setProgressMonitoring(json);

      }).catch((error) => {
        console.error("Error fetching progress monitoring:", error);
      });
  }, [courseID]);

  const CancelButton = () => {
    return (
      <>
        <Button
          style={{
            marginTop: 20,
            color: "white",
            backgroundColor: "primary",
            fontSize: 16,
            padding: "12px 20px",
            borderRadius: 4,
            width: "200px",
          }}
          variant="contained"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </>
    );
  };



  const addRow = () => {
    const currentSum = rows.reduce(
      (sum, row) => sum + parseInt(row.percentage),
      0
    );
    const defaultPercentage = 100 - currentSum;

    if (rows.length === 0) {
      setRows([
        ...rows,
        { percentage: 100, field: "", score: 0, comment: "" },
      ]);
    }
    else {
      setRows([
        ...rows,
        { percentage: 0, field: "", score: 0, comment: "" },
      ]);
    }
    // updateScore(rows);

  };

  const deleteRow = (index) => {
    let column = "percentage";
    const updatedRows = [...rows];
    let numberofrows = updatedRows.length;
    let removedPercentage = updatedRows[index][column];
    updatedRows.splice(index, 1);

    if (numberofrows > 1) {
      for (let i = 0; i < updatedRows.length; i++) {
        updatedRows[i][column] = Math.round(
          (updatedRows[i][column] * 100) / (100 - removedPercentage)
        );
      }

      let verify_total = 0;
      if (updatedRows.length == 1) {
        updatedRows[0][column] = 100
      } else {
        for (let i = 0; i < updatedRows.length; i++) {
          if (isNaN(updatedRows[i][column])) {
            updatedRows[i][column] = 0
          } else {
            verify_total = verify_total + updatedRows[i][column];
          }
        }

        let ipoloipo = 100 - verify_total;
        while (ipoloipo != 0 && !isNaN(ipoloipo)) {
          let ran = Math.floor(Math.random() * updatedRows.length);
          if (ipoloipo > 0) {
            if (ran !== index) {
              updatedRows[ran][column] = updatedRows[ran][column] + 1;
              ipoloipo = ipoloipo - 1;
            }
          } else {
            if (ran !== index && updatedRows[ran][column] > 0) {
              updatedRows[ran][column] = updatedRows[ran][column] - 1;
              ipoloipo = ipoloipo + 1;
            }
          }
        }
      }


    }
    setRows(updatedRows);
    if (updatedRows.length > 0) {
      updateScore(updatedRows);
    } else {
      setOverallScore(0);
    }
  };

  const updateScore = (updatedRows) => {
    const percentage = "percentage";
    const score = "score";
    let overall_score = 0;
    for (let i = 0; i < updatedRows.length; i++) {
      overall_score =
        overall_score +
        (updatedRows[i][percentage] * updatedRows[i][score]) / 100;
    }
    let overall_score_formatted = parseFloat(overall_score.toFixed(2));
    setOverallScore(overall_score_formatted);
  };

  const handleInputChange = (index, column, value) => {
    const updatedRows = [...rows];
    updatedRows[index][column] = value;
    setRows(updatedRows);
    updateScore(updatedRows);
  };

  const getGradeContents = () => {
    const updatedRows = [...rows];
    const percentage = "percentage";
    const field = "field";
    const score = "score";
    const comment = "comment";
    let gradeContents = '';
    for (let i = 0; i < updatedRows.length; i++) {
      gradeContents =
        gradeContents + removeCurrencySymbols(updatedRows[i][percentage]) + '$' + removeCurrencySymbols(updatedRows[i][field]) + '$' + removeCurrencySymbols(updatedRows[i][score]) + '$' + removeCurrencySymbols(updatedRows[i][comment]) + '₺';
    }
    return gradeContents
  };

  function removeCurrencySymbols(inputString) {
    if (inputString === null) {
      return '';
    }

    if (typeof inputString !== 'string') {
      console.error('Input must be a string');
      return inputString;
    }

    return inputString.replace(/[$₺]/g, '');
  }


  const checkScore = () => {
    let totalPercentage = 0;
    for (let i = 0; i < rows.length; i++) {
      let p = parseInt(rows[i]["score"])
      if (p > 100 || p < 0)
        return false
    }
    return true;
  }
  const calculateTotalPercentage = () => {
    let totalPercentage = 0;
    for (let i = 0; i < rows.length; i++) {
      totalPercentage += parseInt(rows[i]["percentage"]);
    }
    return totalPercentage;
  }

  const validatePercentage = (index, column, value) => {

    const newPercentage = parseInt(value);
    if (value == '')
      return 0;
    if ((value != '') && (isNaN(newPercentage) || newPercentage < 0 || newPercentage > 100)) {
      return; // Invalid input, do not update the state
    }

    const updatedRows = [...rows];
    updatedRows[index][column] = newPercentage;

    const remainingSum = 100 - newPercentage;
    const numRemainingRows = rows.length - 1;
    const remainingPercentage = Math.floor(remainingSum / numRemainingRows);


    let old_index_percentage = 0;
    for (let i = 0; i < rows.length; i++) {
      if (i !== index) {
        old_index_percentage = old_index_percentage + updatedRows[i][column];
      }
    }
    old_index_percentage = 100 - old_index_percentage;


    for (let i = 0; i < rows.length; i++) {
      if (i !== index && updatedRows[i][column] > 0) {
        updatedRows[i][column] = Math.round(
          (updatedRows[i][column] * remainingSum) / (100 - old_index_percentage)
        );
      }
    }

    let verify_total = 0;
    for (let i = 0; i < rows.length; i++) {
      verify_total = verify_total + updatedRows[i][column];
    }

    let ipoloipo = 100 - verify_total;

    while (ipoloipo != 0) {
      let ran = Math.floor(Math.random() * rows.length);
      if (ipoloipo > 0) {
        if (ran !== index) {
          updatedRows[ran][column] = updatedRows[ran][column] + 1;
          ipoloipo = ipoloipo - 1;
        }
      } else {
        if (ran !== index && updatedRows[ran][column] > 0) {
          updatedRows[ran][column] = updatedRows[ran][column] - 1;
          ipoloipo = ipoloipo + 1;
        }
      }
    }

    setRows(updatedRows);
    updateScore(updatedRows);
  };
  React.useEffect(() => {
    filterGraph();
  }, [dateFromChart, dateToChart])
  const validateScore = (index, column, value) => {
    const newScore = parseInt(value);

    if (isNaN(newScore) || newScore < 0 || newScore > 100) {
      return; // Invalid input, do not update the state
    }

    const updatedRows = [...rows];
    updatedRows[index][column] = newScore;
    setRows(updatedRows);
    updateScore(updatedRows);
  };

  const SubmitButton = () => {
    return (
      <>
        <Button
          style={{
            color: "white",
            backgroundColor: "primary",
            fontSize: 16,
            padding: "12px 20px",
            borderRadius: 4,
            width: "200px",
            marginTop: 20,
            marginLeft: "50px",
          }}
          variant="contained"
          onClick={async () => {
            let do_the_request = true;
            if (do_the_request) {
              await fetch(BACKEND_URL + "/api/add_grade/", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                },
                body: JSON.stringify({
                  student_id: sid,
                  course_id: courseID,
                  grade: overallScore,
                  dateGrade: dateGrade,
                  grade_contents: getGradeContents(),
                  comment: additionalComments,
                }),
              })
                .then((response) => {
                  if (response.status === 403) {
                    localStorage.clear();
                    navigate("/login", { replace: true });
                    return Promise.reject();
                  } else {
                    return response;
                  }
                })
                .then((response) => response.json())
                .then((json) => {
                  // Handle the response here
                  if (json.message == "Insertion was successful") {
                    // Success
                    Swal.fire({
                      title: "Grade Submitted",
                      text: "The grade was submitted successfully",
                      icon: "success",
                      confirmButtonColor: "#155263",
                      confirmButtonText: "OK",
                    }).then((result) => {
                      setRows([]);
                      setOverallScore(0);
                      setAdditionalComments('')
                      setUpdateChart(!updateChart)
                    });

                    setTimeout(() => {
                      fetch(`${BACKEND_URL}/api/get_progress_monitoring/?student_id=${sid}&course_id=${courseID}`, {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                      })
                        .then((response) => {
                          if (response.status === 403) {
                            localStorage.clear();
                            navigate("/login", { replace: true });
                            return Promise.reject();
                          } else {
                            return response;
                          }
                        })
                        .then((response) => response.json())
                        .then((json) => {
                          console.log(json)
                          setProgressMonitoring(json);


                        })
                        .catch((error) => {
                          console.error("Error fetching progress monitoring:", error);
                        });
                    }, 3000);
                  } else {
                    // Success
                    Swal.fire({
                      title: "Error",
                      text: "An unexpected error occurred. Please try again.",
                      icon: "error",
                      confirmButtonColor: "#155263",
                      confirmButtonText: "OK",
                    }).then((result) => { });
                  }
                });
            }
          }}

        >
          Submit
        </Button>
      </>
    );
  };

  const setTargetBaseline = (type, value) => {
    let target_local = target
    let baseline_local = baseline
    if (type == 'target') {
      target_local = value
    } else {
      baseline_local = value
    }
    fetch(`${BACKEND_URL}/api/set_target_baseline/?student_id=${sid}&course_id=${courseID}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "accessToken"
        )}`,
      },
      body: JSON.stringify({
        target: target_local,
        baseline: baseline_local,
      }),
    })
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return Promise.reject();
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((json) => {
        // Handle the response here
        if (json.message == "Update was successful") {
          // Success
        } else {
          // Failed
        }
      });
  };

  const printFunctions = (obj) => {
    for (let key in obj) {
      if (typeof obj[key] === 'function') {
        console.log(key, obj[key]);
      }
    }
  }
  function filterGraph() {
    const dates = progressMonitoring.map(entry => entry.date);

    let firstDate = null;
    let lastDate = null;
    if (dateFromChart == '' && dateToChart == '' && Array.isArray(dates) && dates.length > 0) {
      let startIndex = 0;
      let endIndex = Math.max(dates.length - 1, 9);
      if (dates.length <= 10) {
        startIndex = 0;
      } else {
        startIndex = endIndex - 9;
      }
      firstDate = dates[startIndex]
      lastDate = dates[endIndex]
      firstDate = dayjs(firstDate);
      lastDate = dayjs(lastDate);
    }
    else {
      firstDate = dateFromChart;
      lastDate = dateToChart
    }

    const grades = progressMonitoring.map(entry => entry.grade);
    const gradesIndexes = [];


    const filteredDates = dates.filter((date, index) => {
      const currentDate = dayjs(date);
      if (currentDate.isBetween(firstDate, lastDate) || currentDate.isSame(firstDate, 'day') || currentDate.isSame(lastDate, 'day')) {
        gradesIndexes.push(index);
        return true;
      }

      return false;
    })

    const correctFilteredDates = filteredDates.map(date => dayjs(date).format("DD/MM/YYYY"))
    if (correctFilteredDates.length === 0) {
      setMessageChart("No lessons were found during these dates.");
      setValidChart(false);
      return;
    }
    else if (correctFilteredDates.length >= THRESHOLD_LESSONS) {
      setMessageChart(`Lesson dates exceed the threshold (${THRESHOLD_LESSONS}). Please check the lessons scheduled between the given dates.`);
      setValidChart(false);
      return;
    }
    else {
      setMessageChart('');
      setValidChart(true);
    }
    const filteredGrades = gradesIndexes.map((index) => grades[index]);
    const forecastedGrades = forecastNextGrades(filteredGrades, correctFilteredDates);
    const comments = progressMonitoring.map(entry => entry.comment);
    const baseline_table = new Array(grades.length).fill(baseline);
    const target_table = new Array(grades.length).fill(target);
    setTargetTable(target_table)
    const star = new Image(100, 100); const forecast = new Image(15, 15);
    forecast.src = '/circle2.png'; star.src = '/star.png';
    const out_points = calculateLinearRegression(filteredGrades);
    const upper_target_points = calculateUpperTarget(filteredGrades, target);
    correctFilteredDates.push('Next Lesson')
    setChartData({
      labels: correctFilteredDates,
      datasets: [
        {
          label: 'Forecast',
          data: forecastedGrades,
          backgroundColor: 'skyblue',
          borderColor: 'skyblue',
          pointStyle: forecast,
          fill: false,
          showLine: false,
        },
        {
          label: 'Upper Target Points',
          data: upper_target_points,
          backgroundColor: 'orange',
          borderColor: 'orange',
          pointStyle: star,
          borderWidth: 1.5,
          fill: false,
          showLine: false,
        },
        {
          label: 'Score',
          data: filteredGrades,
          borderColor: 'grey',
          backgroundColor: 'grey',
          pointStyle: 'circle',
          pointRadius: 6,
          borderWidth: 1.5,
          showLine: true,
        },
        {
          label: 'Baseline',
          data: baseline_table,
          borderColor: 'rgb(252, 3, 3)',
          backgroundColor: 'rgb(252, 3, 3)',
          pointRadius: 0,
          borderWidth: 1.5,
          showLine: true,
        },
        {
          label: 'Target',
          borderWidth: 1.5,

          data: target_table,
          backgroundColor: 'rgb(0, 176, 9)',
          borderColor: 'rgb(0, 176, 9)',
          fill: false,
          pointRadius: 0,

          showLine: true,
        },

        {
          label: 'Best Straight Line',
          data: out_points,
          borderColor: '#F27216',
          backgroundColor: '#F27216',
          pointRadius: 0,
          borderDash: [5, 5],
          showLine: true,
        },
      ],
      options: {
        layout: {
        },
        scales: {
          y: {
            min: 0,
            max: 100,
            ticks: {
              padding: 0,  // Add padding
            },
            beginAtZero: true,

          },
          x: {
            ticks: {
              padding: 1,  // Add padding
              font: {
                size: 8
              }
            }
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                var datasetLabel = context.dataset.label || '';
                var dataLabel = context.parsed.y || '';

                if (datasetLabel) {
                  datasetLabel += ': ';
                }

                var index = context.dataIndex;
                var comment = comments[index] || '';

                return datasetLabel + dataLabel + ' - ' + comment;
              },
            },
          },
          onClick: function (evt, activeElements) {
            // activeElements is an array containing all the active elements (datapoints)
            if (activeElements.length > 0) {
              var chartElement = activeElements[0];  // get first active element

              var datasetIndex = chartElement._datasetIndex;
              var dataIndex = chartElement._index;

              var label = this.data.labels[dataIndex];
              var value = this.data.datasets[datasetIndex].data[dataIndex];

              console.log(`Label: ${label}, Value: ${value}`);
            }
          },
        },
      },
    });
  }

  React.useEffect(() => {
    filterGraph();
    const lastTenValues = progressMonitoring.map(entry => entry.date).slice(-10);

    if (lastTenValues.length > 0) {
      setDateFromChart(dayjs(lastTenValues[0]));
    } else {
      setDateFromChart(dayjs(null));
    }

  }, [progressMonitoring])



  React.useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [textFieldRef.current]);

  return (
    <>
      <div style={backgroundImage} />
      <Navbar />
      <Container style={{ margin: 10 }} maxWidth={false}>
        <Paper
          sx={{
            paddingBottom: 5,
            paddingTop: 5,
            paddingLeft: 5,
            paddingRight: 5,
            display: "flex",
            flexDirection: "column",
          }}
        >

          <Grid
            container
            spacing={2}
            style={{ marginTop: -10, display: 'flex', flexDirection: "row", }}
          >
            <Grid item md={10} xs={10}>
              <h2>Progress Report</h2>
            </Grid>


          </Grid>
          <h3 style={{ marginTop: -10 }}>{student[0].first_name} {student[0].middle_name} {student[0].last_name}</h3>
          <Grid
            container
            spacing={2}
            style={{ marginTop: -10, flexDirection: "row", display: 'flex' }}
          >
            {Array.isArray(studentCourses) &&
              studentCourses.length == 0 ?


              <Grid item md={3} xs={3}>
                <InputLabel className={styles.bigblue1}>
                  <Typography variant="h6">
                    <strong>
                      There are no courses enrolled to this student.
                    </strong>
                  </Typography>
                </InputLabel>
              </Grid>

              :
              <>
                <Grid item xs={2} sm={2} md={2} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: "flex-start", justifyContent: 'flex-start' }}>
                  <Typography>Choose Course:</Typography>
                  <Select
                    onChange={(e) => {
                      const foundCourse = studentCourses.find((course) => {
                        return course.course_name === e.target.value;
                      });
                      setCourse(e.target.value);
                      setCourseID(
                        foundCourse.course_id
                      );
                    }}
                    value={course.course_name}
                    required
                    fullWidth
                    id="course-select"

                  >
                    {Array.isArray(studentCourses) &&
                      studentCourses.map((course) => {
                        return (
                          <MenuItem key={course.course_name} value={course.course_name}>
                            {course.course_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>

                {courseID &&
                  <Grid
                    container
                    spacing={2}
                    style={{ marginTop: 10, marginLeft: 1, display: 'flex', flexDirection: "row", }}
                  >
                    <br></br>
                    <Grid item xs={1.5} sm={1.5} md={1.5} style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignContent: "flex-start", justifyContent: 'flex-start' }}>
                      <Box>

                        <Typography component="h6"> Date From: </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            disableFuture
                            value={dayjs(dateFromChart)}
                            renderInput={(props) => <TextField  {...props} size='small' />}
                            onChange={(newValue) => {
                              setDateFromChart(newValue);
                            }}
                            fullWidth
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid item xs={1.5} sm={1.5} md={1.5} style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignContent: "flex-start", justifyContent: 'flex-start' }}>
                      <Box>
                        <Typography component="h6"> Date To: </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            disableFuture
                            value={dayjs(dateToChart)}
                            renderInput={(props) => <TextField  {...props} size='small' />}
                            onChange={(newValue) => {
                              setDateToChart(newValue);
                            }}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'center' }}>
                        {validChart && <Button variant="contained" style={{ marginTop:20, alignSelf: "center"}} onClick={() => handleGenerationReport()}>Report  &nbsp; <BsDownload style={{ fontSize: 20 }} /> </Button>}
                        {loadReport && <div style={{ display: 'flex', alignItems: 'center' }}><h4 style={{ marginTop:40, alignSelf: "center" ,marginLeft:20}}> Generating report </h4> <CircularProgress style={{ marginTop:20, alignSelf: "center" ,marginLeft: 10}} /></div>}

                    </Grid>


                  </Grid>}
              </>
            }
          </Grid>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >

            {courseID != '' ? <>

              {chartData !== {} ? (
                <Grid item md={12} xs={12} >
                  <div>
                    {
                      (messageChart === '' && chartData.labels && chartData.datasets

                        ? <Box style={{ display: 'flex' }}><Line id="progress-chart" options={chartData.options} style={{ marginTop: 30, height: 'auto' }} data={chartData} /></Box>
                        : null)
                    }
                    {
                      (!(dateFromChart.isValid() && dateToChart.isValid())
                        ? <h3 style={{ color: 'red' }}>Please enter valid dates to show the graph...</h3>
                        : <h3 ref={chartLabel}>{messageChart}</h3>
                      )
                    }
                  </div>

                </Grid>
              ) : null}




            </> : null}


          </Grid>
        </Paper>
      </Container >
      {/* <Modal
        open={openReportModal}
        onClose={() => setOpenReportModal(false)}
      >
        <Box sx={{ ...styleModalReport, width: 400, }}>
          <h2 id="parent-modal-title">Report Generator</h2>
          <p id="parent-modal-description">
            Please insert a date range:
          </p>
          <Typography component="h6"> Date From: </Typography>
          <div style={{ marginBottom: 20 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker disableFuture
                onChange={(e) => changeDateFromReport(e)} />
            </LocalizationProvider>
          </div>
          <Typography component="h6"> Date To: </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker disableFuture
              onChange={(e) => changeDateToReport(e)} />
          </LocalizationProvider>
          <Box sx={{ marginTop: 3 }}>
            <Button variant="contained" onClick={handleGenerationReport}>Generate</Button>
          </Box>
        </Box>
      </Modal> */}
    </>
  );
};
export default IndexPage;
export const Head = () => <title>Tedu - Online Learning Platform</title>;
